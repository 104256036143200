import { React, useEffect, useState, useMyCustomStuff } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router'
import * as eva from 'eva-icons';
import CountUp from 'react-countup';


const SuperadminDashboard = () => {
  let navigate = useNavigate()
  useEffect(() => { eva.replace() });

  return (
      
      <div className='row fadein'>
        <div className='col-md-12 text-start'>
          <div className='foldersview'>
            <div className='row'>
              <div className='col-md-9'>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='folder'>
                      <div className='foldercut'>
                        <div className='cltitle'>
                          <div className='climgcont'>
                            <img src={require('../../assets/images/icons/signers.png')} loading="lazy" />  Organization
                          </div>
                        </div>
                      </div>
                      <div className="card dashboardboxContainer light-blur">
                        <div className='foldcont'>
                          {/* <p className="card-text mb-1 ccondi">Awesome</p>
                    <p className=" cpartitle">Interior Design</p> */}
                          <h2 className='fw-medium text-center'>
                            <CountUp
                              start={3}
                              end={8}
                              duration={4}
                            />
                          </h2>
                        </div>
                        <div className='foldpicshare'>

                          {/* <div className='foldshare eva-hover icon-rht' >View all <i data-eva="arrow-ios-forward-outline" data-eva-animation="flip"></i></div> */}

                          <div className='foldpics'>
                            <ul className='list-unstyled'>
                              {/* <li><span className='flpic'><img src={require('../assets/images/photo2.png')} loading="lazy" /></span></li>
                              <li><span className='flpic'><img src={require('../assets/images/photo4.png')} loading="lazy" /></span></li> */}
                              <li><span className='flpic'><img src={require('../../assets/images/photo1.png')} loading="lazy" /></span></li>
                              <li><span className='flpic'><img src={require('../../assets/images/photo2.png')} loading="lazy" /></span></li>
                              <li><span className='flpic'><img src={require('../../assets/images/photo3.png')} loading="lazy" /></span></li>
                              <li><span className='flpic'><img src={require('../../assets/images/photo5.png')} loading="lazy" /></span></li>
                              {/* <li><span className='flpic last-count'>+25</span></li> */}
                            </ul>
                          </div>

                          <span className='icontext viewall eva-hover'>
                            <span className='icon'><i data-eva="arrow-ios-forward-outline"></i></span>
                            <span className='text'>View all</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className='col-md-4'>
                    <div className='folder'>
                      <div className='foldercut'>
                        <div className='cltitle'>
                          <div className='climgcont'>
                            <img src={require('../../assets/images/icons/students.png')} loading="lazy" />  Users
                          </div>
                        </div>
                      </div>
                      <div className="card dashboardboxContainer light-blur">
                        <div className='foldcont'>
                          {/* <p className="card-text mb-1 ccondi">Best</p>
                    <p className=" cpartitle">Distribution Practices</p> */}
                          <h2 className='fw-medium text-center'>
                            <CountUp
                              start={99}
                              end={137}
                              duration={5}
                            />
                          </h2>
                        </div>
                        <div className='foldpicshare'>

                          <div className='foldpics'>
                            <ul className='list-unstyled'>
                              {/* <li><span className='flpic'><img src={require('../assets/images/photo5.png')} loading="lazy" /></span></li> */}
                              <li><span className='flpic'><img src={require('../../assets/images/photo4.png')} loading="lazy" /></span></li>
                              <li><span className='flpic'><img src={require('../../assets/images/photo3.png')} loading="lazy" /></span></li>
                              <li><span className='flpic'><img src={require('../../assets/images/photo2.png')} loading="lazy" /></span></li>
                              <li><span className='flpic'><img src={require('../../assets/images/photo1.png')} loading="lazy" /></span></li>
                              <li><span className='flpic last-count'>+100</span></li>
                            </ul>
                          </div>

                          <span className='icontext viewall eva-hover'>
                            <span className='icon'><i data-eva="arrow-ios-forward-outline"></i></span>
                            <span className='text'>Manage</span>
                          </span>

                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className='col-md-4'>
                    <div className='folder'>
                      <div className='foldercut'>
                        <div className='cltitle'>
                          <div className='climgcont'>
                            <img src={require('../../assets/images/icons/Certifily-icon.png')} loading="lazy" />  Certificates
                          </div>
                        </div>
                      </div>
                      <div className="card dashboardboxContainer light-blur">
                        <div className='foldcont'>
                          {/* <p className="card-text mb-1 ccondi">Good</p>
                    <p className=" cpartitle">CLINICAL PRACTICES</p> */}
                          <h2 className='fw-medium text-center'>
                            <CountUp
                              start={60}
                              end={92}
                              duration={5}
                            />
                          </h2>
                        </div>
                        <div className='foldpicshare'>
                          <div className='foldpics'>
                           
                          </div>
                          <span className='icontext viewall eva-hover'>
                            <span className='icon'><i data-eva="arrow-ios-forward-outline"></i></span>
                            <span className='text'>View all</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
  );
}

export default SuperadminDashboard;