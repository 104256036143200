export const types = {
    GET_SAMPLEAPI_SUCCESS : 'GET_SAMPLEAPI_SUCCESS',
    GET_SAMPLEAPI_FAILURE : 'GET_SAMPLEAPI_FAILURE',
    POST_LOGIN_SUCCESS : 'POST_LOGIN_SUCCESS',
    POST_LOGIN_FAILURE : 'POST_LOGIN_FAILURE',
    GET_USERPROFILE_SUCCESS : 'GET_USERPROFILE_SUCCESS',
    GET_USERPROFILE_FAILURE : 'GET_USERPROFILE_FAILURE',
    POST_CREATESTUDENT_SUCCESS : 'POST_CREATESTUDENT_SUCCESS',
    POST_CREATESTUDENT_FAILURE : 'POST_CREATESTUDENT_FAILURE',
    GET_USERLIST_SUCCESS : 'GET_USERLIST_SUCCESS',
    GET_USERLIST_FAILED : 'GET_USERLIST_FAILED',
    PUT_EDITUSERDETAILS_SUCCESS : 'PUT_EDITUSERDETAILS_SUCCESS',
    PUT_EDITUSERDETAILS_FAILURE : 'PUT_EDITUSERDETAILS_FAILURE',
    GET_USERBYID_SUCCESS : 'GET_USERBYID_SUCCESS',
    GET_USERBYID_FAILURE : 'GET_USERBYID_FAILURE',
    GENERATE_CERTIFICATE_SUCCESS : 'GENERATE_CERTIFICATE_SUCCESS',
    GENERATE_CERTIFICATE_FAILED : 'GENERATE_CERTIFICATE_FAILED',
    GET_USERCERTLIST_SUCCESS : 'GET_USERCERTLIST_SUCCESS',
    GET_USERCERTLIST_FAILURE : 'GET_USERCERTLIST_FAILURE',
    SHOW_MODAL : 'SHOW_MODAL',
    CLOSE_MODAL : 'CLOSE_MODAL',
    GET_WALLET_ADDRESS : 'GET_WALLET_ADDRESS',
    GET_WALLET_ADDRESS_FAILURE : 'GET_WALLET_ADDRESS_FAILURE',
    MINT_CERTIFICATE : 'MINT_CERTIFICATE',
    MINT_CERTIFICATE_FAILURE : 'MINT_CERTIFICATE_FAILURE',
    POST_RECENTCERTIFICATE_SUCCESS : 'POST_RECENTCERTIFICATE_SUCCESS',
    POST_RECENTCERTIFICATE_FAILURE : 'POST_RECENTCERTIFICATE_FAILURE',
    GET_CERTIFICATE_CERT_NO_FAILURE: 'GET_CERTIFICATE_CERT_NO_FAILURE',
    GET_CERTIFICATE_CERT_NO_SUCCESS: 'GET_CERTIFICATE_CERT_NO_SUCCESS',
    ADD_OWNERSHIP_FLOW_ACCOUNT : 'ADD_OWNERSHIP_FLOW_ACCOUNT',
    ADD_OWNERSHIP_FLOW_ACCOUNT_FAILURE : 'ADD_OWNERSHIP_FLOW_ACCOUNT_FAILURE',
    REMOVE_PUBLIC_KEY : 'REMOVE_PUBLIC_KEY',
    REMOVE_PUBLIC_KEY_FAILURE : 'REMOVE_PUBLIC_KEY_FAILURE',
    //Get user detail after sign in
    GET_AUTHUSER_SUCCESS : 'GET_AUTHUSER_SUCCESS',
    GET_AUTHUSER_FAILED : 'GET_AUTHUSER_FAILED',

    SELECT_DOCUMENT_TYPE : 'SELECT_DOCUMENT_TYPE',
    RESET_DOCUMENT_TYPE : 'RESET_DOCUMENT_TYPE',
    // sign up user
    POST_USER_SIGNUP : 'POST_USER_SIGNUP',
    POST_USER_SIGNUP_FAILED : 'POST_USER_SIGNUP_FAILED',
    //Sign in user
    POST_SIGNIN : 'POST_SIGNIN',
    POST_SIGNIN_FAILED : 'POST_SIGNIN_FAILED',
    IMPORT_USERS_SUCCESS : 'IMPORT_USERS_SUCCESS',
    IMPORT_USERS_FAILED : 'IMPORT_USERS_FAILED',
    GET_INSTUITION_DASHBOARD_DETAILS : 'GET_INSTUITION_DASHBOARD_DETAILS',
    GET_INSTUITION_DASHBOARD_DETAILS_FAILED : 'GET_INSTUITION_DASHBOARD_DETAILS_FAILED',
    GET_STUDENT_DASHBOARD_DETAILS : 'GET_STUDENT_DASHBOARD_DETAILS',
    GET_STUDENT_DASHBOARD_DETAILS_FAILED : 'GET_STUDENT_DASHBOARD_DETAILS_FAILED',
    SCHEDULE_DEMO_SUCCESS : 'SCHEDULE_DEMO_SUCCESS',
    SCHEDULE_DEMO_FAILED : 'SCHEDULE_DEMO_FAILED',
    PWD_RESET_EMAIL_SUCCESS: 'PWD_RESET_EMAIL_SUCCESS',
    PWD_RESET_EMAIL_FAILED: 'PWD_RESET_EMAIL_FAILED',

    UPDATED_PROFILE_DATA_SUCCESS: 'UPDATED_PROFILE_DATA_SUCCESS',
    UPDATED_PROFILE_DATA_FAILED: 'UPDATED_PROFILE_DATA_FAILED',

    COMMON_ERROR_MESSAGE : 'COMMON_ERROR_MESSAGE',
    COMMON_SUCCESS_MESSAGE : 'COMMON_SUCCESS_MESSAGE',

    CREATE_ORGANISATION : 'CREATE_ORGANISATION',
    CREATE_ORGANISATION_FAILED : 'CREATE_ORGANISATION_FAILED',
    CHECK_ORGANISATION_EXIST : 'CHECK_ORGANISATION_EXIST',
    CHECK_ORGANISATION_EXIST_FAILED : 'CHECK_ORGANISATION_EXIST_FAILED',

    GET_MEMBERS_LIST : 'GET_MEMBERS_LIST',
    GET_MEMBERS_LIST_FAILED : 'GET_MEMBERS_LIST_FAILED',

    GET_ORGANISATION_DETAIL : 'GET_ORGANISATION_DETAIL',
    GET_ORGANISATION_DETAIL_FAILED : 'GET_ORGANISATION_DETAIL_FAILED',

    ORGANISATION_SELF_PERMISSIONS : 'ORGANISATION_SELF_PERMISSIONS',
    ORGANISATION_SELF_PERMISSIONS_FAILED : 'ORGANISATION_SELF_PERMISSIONS_FAILED',

    NOTIFICATION_POPUP : 'NOTIFICATION_POPUP',

    GET_ALL_MINTED_CERTIFICATE : 'GET_ALL_MINTED_CERTIFICATE',
    GET_ALL_MINTED_CERTIFICATE_FAILED : 'GET_ALL_MINTED_CERTIFICATE_FAILED',

    RESEND_VERIFICATION_EMAIL : 'RESEND_VERIFICATION_EMAIL',
    RESEND_VERIFICATION_EMAIL_FAILED : 'RESEND_VERIFICATION_EMAIL_FAILED',

    EMAIL_VERIFIED_CHECK : 'EMAIL_VERIFIED_CHECK',

    GET_ORGANISATION_CERTIFICATES : 'GET_ORGANISATION_CERTIFICATES',
    GET_ORGANISATION_CERTIFICATES_FAILED : 'GET_ORGANISATION_CERTIFICATES_FAILED',

    FIREBASE_USER_DETAIL : 'FIREBASE_USER_DETAIL',

    GET_CERTIFICATE_TEMPLATE : 'GET_CERTIFICATE_TEMPLATE',
    GET_CERTIFICATE_TEMPLATE_FAILED : 'GET_CERTIFICATE_TEMPLATE_FAILED',

    GET_SUPERADMIN_ORG_SUCCESS : 'GET_SUPERADMIN_ORG_SUCCESS',
    GET_SUPERADMIN_ORG_FAILURE : 'GET_SUPERADMIN_ORG_FAILURE',

    GET_ORGANISATION_VERIFY_STATUS : 'GET_ORGANISATION_VERIFY_STATUS',
    GET_ORGANISATION_VERIFY_STATUS_FAILED : 'GET_ORGANISATION_VERIFY_STATUS_FAILED',

    PUT_UPDATE_ORG_STATUS_SUCCESS : 'PUT_UPDATE_ORG_STATUS_SUCCESS',
    PUT_UPDATE_ORG_STATUS_FAILURE : 'PUT_UPDATE_ORG_STATUS_FAILURE',

    GET_SUPERADMIN_USERLIST_SUCCESS : 'GET_SUPERADMIN_USERLIST_SUCCESS',
    GET_SUPERADMIN_USERLIST_FAILURE : 'GET_SUPERADMIN_USERLIST_FAILURE'
}