import { isEmailVerifiedCheck } from "actions/exampleAction"
import { isEmailVerified } from "helpers/authData"
import { useDispatch } from "react-redux"
import * as eva from 'eva-icons';
import { useEffect, useState } from "react";
import { getUserDetail, updateLocalStorageFirebaseUser } from "helpers/updateFirebaseAuth";

function CertifiButton(props) {
    const dispatch = useDispatch()

    useEffect(() => { eva.replace() },
    [props]);
    const [btnDetail,setBtnDetail] = useState({})
    const [icon,setIcon] = useState('')
        useEffect(() => {
                setBtnDetail(props)
        },[props])
    const onclickHandler = async (e) => {
        if(btnDetail.verificationNeed === false){
            btnDetail.onClick(e)
        }else{
            if (isEmailVerified()) {
                if(btnDetail.onClick){
                    btnDetail.onClick(e)
                }
            } else {
                e.preventDefault()
                const user = await getUserDetail() 
                  if(user && user.claims){
                    if(user.claims.email_verified){
                      updateLocalStorageFirebaseUser(user)
                        if(btnDetail.onClick){
                            btnDetail.onClick(e)
                        }
                      
                    }else{
                      updateLocalStorageFirebaseUser(user)
                        if(btnDetail.onClick){
                          dispatch(isEmailVerifiedCheck())
                        }
                      
                    }
                  }
            }
        }
        
    }
    const onSubmitHandler = async (e) => {
        if(btnDetail.verificationNeed === false){
            btnDetail.onSubmit(e)
        }else{
            if (isEmailVerified()) {
                btnDetail.onSubmit(e)
            } else {
                e.preventDefault()
                const user = await getUserDetail() 
                  if(user && user.claims){
                    if(user.claims.email_verified){
                      updateLocalStorageFirebaseUser(user)
                        if(btnDetail.onSubmit){
                            btnDetail.onSubmit(e)
                        }
                      
                    }else{
                      updateLocalStorageFirebaseUser(user)
                        if(btnDetail.onSubmit){
                          dispatch(isEmailVerifiedCheck())
                        }
                      
                    }
                  }
            }
        }
        
    }
   
    return (
        <button
            type={btnDetail.type}
            className={btnDetail.className}
            data-tooltip={btnDetail.tooltip}
            onClick={onclickHandler}
            onSubmit={onSubmitHandler}
            >
                {btnDetail.image ? 
                <img
                        src={require(`../../assets/images/icons/${btnDetail.image}`)}
                        className=''
                      /> : ''}

{/*   {btnDetail.icon ? btnDetail.icon : ''} */}

            {btnDetail.name}
        </button>

    )
}

export { CertifiButton }