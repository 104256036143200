import { getSelectedUserOid, getSelectedUserRole } from 'helpers/authData';
import { React } from 'react';
import { NavLink } from 'react-router-dom';
import { isUserRoleEnabled } from 'utils/utils';

const PageNotFound = () => {
  let oid = getSelectedUserOid()
  return (
    <div className='pnf'>
        <img src={require('../assets/images/page-not-found.png')} loading="lazy" />
        <h6>Oops!</h6>
        <p>The page you were looking for doesn't exist</p>
        {isUserRoleEnabled(getSelectedUserRole()) ? <NavLink to="/dashboard" className='btn btn-primary'>Go to Dashboard</NavLink> : 
        <NavLink to={`/institution/${oid}/dashboard`} className='btn btn-primary'>Go to Dashboard</NavLink>}
    </div>
  );
}

export default PageNotFound;