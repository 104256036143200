import { useDrag } from 'react-dnd';

const DraggableDiv = ({ id, children, customAttr }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'div',
    item: { id, type: 'div',filename: customAttr.filename },    
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div id={id} ref={drag} style={{ opacity: isDragging ? 1 : 1, cursor: isDragging ? 'grabbing' : 'grab'}}>
      {children}
    </div>
  );
};

export default DraggableDiv;
