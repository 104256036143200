import { firebaseUserUpdate } from 'actions/exampleAction';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { store } from 'store';


export const getUserDetail = async () => {
    return new Promise((resolve, reject) => {
        const unsub = onAuthStateChanged(getAuth(), async (user) => {
            if (user) {
                const userDet = await getAuth().currentUser.getIdTokenResult(true);
                resolve(userDet)
            } else {
                console.log("User not logged in")
                resolve(null)
            }
            unsub();
        });
    })
}


export const updateFirebaseAuth = async () => {
    const user = await getUserDetail()
    if(user && user.claims && user.token){
      localStorage.setItem('userprofile',JSON.stringify(user.claims))
      localStorage.setItem('accessToken', user.token)
      localStorage.setItem('user_email', user.claims.email);
      store.dispatch(firebaseUserUpdate(user.claims))
    }

}

export const updateLocalStorageFirebaseUser = (user) => {
    if(user && user.claims && user.token){
        localStorage.setItem('userprofile',JSON.stringify(user.claims))
        localStorage.setItem('accessToken', user.token)
        localStorage.setItem('user_email', user.claims.email);
        store.dispatch(firebaseUserUpdate(user.claims))
      }
}