import { types } from "../constants/types";

const initialState = {
    sampleApi : [],
    logInResponse : [],
    userProfile : [],
    addStudent : [],
    userlist : [],
    edituser : [],
    userbyid : [],
    generatedCertificate : [],
    logInResponseFailed : '',
    addNewStudentFailed : '',
    userProfileFailed : '',
    isInfoModal : false,
    walletAddress : null,
    mintResponse : [],
    mintFailed : '',
    userCertificateList : [],
    recentcertificate : [],
    getCertificateFailed:'',
    getCertificate: {},
    flowOwnership : {},
    flowOwnershipFailed : '',
    removedKey : [],
    removeKeyFailed : '',
    isDocTypeClicked: false,
    userListFailed : '',
    getRecentCertificateFailed : {},
    commonErrorMessage:'',
    commonSuccessMessage: '',
    walletAddressFailed: '',
    importUsersSuccess : [],
    impoerUsersFailed : '',
    edituserFailed : {},
    allstats : [],
    statsFailed:{},
    studentStats : [],
    studentStatsFailed : {},
    updatedProfile: [],
    updatedProfileFailed: {},
    organisationCreated: [],
    organisationCreatedFailed: {},
    checkOrgnameexist: [],
    checkOrgnameexistfailed: {},
    orgDetail: {},
    orgDetailFailed: {},
    membersList : [],
    membersListFailed : {},
    selfPermissions : {},
    permissionsFailed : {},
    notifiedMessage : '',
    emailVerificationStatus : {},
    getAllcertificates : {},
    getAllCertFailed : {},
    getAllOrgCertificates : {},
    getAllOrgCertificatesFailed : {},
    firebaseUser : {},
    certificateTemplates: {},
    certificateTemplatesFailed: {},
    orgVerificationStatus: {},
    orgVerificationStatusFailed: {}
}
//receives the current state and an action object
export function demoReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_SAMPLEAPI_SUCCESS:
            return {
                ...state,
                sampleApi : action.payload
            }
        case types.POST_LOGIN_SUCCESS:
            return {
                ...state,
                logInResponse : action.payload
            }
        case types.GET_USERPROFILE_SUCCESS:
            return {
                ...state,
                userProfile : action.payload
            }
        case types.POST_CREATESTUDENT_SUCCESS:
            return {
                ...state,
                addStudent : action.payload
            }
        case types.POST_CREATESTUDENT_FAILURE:
            return {
                ...state,
                addNewStudentFailed : action.payload
            }
        case types.GET_USERLIST_SUCCESS:
            return {
                ...state,
                userlist : action.payload
            }
        case types.PUT_EDITUSERDETAILS_SUCCESS:
            return {
                ...state,
                edituser : action.payload
            }
        case types.GET_USERBYID_SUCCESS:
            return {
                ...state,
                userbyid : action.payload
            }
        case types.GENERATE_CERTIFICATE_SUCCESS:
            return {
                ...state,
                generatedCertificate : action.payload
            }
        case types.GET_USERCERTLIST_SUCCESS:
            return {
                ...state,
                userCertificateList : action.payload
            }
        case types.POST_LOGIN_FAILURE:
            return {
                ...state,
                logInResponseFailed : action.payload
            }
        case types.GET_USERPROFILE_FAILURE:
            return {
                ...state,
                userProfileFailed : action.payload
            }
        case types.SHOW_MODAL:
            return {
                ...state,
                isInfoModal : action.payload
            }
        case types.CLOSE_MODAL:
            return {
                ...state,
                isInfoModal : action.payload
            }
        case types.GET_WALLET_ADDRESS:
            return {
                ...state,
                walletAddress : action.payload
            }
        case types.GET_WALLET_ADDRESS_FAILURE:
            return {
                ...state,
                walletAddressFailed : action.payload
            }
        case types.MINT_CERTIFICATE:
            return {
                ...state,
                mintResponse : action.payload
            }
        case types.MINT_CERTIFICATE_FAILURE:
            return {
                ...state,
                mintFailed : action.payload
            }
        case types.POST_RECENTCERTIFICATE_SUCCESS:
            return {
                ...state,
                recentcertificate : action.payload
            }
        case types.GET_CERTIFICATE_CERT_NO_SUCCESS:
            return {
                ...state,
                getCertificate: action.payload
            }
        case types.GET_CERTIFICATE_CERT_NO_FAILURE:
            return {
                ...state,
                getCertificateFailed: action.payload
            }
        case types.ADD_OWNERSHIP_FLOW_ACCOUNT:
            return {
                ...state,
                flowOwnership: action.payload
            }
        case types.ADD_OWNERSHIP_FLOW_ACCOUNT_FAILURE:
            return {
                ...state,
                flowOwnershipFailed: action.payload
            }
        case types.REMOVE_PUBLIC_KEY:
            return {
                ...state,
                removedKey: action.payload
            }
        case types.REMOVE_PUBLIC_KEY_FAILURE:
            return {
                ...state,
                removeKeyFailed: action.payload
            }
        case types.SELECT_DOCUMENT_TYPE:
            return {
                ...state,
                isDocTypeClicked: action.payload
            }
        case types.RESET_DOCUMENT_TYPE:
            return {
                ...state,
                isDocTypeClicked: action.payload
            }
        case types.GET_USERLIST_FAILED:
            return {
                ...state,
                userListFailed: action.payload
            }
        case types.POST_RECENTCERTIFICATE_FAILURE:
            return {
                ...state,
                getRecentCertificateFailed: action.payload
            }
        case types.COMMON_ERROR_MESSAGE:
            return {
                ...state,
                commonErrorMessage: action.payload
            }
        case types.COMMON_SUCCESS_MESSAGE:
            return {
                ...state,
                commonSuccessMessage: action.payload
            }
        case types.IMPORT_USERS_SUCCESS:
            return {
                ...state,
                importUsersSuccess: action.payload
            }
        case types.IMPORT_USERS_FAILED:
            return {
                ...state,
                impoerUsersFailed: action.payload
            }
        case types.PUT_EDITUSERDETAILS_FAILURE:
            return {
                ...state,
                edituserFailed : action.payload
            }
        case types.GET_INSTUITION_DASHBOARD_DETAILS:
            return {
                ...state,
                allstats : action.payload
            }
        case types.GET_INSTUITION_DASHBOARD_DETAILS_FAILED:
            return {
                ...state,
                statsFailed : action.payload
            }
        case types.GET_STUDENT_DASHBOARD_DETAILS:
            return {
                ...state,
                studentStats : action.payload
            }
        case types.GET_STUDENT_DASHBOARD_DETAILS_FAILED:
            return {
                ...state,
                studentStatsFailed : action.payload
            }
        case types.UPDATED_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                updatedProfile : action.payload
            }
        case types.UPDATED_PROFILE_DATA_FAILED:
            return {
                ...state,
                updatedProfileFailed : action.payload
            }
        case types.CREATE_ORGANISATION:
            return {
                ...state,
                organisationCreated: action.payload
            }
        case types.CREATE_ORGANISATION_FAILED:
            return {
                ...state,
                organisationCreatedFailed: action.payload
            }
        case types.CHECK_ORGANISATION_EXIST:
            return {
                ...state,
                checkOrgnameexist: action.payload
            }
        case types.CHECK_ORGANISATION_EXIST_FAILED:
            return {
                ...state,
                checkOrgnameexistfailed: action.payload
            }
        case types.GET_ORGANISATION_DETAIL:
            return {
                ...state,
                orgDetail: action.payload
            }
        case types.GET_ORGANISATION_DETAIL_FAILED:
            return {
                ...state,
                orgDetailFailed: action.payload
            }
        case types.GET_MEMBERS_LIST:
            return {
                ...state,
                membersList: action.payload
            }
        case types.GET_MEMBERS_LIST_FAILED:
            return {
                ...state,
                membersListFailed: action.payload
            }
        case types.ORGANISATION_SELF_PERMISSIONS:
            return {
                ...state,
                selfPermissions: action.payload
            }
        case types.ORGANISATION_SELF_PERMISSIONS_FAILED:
            return {
                ...state,
                permissionsFailed: action.payload
            }
        case types.NOTIFICATION_POPUP:
            return {
                ...state,
                notifiedMessage: action.payload
            }
        case types.EMAIL_VERIFIED_CHECK:
            return {
                ...state,
                emailVerificationStatus: action.payload
            }
        case types.GET_ALL_MINTED_CERTIFICATE:
            return {
                ...state,
                getAllcertificates: action.payload
            }
        case types.GET_ALL_MINTED_CERTIFICATE_FAILED:
            return {
                ...state,
                getAllCertFailed: action.payload
            }
        case types.GET_ORGANISATION_CERTIFICATES:
            return {
                ...state,
                getAllOrgCertificates: action.payload
            }
        case types.GET_ORGANISATION_CERTIFICATES_FAILED:
            return {
                ...state,
                getAllOrgCertificatesFailed: action.payload
            }
        case types.FIREBASE_USER_DETAIL:
            return {
                ...state,
                firebaseUser: action.payload
            }
        case types.GET_CERTIFICATE_TEMPLATE:
            return {
                ...state,
                certificateTemplates: action.payload
            }
        case types.GET_CERTIFICATE_TEMPLATE_FAILED:
            return {
                ...state,
                certificateTemplatesFailed: action.payload
            }
        case types.GET_ORGANISATION_VERIFY_STATUS:
            return {
                ...state,
                orgVerificationStatus: action.payload
            }
        case types.GET_ORGANISATION_VERIFY_STATUS_FAILED:
            return {
                ...state,
                orgVerificationStatusFailed: action.payload
            }
        default:
            return state
    }
}