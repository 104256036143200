import {auth} from '../firebase-config';
import { logoutUser } from '../helpers/authData';
import { getAuth, signOut } from 'firebase/auth';
export const ErrorResponseSetup = async (err) => {
    const originalConfig = err.config;
    console.log(originalConfig,err)
    if (originalConfig && originalConfig.url !== "/login" && err && err.response) {
        // Access Token was expired
        if ((err.response.status === 401) && !originalConfig._retry) {
        originalConfig._retry = true;

        signOut(getAuth()).then(() => {
            logoutUser()
        }).catch((error) => {
            // An error happened.
        });
        }
    }
   
    if(err && 
        err.response && 
        err.response.data && 
        err.response.data.data.data && 
        err.response.data.message && 
        err.response.data.statusCode
        ){
        let obj = {
            statusCode : err.response.data.statusCode,
            data: err.response.data.data.data
        }
        if(err.response.data.message.toLowerCase().includes("standard complaint identifier string")){
            obj['message'] = "Please check your phone number and country code"
        }else{
            obj['message'] = err.response.data.message
        }
        return Promise.reject(obj)
    }

    if(err && 
        err.response && 
        err.response.data && 
        err.response.data.statusCode && 
        err.response.data.message){
        let obj = {
            statusCode : err.response.data.statusCode,
        }
        if(err.response.data.message.toLowerCase().includes("standard complaint identifier string")){
            obj['message'] = "Please check your phone number and country code"
        }else{
            obj['message'] = err.response.data.message
        }
        return Promise.reject(obj)
    }
    if(err && 
        err.response && 
        err.response.data && 
        err.response.data.statusCode){
        let obj = {
            statusCode : err.response.data.statusCode,
        }
        return Promise.reject(obj)
    }
    return Promise.reject(err);




}

