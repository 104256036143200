import { React, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonError, firebaseUserUpdate, getUserProfile, isEmailVerifiedCheck, resetUserProfile, resetUserProfileFailed } from '../actions/exampleAction';
import { useNavigate, NavLink } from "react-router-dom";
import { getAuthUserDetail, postSignUp, resetAuthUserDetail, resetAuthUserDetailFailed, resetSignupInfo, resetSignupInfoFailed } from '../actions/authuserAction';

import  {getAuth, signInWithCustomToken} from 'firebase/auth'
import {auth} from '../firebase-config';
const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const minOrgnameLength = 1
  const maxOrgnameLength = 255
  const minUsernamelength = 1
  const maxUsernameLength = 255
  const minPasswordLength = 8
  const maxPasswordLength = 255
  const signInfo = useSelector(state => state.authUserReducer.signupInfo);
  const userProfile = useSelector(state => state.authUserReducer.fetchuserDetail);
  const userProfilefailed = useSelector(state => state.authUserReducer.fetchuserDetailFailed);
  const signResponseFailed = useSelector(state => state.authUserReducer.signupFailed);
  const userProfileWithWallet = useSelector(state => state.demoReducer.userProfile);
  const userProfilefailedWithWallet = useSelector(state => state.demoReducer.userProfileFailed);

  const [isLoading, setIsLoading] = useState(false)
  const [erroMessage, setErroMessage] = useState("");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [orgName, setOrgName] = useState('');
  const [phnNo, setPhnNo] = useState('')
  const [userName, setUserName] = useState('')
  const [isErrorPass, setIsErrorPass] = useState(false)
  const [isAuthCalled, setIsAuthCalled] = useState(false)
  const [buttonStyle,setButtonStyle] = useState('')
  const [isProfileCalled, setIsProfileCalled] = useState(false)

  const errorRef = useRef(null);

  useEffect(() => {
    if (signInfo && signInfo.statusCode == 200 && signInfo.data.user.token) {
      dispatch(resetSignupInfo())
      signedIn(signInfo.data.user.token)
    }
  }, [signInfo]);

 
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value.toLowerCase());
    } else if (name === 'password') {
      setPassword(value);
      setConfirmPass('')
    } else if (name === 'confirmPass') {
      setConfirmPass(value)
    }
  };
  const validateInput = e => {
    let { name, value } = e.target;
    console.log(name, value)
    if (name === "password") {
      if (confirmPass && value !== confirmPass) {
        setIsErrorPass(true)
      } else{
        setIsErrorPass(false)
      }
    } else if (name === "confirmPass") {
      if (password && value !== password) {
        setIsErrorPass(true)
      }else{
        setIsErrorPass(false)
      }
    }

  }
  const checkPassword = (value) => {
    if ((value && value.length) >= 8) {
        return true
    } else {
        return false
    }
  }
  const handleOrgnameChange = (e) => {
    setOrgName(e.target.value)
  }
  const handleUsernameChange = (e) => {
    setUserName(e.target.value)
  }
  const handleSubmit = (event) => {
    setErroMessage("");
    event.preventDefault();
    setIsLoading(true)
    const formData = { email, password, userName, orgName, confirmPass, phnNo };
    if (!formData.email || !formData.password || !formData.confirmPass) {
      dispatch(commonError("Please fill out all below fields"))
      setIsLoading(false)
      return;
    }else if (checkConfirmPassword()){
      let data = {
        "email": formData.email,
        "password": formData.password,
        // "orgName": formData.orgName,
        // "name": formData.userName,
      }
      dispatch(postSignUp(data));
   
    }else{
      dispatch(commonError("Password and Confirm Password doesn't match"))
      setIsLoading(false)
      return;
    }
    
  };
  const checkConfirmPassword = () => {
    if (password === confirmPass) {
        return true
    } else {
        return false
    }
}
  
  useEffect(() => {
    if(signResponseFailed && signResponseFailed.message){
      setIsLoading(false)
      dispatch(resetSignupInfoFailed())
      
       if(signResponseFailed && 
      signResponseFailed.data && 
      signResponseFailed.data.data &&
      signResponseFailed.data.data[0] && 
      signResponseFailed.data.data[0][0] && 
      typeof signResponseFailed.data.data[0][0] === 'string') {
        if(signResponseFailed.data.data[0][0].toLowerCase().includes('email must be an email')){
          dispatch(commonError('Invalid email'))
        }else{
          dispatch(commonError(signResponseFailed.data.data[0][0]))
        }
    }else if(typeof signResponseFailed.message === 'string'){
      dispatch(commonError(signResponseFailed.message))
    }
    }
  }, [signResponseFailed]);
  useEffect(() => {
    if(erroMessage && erroMessage !== ''){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
    }
  },[erroMessage])
  const onCloseAlert = () => {
    setErroMessage('')
  }
  const signedIn = async (token) => {
    try{
      const signedIn = await signInWithCustomToken(getAuth(),token)
      if(signedIn){
        console.log(signedIn)
        localStorage.setItem('accessToken', signedIn._tokenResponse.idToken)
        localStorage.setItem('refreshToken', signedIn._tokenResponse.refreshToken)
        localStorage.setItem('user_email', email);
        setIsAuthCalled(true)
      }
    }catch(err){
      if(err){
        setIsLoading(false)
        setErroMessage("Something went wrong. Try with login")
        navigate('/login')
      }
    }
  }
  useEffect(() => {
    if(isAuthCalled){
      dispatch(getAuthUserDetail())
    }
  },[isAuthCalled])
  useEffect(() => {
    if (userProfile && userProfile.statusCode == 200 && isAuthCalled) {
      setIsAuthCalled(false)
      dispatch(resetAuthUserDetail())

      if(userProfile && userProfile.data && userProfile.data !== undefined){
        localStorage.setItem('userprofile', JSON.stringify(userProfile.data));
        setIsProfileCalled(true)
        dispatch(getUserProfile())
        dispatch(firebaseUserUpdate(userProfile.data))
      }else{
        navigate("/login");
      }
      
    }
  }, [userProfile]);
  useEffect(() => {
    if (userProfilefailed && typeof userProfilefailed.statusCode) {
      setIsLoading(false)
      navigate("/login");
      dispatch(resetAuthUserDetailFailed())
    }
  }, [userProfilefailed]);
  useEffect(() => {
    if (userProfileWithWallet && userProfileWithWallet.statusCode == 200 && isProfileCalled) {  
      // dispatch(resetUserProfile())
      setIsLoading(false)
      setIsProfileCalled(false)

      if(userProfileWithWallet && userProfileWithWallet.data && userProfileWithWallet.data !== undefined){
        localStorage.setItem('walletProfile', JSON.stringify(userProfileWithWallet.data));
        // dispatch(isEmailVerifiedCheck())
        navigate("/dashboard");

      }
      
    }
  }, [userProfileWithWallet]);
  useEffect(() => {
    if (userProfilefailedWithWallet && userProfilefailedWithWallet.statusCode) {  
      dispatch(resetUserProfileFailed())
      setIsProfileCalled(false)
    }
  }, [userProfilefailedWithWallet]);
  const gotoHome = () => {
    navigate('/')
  }
  return (
    <div className='logouter'>
      <div className='logincon'>
        <div className='loginimg'>
          <img src={require('../assets/images/login-bg.jpg')} alt="" loading="lazy" />
          <div className='logtxtbox'>
          <h1>Professional documents on <span className='dartxtclr'>blockchain</span></h1>
          </div>
        </div>
        <div className='loginform fadein'>
        {erroMessage && <div className="slideDown" ref={errorRef}>
      <div className="alert alert-danger text-center py-3 fade show fadein alert-top" role="alert">
                        {erroMessage}
                      </div>
        </div>}
        
          <div className="main-content">
            <div className="main-logo main-logo--cert">
              <a onClick={gotoHome} className='cursor-pointer'>
                <img src={require('../assets/images/logo.png')} alt="certifily" loading="lazy" />
              </a>
            </div>
            <div id="log-in" className="base-layout">
              <h5 className="title">Create an Account</h5>
              <p className='lighttxtclr mb-5'>Enter the information to register.</p>
              <div className="remember-user">
                <form onSubmit={handleSubmit}>
                  {/* <div className="form-group">
                    <label htmlFor='orgName'>Institution name</label>
                    <input 
                      id='orgName' 
                      type="text"
                      value={orgName} 
                      name="orgName" 
                      maxLength={maxOrgnameLength}
                      minLength={minOrgnameLength}
                      className='form-control' 
                      onChange={handleOrgnameChange}
                      placeholder="Institution"
                      required />
                  </div>
                  <div className="form-group">
                    <label htmlFor='username'>Name</label>
                    <input 
                      id='username' 
                      type="text" 
                      value={userName} 
                      name="userName" 
                      maxLength={maxUsernameLength}
                      minLength={minUsernamelength}
                      className='form-control'
                      onChange={handleUsernameChange}
                      placeholder='Your name' 
                      required />
                  </div> */}
                  <div className="form-group">
                    <label htmlFor='email'>Email</label>
                    <input 
                      id='email' 
                      type="text" 
                      name="email" 
                      value={email} 
                      onChange={handleInputChange} 
                      maxLength="80" 
                      minLength="6" 
                      className='form-control' 
                      placeholder='Email address'
                      autocomplete="username"
                      required />
                  </div>
                  <div className="form-group">
                    <label htmlFor='password'>Password</label>
                    <input
                      id='password'
                      type="password"
                      name="password"
                      value={password}
                      onChange={handleInputChange}
                      maxLength={maxPasswordLength}
                      minLength={minPasswordLength}
                      className='form-control'
                      placeholder="Create a strong password with minimum 8 character"
                      required />
                  </div>
                  <div className="form-group">
                    <label htmlFor='Confirmpassword'>Confirm Password</label>
                    <input
                      id='Confirmpassword'
                      type="password"
                      name="confirmPass"
                      value={confirmPass}
                      onChange={handleInputChange}
                      maxLength={maxPasswordLength}
                      minLength={minPasswordLength}
                      className={isErrorPass ? 'form-control' : 'form-control'}
                      disabled={!checkPassword(password)}
                      placeholder="Re-enter password"
                      required />
                  </div>

                  <div className="form-group text-center loginbtn">

                    {isLoading ?
                      <button
                        type="button"
                        className="btn btn-light btn-icon btn-disabled bg-white filter-none"
                      >

                        <img
                          src={require('../assets/images/certifi-loader.gif')}
                          loading="lazy"
                          alt="Loading..." width={28}
                        />
                      </button>
                      :
                      <button className="btn btn-primary text-uppercase" type="submit">Register
                      </button>}

                  </div>


                  <div className='form-group text-center mt-3'>
                    Already have an account? <NavLink to="/login" className="text-underline">Login</NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;