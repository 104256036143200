import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthStatus } from '../helpers/authHook';
import Admin from './admin';

// import { history } from '_helpers';

export { UnauthRoute };

function UnauthRoute({ children }) {
    let authUser = localStorage.accessToken;
    const isLoggedIn = useAuthStatus()
    const navigate = useNavigate()
    if (!authUser || !isLoggedIn) {
      return <>{children}</>
    }
    console.log("unauth")
    // authorized so return child components
    return <Navigate to="/"/>
}