import { React, useEffect, useState, useMyCustomStuff } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router'
import * as eva from 'eva-icons';
import CountUp from 'react-countup';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentCertificate, reseRecentCertificate } from '../../actions/exampleAction';
import TableLoader from '.././shared/TableLoader';
import ProfileArea from '../../components/shared/ProfileArea';

const certlist = [
  {
    uid: '394efbffc42e4ee8826a202062e5ddeb',
    orgname:'Northfield Institute of Technology',
    username: 'Elsie Luthers',
    course: 'CSE',
    batch: 2023,
    issuedon:'2023-04-10 11:03:45',
    status: 'active',
  },
  {
    uid: '08813c3a1e154570830caad0b7ffbb1f',
    orgname:'Columbia University',
    username: 'Elsie Luthers',
    course: 'ECE',
    batch: 2022,
    issuedon:'2022-12-10 11:03:45',
    status: 'active',
  },
  {
    uid: '394efbffc42e4ee8826a202062e5ddeb',
    orgname:'Cerifily',
    username: 'Elsie Luthers',
    course: 'IT',
    batch: 2021,
    issuedon:'2023-04-10 11:03:45',
    status: 'active',
  },
  {
    uid: '08813c3a1e154570830caad0b7ffbb1f',
    orgname:'Emerald College of Environmental Science',
    username: 'Elsie Luthers',
    course: 'ECE',
    batch: 2023,
    issuedon:'2023-04-10 11:03:45',
    status: 'active',
  },
  {
    uid: '394efbffc42e4ee8826a202062e5ddeb',
    orgname:'Ganges College of Business and Finance',
    username: 'Elsie Luthers',
    course: 'EEE',
    batch: 2023,
    issuedon:'2023-04-10 11:03:45',
    status: 'active',
  }, 
  {
    uid: '08813c3a1e154570830caad0b7ffbb1f',
    orgname:'Lotus College of Fine Arts and Design',
    username: 'Elsie Luthers',
    course: 'CSE',
    batch: 2023,
    issuedon:'2023-04-10 11:03:45',
    status: 'active',
  }
];

const SuperadminMints = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch();
  let userprofile = JSON.parse(localStorage.getItem('userprofile'));
  let orgID = userprofile && userprofile.organistaions &&  userprofile.organistaions[0]?.id;
  const [recentCertData, setRecentCertData] = useState([]);
  const [fetched, setFetched] = useState(false)

  return (
    <div className='row fadein'>
      <div className='col-md-12 text-start'>
        <div className='tableblur mt-3'>                    
          <div className='searchform pt-0'>
            <div className='fields'>Search & Filters</div>
            <div className='fields'><input type={'text'} className="form-control" placeholder='Organization Name' readOnly disabled/></div>
            <div className='fields'><input type={'text'} className="form-control" placeholder='User Name' readOnly disabled/></div>
            <div className='fields'><input type={'text'} className="form-control" placeholder='Batch' readOnly disabled/></div>
            <div className='fields'><input type={'text'} className="form-control" placeholder='Course' readOnly disabled/></div>
          </div>
          <div className='table-responsive'>
          <table className="table align-middle mb-0 custable table-hover">
            <thead className="">
              <tr>
                <th>ID</th>
                <th>Organization</th>
                <th>User Name</th>
                <th>Course</th> 
                <th>Batch</th>
                <th>Status</th>
                <th>Issued on</th>
                <th className='text-center'>Action</th>
              </tr>
            </thead>
            <tbody>
                {certlist.map((user, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">{index+1}</div>
                    </td>
                    <td>
                      <p className="mb-1">{user.orgname}</p>
                    </td>
                    <td>
                      <p className="mb-1">{user.username}</p>
                    </td>
                    <td>
                      <p className="mb-1">{user.course}</p>
                    </td>
                    <td>
                      <p className="mb-1">{user.batch}</p>
                    </td>
                    <td>
                      <p className="mb-1">{user.status}</p>
                    </td>
                    <td>
                      <p className="mb-1">{user.issuedon}</p>
                    </td>
                    <td className='text-center'>
                      <div className='btngrouprht'>
                        <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="View" >< i data-eva-animation="flip" data-eva="eye-outline"></i></a>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperadminMints;