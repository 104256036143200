import { resetCommonNotification } from 'actions/exampleAction';
import { React, useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux';

const NotificationPopup = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [notificationMessage,setNotificationMessage] = useState('')
    const notification = useSelector(state => state.demoReducer.notifiedMessage);
    const dispatch = useDispatch()
    const closeModal = () => {
        setIsOpen(false)
    }
    useEffect(() => {
        if(notification && notification !== ''){
            setNotificationMessage(notification)
            setIsOpen(true)
            dispatch(resetCommonNotification())
        }
    },[notification])
    return (
        <Modal show={isOpen} onHide={(e) => closeModal(e, this)} size="md" aria-labelledby="contained-modal-title-vcenter"
            centered backdrop="static">

            <Modal.Body>
            <span className='mcls' onClick={closeModal}>&times;</span>
                <div className="modal-body d-flex flex-column align-items-center">
                    <div className="form-group text-center checkcross">
                        <div className=' mb-5'><img src={require('../../assets/images/logo.png')} loading="lazy" /></div>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#F96262" strokeWidth="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <line className="path line" fill="none" stroke="#F96262" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                            <line className="path line" fill="none" stroke="#F96262" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                        </svg>
                        <h4 className='mt-3  mb-0 text-uppercase fw-bold'>{notificationMessage}</h4>
                    </div>

                    <div className='btngrouprht mt-5'>
                        <a href='' className="btn btn-light  minw-auto" onClick={closeModal}>Close</a>
                    </div>

                </div>

            </Modal.Body>          
        </Modal>
    );
}

export default NotificationPopup;