import { isEmailVerified } from 'helpers/authData';
import api from '../services/api';
import apiTokenOrgid, { apiTokenWithBlob } from '../services/apiTokenOrgid';
import apiWithToken, { apiWithMultipartFormData } from '../services/apiWithToken';
import { types } from '../store/constants/types';
import { getUserAddress } from '../utils/utils';

// // Sign in
// export const postSignIn = (data) => {
//   return (dispatch) => {
//     api.post('/api/users/auth/sign-in', data)
//       .then((response) => {
//         localStorage.setItem('accessToken',response.data.data.accessToken);
//         dispatch({ type: 'POST_LOGIN_SUCCESS', payload: response.data });
//       })
//       .catch((error) => {
//         dispatch({ type: 'POST_LOGIN_FAILURE', payload: error && error.message && error.response.data.message });
//       });
//   };
// };

// Get super admin org lists
export const getSuperAdminOrg = () => { 
  return (dispatch) => {
    apiWithToken.get(`/api/superadmin/organisations`)
      .then((response) => {
        dispatch({ type: 'GET_SUPERADMIN_ORG_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'GET_SUPERADMIN_ORG_FAILURE', payload: error });
      });
  };
};

export const resetSuperAdminOrg = () => {
  return dispatch => {
     dispatch({type : 'GET_SUPERADMIN_ORG_SUCCESS',payload : []})
  }
}

// Get super admin user lists
export const getSuperAdminAllUsers = () => { 
  return (dispatch) => {
    apiWithToken.get(`/api/superadmin/users`)
      .then((response) => {
        dispatch({ type: 'GET_SUPERADMIN_USERLIST_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'GET_SUPERADMIN_USERLIST_FAILURE', payload: error });
      });
  };
};

export const resetSuperAdminAllUsers = () => {
  return dispatch => {
     dispatch({type : 'GET_SUPERADMIN_USERLIST_SUCCESS',payload : []})
  }
}

// Get super admin org lists
export const putUpdateOrgStatus = (oid,data) => { 
  return (dispatch) => {
    apiWithToken.put(`/api/superadmin/organisations/update-status/${oid}`,data)
      .then((response) => {
        dispatch({ type: 'PUT_UPDATE_ORG_STATUS_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'PUT_UPDATE_ORG_STATUS_FAILURE', payload: error });
      });
  };
};

export const resetUpdateOrgStatus = () => {
  return dispatch => {
     dispatch({type : 'PUT_UPDATE_ORG_STATUS_SUCCESS',payload : []})
  }
}