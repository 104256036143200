import { Fragment, React, useEffect, useMyCustomStuff, useState } from 'react';
import * as eva from 'eva-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltipb from 'react-bootstrap/Tooltip';
import { useDrag, useDrop } from 'react-dnd';

const All_Documents = () => {

  const [documents, setDocuments] = useState([
    { id: 1, days: '1 day ago', date: '22-Apr-2023', status: 'Draft', title: 'Nature of agreement', signers: 'John, Mariya', createdBy: 'You' },//badge badge-secondary
    { id: 2, days: '1 day ago', date: '22-Apr-2023', status: 'Fully Signed', title: 'Nature of agreement', signers: 'John, Mariya', createdBy: 'You' },//badge badge-primary
    { id: 3, days: '1 day ago', date: '22-Apr-2023', status: 'Request Sent', title: 'Nature of agreement', signers: 'John, Mariya', createdBy: 'You' },//badge badge-success
    { id: 4, days: '1 day ago', date: '22-Apr-2023', status: 'Waiting for you', title: 'Nature of agreement', signers: 'John, Mariya', createdBy: 'You' }//badge badge-warning
  ])
  useEffect(() => { eva.replace() });
  const TableRow = ({ index, data, moveRow }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'ROW',
      item: { index, data },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: 'ROW',
      drop: (item) => moveRow(item.index, index),
      canDrop: (item) => item.index !== index,
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
    }));
    return (
      <tr ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
        <td>
          <div className="dl-date">
            <div className='altem'>
              <div className='btngrouprht'>
                <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Edit">< i data-eva-animation="flip" data-eva="edit-outline"></i></a>
                <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Move">< i data-eva-animation="flip" data-eva="keypad-outline"></i></a>
              </div>
            </div>
            <span className='dl-ago'>{data.days}</span>
            <span className='dl-dmy'>{data.date}</span>
          </div>
        </td>
        <td>
          <div className='dl-status'>
            <span className={loadClass(data)}>{data.status}</span>
            <span>{data.title}</span>
          </div>
        </td>
        <td><div className='dl-status'>{data.signers}</div></td>
        <td><div className='dl-status'>{data.createdBy}</div></td>
        <td>
          <div className='dl-status'>
          <div className='btngrouprht '>
          <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Preview">< i data-eva-animation="flip" data-eva="external-link-outline"></i></a>
          <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Print">< i data-eva-animation="flip" data-eva="printer-outline"></i></a>
          <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Delete">< i data-eva-animation="flip" data-eva="trash-2-outline"></i></a>
        </div>
        </div>
        </td>
      </tr>

    );
  };
  const moveRow = (dragIndex, hoverIndex) => {
    const dragRow = documents[dragIndex];
    console.log(dragRow)
    setDocuments((prevState) => {
      const updatedRows = [...prevState];
      updatedRows.splice(dragIndex, 1);
      updatedRows.splice(hoverIndex, 0, dragRow);
      return updatedRows;
    });
  };



  const loadClass = (items) => {
    if (items.status === 'Draft') {
      return 'badge badge-secondary'
    } else if (items.status === 'Fully Signed') {
      return 'badge badge-primary'
    } else if (items.status === 'Request Sent') {
      return 'badge badge-success'
    } else if (items.status === 'Waiting for you') {
      return 'badge badge-warning'
    }
  }
  return (
    <Fragment>

      <div className='issuecerttabs'>

        <div className='formscroldiv1 fadein'>

          <div className='altermsbox'>
            <div className='altcont'>
              <div className='backgroundblur1'>
                <div className="tableresponsive">
                  <table id="sortable" className='table'>
                    <tr className="ui-state-default">
                      <th></th>
                      <th>Status</th>
                      <th>Signers</th>
                      <th>Created by</th>
                      <th width="200"></th>
                    </tr>
                    <tbody>
                      {documents && documents.map((items, index) => (
                        <TableRow
                          key={index}
                          index={index}
                          data={items}
                          moveRow={moveRow}
                        />
                      ))}
                    </tbody>
                    {/* {documents && documents.map((items,index) => (
                    <tr key={items.id}>
                      <td>
                        <div className="dl-date">
                          <div className='altem'>
                            <div className='btngrouprht'>
                              <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Edit">< i data-eva-animation="flip" data-eva="edit-outline"></i></a>
                              <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Move">< i data-eva-animation="flip" data-eva="keypad-outline"></i></a>
                            </div>
                          </div>
                          <span className='dl-ago'>{items.days}</span>
                          <span className='dl-dmy'>{items.date}</span>
                        </div>
                      </td>
                      <td>
                        <div className='dl-status'>
                          <span className={loadClass(items)}>{items.status}</span>
                          <span>{items.title}</span>
                        </div>
                      </td>
                      <td>{items.signers}</td>
                      <td>{items.createdBy}</td>
                      <td><div className='btngrouprht '>
                        <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Preview">< i data-eva-animation="flip" data-eva="external-link-outline"></i></a>
                        <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Print">< i data-eva-animation="flip" data-eva="printer-outline"></i></a>
                        <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Delete">< i data-eva-animation="flip" data-eva="trash-2-outline"></i></a>
                      </div></td>
                    </tr>))} */}

                  </table>
                </div>


              </div>
            </div>
          </div>

        </div>

      </div>



    </Fragment>


  );
}

export default All_Documents;