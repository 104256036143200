import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as eva from 'eva-icons';
import { checkemail } from '../utils/utils';
import {
    sendResetPasswordEmail,
    resetSendResetPasswordEmail,
    resetSendResetPasswordEmailFailed,
} from '../actions/authuserAction';
import { commonError } from 'actions/exampleAction';

const ForgotPassword = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [erroMessage, setErroMessage] = useState('');
    const [email, setEmail] = useState('');
    const [requestSuccess, setRequestSuccess] = useState(false);
    const resetRequestSuccess = useSelector(
        (state) => state.authUserReducer.sendResetPwdEmailSuccess
    );
    const resetRequestFailed = useSelector(
        (state) => state.authUserReducer.sendResetPwdEmailFailed
    );

    useEffect(() => {
        if (resetRequestSuccess && resetRequestSuccess.statusCode === 200) {
            setIsLoading(false);
            dispatch(resetSendResetPasswordEmail());
            setRequestSuccess(true);
            setEmail('');
        }
    }, [resetRequestSuccess]);
    
    useEffect(() => {
        if (resetRequestFailed && resetRequestFailed.statusCode) {
            setIsLoading(false);
            setRequestSuccess(false);
            dispatch(resetSendResetPasswordEmailFailed());
            if (
                resetRequestFailed &&
                resetRequestFailed.data &&
                resetRequestFailed.data.data &&
                resetRequestFailed.data.data[0] &&
                resetRequestFailed.data.data[0][0] &&
                typeof resetRequestFailed.data.data[0][0] === 'string'
            ) {
                dispatch(commonError(resetRequestFailed.data.data[0][0]))
            } else if (resetRequestFailed && resetRequestFailed.message) {
                dispatch(commonError(resetRequestFailed.message))
            }
            // const timeout = setTimeout(() => {
            //     setErroMessage('');
            // }, 5000);
            // return () => {
            //     clearTimeout(timeout);
            // };
        }
    }, [resetRequestFailed]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setErroMessage('');
        setIsLoading(true);
        // console.log(formData);
        if (!email || email === '') {
            dispatch(commonError('Please fill out all below fields'))
            setIsLoading(false);
            return;
        }
        if (checkemail(email) === false) {
            dispatch(commonError('Please provide a valid email address'))
            setIsLoading(false);
            return;
        }

        dispatch(sendResetPasswordEmail({ email }));
    };

    useEffect(() => {
        eva.replace();
    });

    return (
        <div className="logouter">
            <div className="logincon">
                <div className="loginimg">
                    <img
                        src={require('../assets/images/login-bg.jpg')}
                        alt=""
                        loading="lazy"
                    />
                    <div className="logtxtbox">
                        <h1>
                            Professional documents on{' '}
                            <span className="dartxtclr">blockchain</span>
                        </h1>
                    </div>
                </div>
                <div className="loginform fadein">
                    
                    <div className="main-content">
                        <div className="main-logo main-logo--cert">
                            <a href="">
                                <img
                                    src={require('../assets/images/logo.png')}
                                    alt="certifily"
                                    loading="lazy"
                                />
                            </a>
                        </div>
                        {requestSuccess === false ? (
                            <div id="log-in" className="base-layout">
                                <h5 className="title">Forgot Password</h5>
                                <p className="lighttxtclr mb-5">
                                    No Worries, we'll send you the instructions
                                    to reset your password.
                                </p>
                                <div className="remember-user">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                id="email"
                                                type="text"
                                                name="email"
                                                value={email}
                                                onChange={handleInputChange}
                                                maxLength="80"
                                                minLength="6"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="form-group text-center loginbtn">
                                            {isLoading ? (
                                                <button
                                                    type="button"
                                                    className="btn btn-light btn-icon btn-disabled bg-white filter-none"
                                                >
                                                    <img
                                                        src={require('../assets/images/certifi-loader.gif')}
                                                        loading="lazy"
                                                        alt="Loading..."
                                                        width={28}
                                                    />
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-primary text-uppercase"
                                                    type="submit"
                                                >
                                                    Reset Password
                                                </button>
                                            )}
                                        </div>

                                        <div className="form-group text-center mt-5">
                                            <NavLink
                                                to="/login"
                                                className={'loglinks'}
                                            >
                                                <i data-eva="arrow-back-outline"></i>{' '}
                                                Back to Login
                                            </NavLink>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        ) : (
                            <div className="remember-user">
                                <h5 className="title">
                                    Please check your inbox
                                </h5>
                                <p className="lighttxtclr mb-5">
                                    We have sent you an email with the
                                    instructions to reset your password.
                                </p>

                                <div className="form-group text-center mt-5">
                                    <NavLink to="/login" className={'loglinks'}>
                                        <i data-eva="arrow-back-outline"></i>{' '}
                                        Back to Login
                                    </NavLink>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
