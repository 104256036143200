import { useState,useEffect } from 'react';
import { useDrop } from 'react-dnd';

const DropZone = ({ id, onDrop, children }) => {
  // const [isOver, setIsOver] = useState(false);
  // const [canDrop, setCanDrop] = useState(false);
  
  const [{isOver,canDrop}, drop] = useDrop({
    accept: 'div',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item) => {
      // alert(`You dropped ${item.filename}`)
      onDrop(item, id);
    },
  });

  return (
    <div id={id} style={{ backgroundColor: isOver ? (canDrop ? '#005FFF' : 'red') : '', color: isOver ? (canDrop ? '#fff' : 'red') : '#96a7c6'  }} className="droparea">
      <div ref={drop} className='dafhtxt'>
        {isOver ? (canDrop ? 'Release to drop' : 'Cannot drop here') : 'Drag a file here'}
        {children}
      </div>
    </div>
  );
};

export default DropZone;
