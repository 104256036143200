import { checkOrganisationNameExist, commonError, commonSuccess, createOrganisation, getOrgDetail, getOrgSelfPermissions, getUserProfile, resetCheckOrganisationNameExist, resetCheckOrganisationNameExistFailed, resetCreateOrganisation, resetCreateOrganisationFailed, resetUpdateProfile, resetUpdateProfileFailed, updateProfile } from 'actions/exampleAction';
import { getAuth } from 'firebase/auth';
import { getSelectedUserOid, getSelectedUserRole, getUserEmail, getUserPhone, getUsername, logoutUser } from 'helpers/authData';
import { Fragment, React, useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkWithSpace, isOrgRoleEnabled, isUserRoleEnabled } from 'utils/utils';
import { checkemail } from 'utils/utils';
import { CertifiButton } from './shared/CertifiButton';
import * as eva from 'eva-icons';
import { Country, State, City } from "country-state-city";
import Select from "react-select";

const CreateOrganisation = () => {
    useEffect(() => { eva.replace() });
    const [orgname, setOrgname] = useState('')
    const [desc, setDesc] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [isNameUnique, setIsNameUnique] = useState(false)
    const [isNamecheckLoading, setIsNameCheckLoading] = useState(false)
    const [oldDesc, setOldDesc] = useState('')
    const [isCreated, setIsCreated] = useState(false)
    const [shortName, setShortName] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [city, setCity] = useState(null)
    const [selectedState, setSelectedState] = useState(null)
    const [country, setCountry] = useState(null)
    const [zipcode, setZipcode] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [website, setWebsite] = useState('')
    const [isValidPhnNo, setIsValidPhnNo] = useState(false)

    const createdSuccess = useSelector(state => state.demoReducer.organisationCreated);
    const createdFailed = useSelector(state => state.demoReducer.organisationCreatedFailed);
    const checkNameSuccess = useSelector(state => state.demoReducer.checkOrgnameexist);
    const checkNameFailed = useSelector(state => state.demoReducer.checkOrgnameexistfailed);

    const orgdetail = useSelector(state => state.demoReducer.orgDetail);
    const orgdetailFailed = useSelector(state => state.demoReducer.orgDetailFailed);
    const userProfileWithWallet = useSelector(state => state.demoReducer.userProfile);

    const minNameLength = 2
    const minDescLength = 10
    const timeoutRef = useRef(null);
    const shortNameMaxLength = 250
    const shortNameMinLength = 2
    const addressMaxLength = 500
    const addressMinLength = 10

    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {

    }, [])
    useEffect(() => {
        // if(orgdetail && orgdetail.statusCode === 200){
        //     if(orgdetail.data && orgdetail.data.organisation){
        //         setOrgname(orgdetail.data.organisation.name)
        //         setDesc(orgdetail.data.organisation.description)
        //         setOldDesc(orgdetail.data.organisation.description)
        //         setOldName(orgdetail.data.organisation.name)
        //     }
        // }
    }, [orgdetail])
    const handleName = (e) => {
        if(checkWithSpace(e.target.value)){
            setOrgname(e.target.value)
        }else{
            setOrgname('')
        }
    }
    const handleShortName = (e) => {
        if(checkWithSpace(e.target.value)){
            setShortName(e.target.value)
        }else{
            setShortName('')
        }
    }
    const handleAddress1 = (e) => {
        if(checkWithSpace(e.target.value)){
            setAddress1(e.target.value)
        }else{
            setAddress1('')
        }
    }
    const handleAddress2 = (e) => {
        if(checkWithSpace(e.target.value)){
            setAddress2(e.target.value)
        }else{
            setAddress2('')
        }
    }
    const handleCity = (value) => {
        setCity(value)
    }
    const handleState = (value) => {
        setSelectedState(value)
        setCity(null)
    }
    const handleEmail = (e) => {
        if(checkWithSpace(e.target.value)){
            setEmail(e.target.value.toLowerCase())
        }else{
            setEmail('')
        }
    }
    const handleWebsite = (e) => {
        if(checkWithSpace(e.target.value)){
            setWebsite(e.target.value)
        }else{
            setWebsite('')
        }
    }
    const handlephone = (e) => {
        setPhone(e.target.value)
    }
    const handleCountry = (value) => {
        setCountry(value)
        setSelectedState(null)
        setCity(null)
    }
    const handleZipcode = (e) => {
        if(checkWithSpace(e.target.value)){
            setZipcode(e.target.value)
        }else{
            setZipcode('')
        }
    }
    const handleDesc = (e) => {
        if(checkWithSpace(e.target.value)){
            setDesc(e.target.value)
        }else{
            setDesc('')
        }
    }
    const submitData = (e) => {
        e.preventDefault()
        setSubmitted(true)
        const formData = { orgname, desc, shortName, website, email, country, selectedState, city, address1, address2, zipcode, phone }
        if (!formData.orgname || 
            !formData.desc || 
            !checkDescLength() || 
            !checkNameLength() ||
            !formData.shortName || 
            !checkShortNameLength() ||
            !formData.website ||
            !checkURL(website) ||
            !formData.email ||
            !checkemail(email) ||
            !checkAddress(address1) ||
            !checkAddress(address2) ||
            !formData.address1 ||
            !formData.address2 ||
            !formData.country ||
            // !formData.selectedState || 
            // !formData.city || 
            !formData.phone || 
            !formData.zipcode
            ) {
            return;
        } else {

            let data = {
                name: formData.orgname,
                description: formData.desc
            }

            if (isNameUnique && !isNamecheckLoading) {
                if (phone !== '') {
                    if (isValidPhnNo) {
                        data['phone'] = `+${phone}`
                    } else {
                        return;
                    }
                }
                if (website !== '') {
                    if (checkURL(website)) {
                        data['website'] = website
                    } else {
                        return;
                    }
                }
                if (email !== '') {
                    if (checkemail(email)) {
                        data['email'] = email
                    } else {
                        return;
                    }
                }
                if (shortName !== '') {
                    if (checkShortNameLength()) {
                        data['shortName'] = shortName
                    } else {
                        return;
                    }
                }
                if (address1 !== '') {
                    if (checkAddress(address1)) {
                        data['addressLine1'] = address1
                    } else {
                        return;
                    }
                }
                if (address2 !== '') {
                    if (checkAddress(address2)) {
                        data['addressLine2'] = address2
                    } else {
                        return;
                    }
                }
                if (country !== null) {
                    data['country'] = country.name
                }
                if (selectedState !== null) {
                    data['state'] = selectedState.name
                }
                if (city !== null) {
                    data['city'] = city.name
                }
                if(zipcode !== ''){
                    data['zipCode'] = zipcode
                }
                setIsLoading(true)
                dispatch(createOrganisation(data))
            }

        }

    }



    useEffect(() => {
        if (createdSuccess && createdSuccess.statusCode === 200) {
            dispatch(resetCreateOrganisation())
            dispatch(commonSuccess("Created successfully"))
            setSubmitted(false)
            dispatch(getUserProfile())
            setIsNameCheckLoading(false)
            setIsNameUnique(false)
            setDesc('')
            setIsCreated(true)
            timeoutRef.current = null

        }
    }, [createdSuccess])
    useEffect(() => {
        if (isCreated === true) {
            if (userProfileWithWallet && userProfileWithWallet.data && userProfileWithWallet.data.organistaions) {
                const lastCreatedOrg = userProfileWithWallet.data.organistaions.filter(e => e.name === orgname)
                setOrgname('')
                if (lastCreatedOrg && lastCreatedOrg[0] && lastCreatedOrg[0].oid) {

                    setIsLoading(false)
                    localStorage.setItem("selectedRole", JSON.stringify(lastCreatedOrg[0]))
                    navigate(`/institution/${lastCreatedOrg[0].oid}/dashboard`)
                    dispatch(getOrgSelfPermissions(lastCreatedOrg[0].oid))
                } else {
                    setIsLoading(false)
                    navigate('/dashboard')
                }
            }
            setIsCreated(false)
        }

    }, [userProfileWithWallet])
    useEffect(() => {
        if (createdFailed && createdFailed.statusCode) {
            dispatch(resetCreateOrganisationFailed())
            setIsLoading(false)
            console.log(createdFailed)
            if (createdFailed.statusCode === 401) {
                navigate('/login')
            } else if (createdFailed.statusCode === 403) {
                dispatch(commonError('Access denied'))
            } else if (createdFailed && createdFailed.data && createdFailed.data[0] && createdFailed.data[0][0] && typeof createdFailed.data[0][0] === 'string') {
                if (createdFailed.data[0][0].toLowerCase().includes('organisation')) {
                    const error = createdFailed.data[0][0].replace(/organisation/gi, "institution")
                    dispatch(commonError(error))
                }else if(createdFailed.data[0][0].toLowerCase().includes('website must be a')){
                    dispatch(commonError(`Enter website in this format "https://xyz.com"`))
                }else {
                    dispatch(commonError(createdFailed.data[0][0]))
                }
            } else if (createdFailed && createdFailed.message) {
                if (createdFailed.message.toLowerCase().includes('organisation')) {
                    const error = createdFailed.message.replace(/organisation/gi, "institution")
                    dispatch(commonError(error))
                } else {
                    dispatch(commonError(createdFailed.message))
                }
            }
        }
    }, [createdFailed])
    useEffect(() => {
        if (checkNameSuccess && checkNameSuccess.statusCode === 200) {
            dispatch(resetCheckOrganisationNameExist())
            setIsNameCheckLoading(false)
            if (checkNameSuccess && checkNameSuccess.data && checkNameSuccess.data.isExists === false) {
                setIsNameUnique(true)
            } else {
                setIsNameUnique(false)
            }
        }
    }, [checkNameSuccess])
    useEffect(() => {
        if (checkNameFailed && checkNameFailed.statusCode) {
            dispatch(resetCheckOrganisationNameExistFailed())
            setIsNameCheckLoading(false)
            if (checkNameFailed.statusCode === 401) {
                navigate('/login')
            } if (checkNameFailed.statusCode === 403) {
                dispatch(commonError('Access denied'))
            } else {
                setIsNameUnique(true)
            }
        }
    }, [checkNameFailed])
    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        if ((orgname !== '') && (orgname.length >= (minNameLength))) {
            timeoutRef.current = setTimeout(() => {
                setIsNameUnique(false)
                setIsNameCheckLoading(true)
                dispatch(checkOrganisationNameExist(encodeURIComponent(orgname)))
            }, 500);
        }

    }, [orgname]);
    const checkNameLength = () => {
        if ((orgname.trim().length) >= (minNameLength)) {
            return true
        } else {
            return false
        }
    }
    const checkDescLength = () => {
        if ((desc.trim().length) >= (minDescLength)) {
            return true
        } else {
            return false
        }
    }
    const checkShortNameLength = () => {
        if ((shortName.trim().length) >= (shortNameMinLength) && ((shortName.trim().length) <= (shortNameMaxLength))) {
            return true
        } else {
            return false
        }
    }
    const checkAddress = (address) => {
        if ((address.trim().length) >= (addressMinLength) && ((address.trim().length) <= (addressMaxLength))) {
            return true
        } else {
            return false
        }
    }
    const checkOrgDescAsold = (desc) => {
        if (desc === oldDesc) {
            return true
        } else {
            return false
        }
    }
    const checkURL = (value) => {
        const urlReg = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
        if (urlReg.test(value)) {
            return true;
        }
        return false;
    }
    const [previewImage, setPreviewImage] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const handleUploadImage = () => {
        const data = new FormData();
        data.append('files[]', previewImage);

        fetch({ method: 'POST', body: data }).then(async (response) => {
            const imageResponse = await response.json();
            setUploadedImage(imageResponse);
        }).catch((err) => {

        });
    }

    const handleSelectImage = (event) => {
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            setPreviewImage(fileReader.result);
        });
        fileReader.readAsDataURL(file);
    }
    const isNumbervalid = (value, country) => {
        if (value.match(/12345/)) {
            setIsValidPhnNo(false)
            return 'Invalid value: ' + value + ', ' + country.name;
        } else if (value.match(/1234/)) {
            setIsValidPhnNo(false)
            return false
        } else {
            setIsValidPhnNo(true)
            return true;
        }
        
    }
    
    return (
        <Fragment>
            <div className='scrolldiv1'>
                <div className='row '>
                    <div className='col-md-12 text-start'>
                        <div className=''>

                            <div className='backgroundblur'>
                                <div className='searchform border-none d-block'>
                                    <form onSubmit={submitData}>
                                        <div className='formscroldiv1 px-3'>

                                            {/* <h6 className='mb-3 fw-bold'>Organisation</h6> */}
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className='cly-proimg'>
                                                        <div className='cly-pimg'>
                                                            {orgname.length < 2 ? <img src={previewImage} alt="" loading="lazy" /> : <span className='instletter'>{orgname.slice(0,2).toUpperCase()}</span>}
                                                            {orgname.length < 2 ? <img src={uploadedImage} alt="" loading="lazy" /> : <span className='instletter'>{orgname.slice(0,2).toUpperCase()}</span>}
                                                            {/* <label className='cly-upimg' htmlFor='clyupimg' onClick={handleUploadImage}>
                                                                <input type="file" id='clyupimg' onChange={handleSelectImage} />
                                                                <i data-eva-animation="flip" data-eva="camera-outline"></i>
                                                                <p>Change picture</p>
                                                            </label> */}
                                                        </div>
                                                        <p className='small text-center mb-1 d-none'>Hover to upload picture</p>
                                                        <p className='small text-center mb-0 d-none'>Picture size : <span>400w x 400h</span></p>
                                                    </div>
                                                </div>

                                                <div className='col-md-8'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>Name *</label>
                                                                <input
                                                                    name="name"
                                                                    type={'text'}
                                                                    value={orgname}
                                                                    className="form-control"
                                                                    placeholder='Name'
                                                                    onChange={handleName}
                                                                />

                                                                {submitted && !orgname && <div className='mandatory'>Required</div>}
                                                                {submitted && orgname && !checkNameLength() && <div className='mandatory'>Minimum {minNameLength} characters</div>}
                                                                {orgname && !isNameUnique && !isNamecheckLoading && timeoutRef.current !== null && <div className='mandatory'>Institution name already exist</div>}
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>Short name *</label>
                                                                <input
                                                                    name="short_name"
                                                                    type={'text'}
                                                                    value={shortName}
                                                                    className="form-control"
                                                                    placeholder='Short name'
                                                                    onChange={handleShortName}
                                                                />
                                                                {submitted && !shortName && <div className='mandatory'>Required</div>}
                                                                {submitted && shortName !== '' && !checkShortNameLength() && <div className='mandatory'>Min {shortNameMinLength} - Max {shortNameMaxLength}</div>}

                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>Email *</label>
                                                                <input
                                                                    name="email"
                                                                    type={'text'}
                                                                    value={email}
                                                                    className="form-control"
                                                                    placeholder='Email'
                                                                    onChange={handleEmail}
                                                                />
                                                                {submitted && !email && <div className='mandatory'>Required</div>}
                                                                {submitted && email !== '' && !checkemail(email) &&  <div className='mandatory'>Invalid email</div>}
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>Website *</label>
                                                                <input
                                                                    name="website"
                                                                    type={'text'}
                                                                    value={website}
                                                                    className="form-control"
                                                                    placeholder='Website'
                                                                    onChange={handleWebsite}
                                                                />
                                                                {<div className='mandatory-req'>Ex : https://xyz.com</div>}
                                                                {submitted && !website && <div className='mandatory'>Required</div>}

                                                                {submitted && website !== '' && !checkURL(website) && <div className='mandatory'>Invalid website url</div>}
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>Address Line1 *</label>
                                                                <input
                                                                    name="address1"
                                                                    type={'text'}
                                                                    value={address1}
                                                                    className="form-control"
                                                                    placeholder='Address Line1'
                                                                    onChange={handleAddress1}
                                                                />
                                                                {submitted && !address1 && <div className='mandatory'>Required</div>}

                                                                {submitted && address1 !== '' && !checkAddress(address1) && <div className='mandatory'>Min {addressMinLength} - Max {addressMaxLength}</div>}
                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>Address Line2 *</label>
                                                                <input
                                                                    name="address2"
                                                                    type={'text'}
                                                                    value={address2}
                                                                    className="form-control"
                                                                    placeholder='Address Line2'
                                                                    onChange={handleAddress2}
                                                                />
                                                                {submitted && !address2 && <div className='mandatory'>Required</div>}
                                                                {submitted && address2 !== '' && !checkAddress(address2) && <div className='mandatory'>Min {addressMinLength} - Max {addressMaxLength}</div>}

                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>Country *</label>
                                                                <div className='cly-cusel'>
                                                                <Select
                                                                    options={Country.getAllCountries()}
                                                                    getOptionLabel={(options) => {
                                                                        return options["name"];
                                                                    }}
                                                                    getOptionValue={(options) => {
                                                                        return options["name"];
                                                                    }}
                                                                    value={country}
                                                                    onChange={handleCountry}
                                                                    placeholder="Select Country"
                                                                />
                                                                </div>
                                                                {submitted && !country && <div className='mandatory'>Required</div>}


                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>State</label>
                                                                <div className='cly-cusel'>
                                                                <Select
                                                                    options={State.getStatesOfCountry(country?.isoCode)}
                                                                    getOptionLabel={(options) => {
                                                                        return options["name"];
                                                                    }}
                                                                    getOptionValue={(options) => {
                                                                        return options["name"];
                                                                    }}
                                                                    value={selectedState}
                                                                    onChange={handleState}
                                                                    placeholder="Select State"
                                                                /></div>
                                                                {/* {submitted && !selectedState && <div className='mandatory'>Required</div>} */}

                                                            </div>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>City</label>
                                                                <div className='cly-cusel'>
                                                                <Select
                                                                    options={City.getCitiesOfState(
                                                                        selectedState?.countryCode,
                                                                        selectedState?.isoCode
                                                                    )}
                                                                    getOptionLabel={(options) => {
                                                                        return options["name"];
                                                                    }}
                                                                    getOptionValue={(options) => {
                                                                        return options["name"];
                                                                    }}
                                                                    value={city}
                                                                    onChange={handleCity}
                                                                    placeholder="Select city"
                                                                />
                                                                {/* {submitted && !city && <div className='mandatory'>Required</div>} */}

                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>Zip Code *</label>
                                                                <input
                                                                    name="zip"
                                                                    type={'text'}
                                                                    value={zipcode}
                                                                    className="form-control"
                                                                    placeholder='Zip'
                                                                    onChange={handleZipcode}
                                                                />
                                                                {submitted && !zipcode && <div className='mandatory'>Required</div>}

                                                            </div>
                                                        </div>

                                                        <div className='col-md-4'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>Contact Number *</label>
                                                                <PhoneInput
                                                                    value={phone}
                                                                    onChange={phone => setPhone(phone)}
                                                                    placeholder="Contact Number *"
                                                                    inputClass="form-control"
                                                                    country={'us'}
                                                                    isValid={(value, country) => isNumbervalid(value, country)}
                                                                />
                                                                {submitted && !phone && <div className='mandatory'>Required</div>}
                                                            </div>
                                                        </div>

                                                        <div className='col-md-12'>
                                                            <div className='form-group'>
                                                                <label className='mb-2'>Description *</label>
                                                                <textarea
                                                                    name="name"
                                                                    type={'text'}
                                                                    value={desc}
                                                                    className="form-control"
                                                                    placeholder='Description'
                                                                    onChange={handleDesc}

                                                                />
                                                                {submitted && !desc && <div className='mandatory'>Required</div>}
                                                                {submitted && desc && !checkDescLength() && <div className='mandatory'>Minimum {minDescLength} characters</div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                        <hr className='light-brd' />
                                        <div className='row align-items-center'>
                                            <div className='col-12 text-center'>
                                                <div className='btngrouprht'>
                                                    {isLoading ?
                                                        <button
                                                            type="button"
                                                            className="btn btn-light btn-icon btn-disabled bg-white filter-none"
                                                        >

                                                            <img
                                                                src={require('../assets/images/certifi-loader.gif')}
                                                                loading="lazy"
                                                                alt="Loading..." width={28}
                                                            />
                                                        </button>
                                                        :
                                                        <CertifiButton type="submit" className='btn btn-primary btn-icon' onClick={submitData} name={'Save'} image="save-outline.png" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>


    );
}

export default CreateOrganisation;