import { signInWithEmailAndPassword, onAuthStateChanged, getUserData, getAuth } from 'firebase/auth';
import {auth} from '../firebase-config';
import api from '../services/api';
import { types } from '../store/constants/types';


// Get user profile
export const getAuthUserDetail = () => { 
  return async (dispatch) => {
    try {
      await onAuthStateChanged(getAuth(), async (currentUser) => {
        if (currentUser) {
          localStorage.setItem("isAuthenticated", true);
          // const user = await getUserData(currentUser.uid)
          const user = await getAuth().currentUser.getIdTokenResult()
          let obj = {
            statusCode : 200,
            data : user && user.claims
          }
          dispatch({
            type: types.GET_AUTHUSER_SUCCESS,
            payload: obj
          });
        // } else {
          // localStorage.removeItem("isAuthenticated");
          // dispatch({
          //   type: types.GET_AUTHUSER_FAILED,
          //   payload: "",
          // });
        }
      });
    } catch (error) {
      dispatch({
        type: types.GET_AUTHUSER_FAILED,
        payload: "Failed to get user info",
      });
    }
    
  };
};
export const resetAuthUserDetail = () => {
  return dispatch => {
    dispatch({type : types.GET_AUTHUSER_SUCCESS,payload : []})
  }
}
export const resetAuthUserDetailFailed = () => {
  return dispatch => {
    dispatch({type : types.GET_AUTHUSER_FAILED,payload : ''})
  }
}
export const postSignUp = (data) => {
  return (dispatch) => {
    api.post('/api/users/auth/signup', data)
      .then((response) => {
        // localStorage.setItem('accessToken',response.data.data.token);
        dispatch({ type: types.POST_USER_SIGNUP, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.POST_USER_SIGNUP_FAILED, payload: (error && error.response && error.response.data) ? error.response.data : "Something went wrong" });
      });
  };
};
export const resetSignupInfo = () => {
  return dispatch => {
     dispatch({type : types.POST_USER_SIGNUP,payload : []})
}}
export const resetSignupInfoFailed = () => {
  return dispatch => {
     dispatch({type : types.POST_USER_SIGNUP_FAILED,payload : ''})
}}
export const authpostSignIn = (email,pass) => {
  return (dispatch) => {
    signInWithEmailAndPassword(getAuth(),email,pass)
      .then((response) => {
        localStorage.setItem('accessToken', response._tokenResponse.idToken)
        localStorage.setItem('refreshToken', response._tokenResponse.refreshToken)
        localStorage.setItem('user_email', email);
        let obj = {
          statusCode : 200,
          data : response
        }
        dispatch({ type: types.POST_SIGNIN, payload: obj });
      })
      .catch((error) => {
        dispatch({ type: types.POST_SIGNIN_FAILED, payload: error });
      });
  };
};
export const resetauthPostSignIn = () => {
  return dispatch => {
     dispatch({type : types.POST_SIGNIN,payload : []})
}}
export const resetauthPostSignInFailed = () => {
  return dispatch => {
     dispatch({type : types.POST_SIGNIN_FAILED,payload : ''})
}}


export const scheduleDemoCall = (data) => {
  return (dispatch) => {
    api.post('/api/demo-requests', data)
      .then((response) => {
        dispatch({ type: types.SCHEDULE_DEMO_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.SCHEDULE_DEMO_FAILED, payload: (error.response.data)});
      });
  };
};
export const resetScheduleDemo = () => {
  return dispatch => {
     dispatch({type : types.SCHEDULE_DEMO_SUCCESS,payload : []})
}}
export const resetScheduleDemoFailed = () => {
  return dispatch => {
     dispatch({type : types.SCHEDULE_DEMO_FAILED,payload : ''})
}}


export const sendResetPasswordEmail = (data) => {
  return (dispatch) => {
    api.post('/api/users/auth/reset-password', data)
      .then((response) => {
        dispatch({ type: types.PWD_RESET_EMAIL_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.PWD_RESET_EMAIL_FAILED, payload: (error.response.data) });
      });
  };
};
export const resetSendResetPasswordEmail = () => {
  return dispatch => {
    dispatch({ type: types.PWD_RESET_EMAIL_SUCCESS, payload: { email: '' } })
  }
}
export const resetSendResetPasswordEmailFailed = () => {
  return dispatch => {
    dispatch({ type: types.PWD_RESET_EMAIL_FAILED, payload: '' })
  }
}

export const resendVerificationEmail = (uid) => {
  return (dispatch) => {
    api.post(`/api/users/auth/${uid}/verify-email`)
      .then((response) => {
        dispatch({ type: types.RESEND_VERIFICATION_EMAIL, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.RESEND_VERIFICATION_EMAIL_FAILED, payload: error.response.data });
      });
  };
};
export const resetResendVerificationEmail = () => {
  return dispatch => {
     dispatch({type : types.RESEND_VERIFICATION_EMAIL,payload : []})
}}

export const resetResendVerificationEmailFailed = () => {
  return dispatch => {
     dispatch({type : types.RESEND_VERIFICATION_EMAIL_FAILED,payload : {}})
}}