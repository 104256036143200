import { React, useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletAddress, isEmailVerifiedCheck, resetCommonError, resetCommonSuccess, resetWalletAddressSuccess } from '../../actions/exampleAction';
import { getAuth, signOut } from 'firebase/auth';
import { auth } from '../../firebase-config';
import { getOrgName, getUsername, logoutUser } from '../../helpers/authData';
import { connectBlocto } from 'helpers/ConnectWallet';
import Profile from 'components/Profile';
import { getuserName } from 'utils/utils';

const ProfileArea = () => {

  let userDetail;
  let userOrg = getOrgName();
  const [address, setAddress] = useState('')
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const walletaddress = useSelector(state => state.demoReducer.walletAddress);
  const errorMessageshown = useSelector(state => state.demoReducer.commonErrorMessage);
  const successMessageshown = useSelector(state => state.demoReducer.commonSuccessMessage);
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showClass,setShowClass] = useState('')

  const userProfileWithWallet = useSelector(state => state.demoReducer.userProfile);
  if(userProfileWithWallet && userProfileWithWallet.data && userProfileWithWallet.data.name){
    userDetail = userProfileWithWallet.data
  }
  var timeout;
  useEffect(() => {
    if (errorMessageshown && errorMessageshown !== '') {
      setErrorMessage(errorMessageshown)
      setTimeout(() => {
        setShowClass('show')
      }, 200)
      setTimeout(() => {
        setShowClass('hide')
      }, 4000)
      dispatch(resetCommonError())
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setErrorMessage('')
        setShowClass('')
      }, 5000)
    }
  }, [errorMessageshown])
  useEffect(() => {
    if (successMessageshown && successMessageshown !== '') {
      setSuccessMessage(successMessageshown)
      setTimeout(() => {
        setShowClass('show')
      }, 200)
      setTimeout(() => {
        setShowClass('hide')
      }, 4000)
      dispatch(resetCommonSuccess())
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setSuccessMessage('')
        setShowClass('')
      }, 5000)
    }
  }, [successMessageshown])
  function logout() {
    signOut(getAuth()).then(() => {
      logoutUser()
      // dispatch(isEmailVerifiedCheck())
      navigate("/");
    }).catch((error) => {
      // An error happened.
    });



  }

  useEffect(() => {
    if (walletaddress && walletaddress !== null && walletaddress.length > 0) {
      setAddress(walletaddress)
      dispatch(resetWalletAddressSuccess())
    }

  }, [walletaddress])
  useEffect(() => {
    dispatch(getWalletAddress())
  }, [])
  const connectwallet = async () => {
    const wallet = await connectBlocto()
    if (wallet && wallet.walletAddress) {
      dispatch(getWalletAddress())
    }
  }
  const user = "user"
  return (

    <div className='content-header mb-0'>      
      {/* {(successMessage !== '') ? <div className={`alert alert-success cf-alert text-center py-3 ${showClass} alert-top`} role="alert">{successMessage}</div> : ''}
      {(errorMessage !== '') ? <div className={`alert alert-danger cf-alert text-center py-3 ${showClass} alert-top`} role="alert">{errorMessage}</div> : ''} */}
      <div className='profile-area'>
        <div className='profile-cont'>
          <p className='mb-1 profilename' data-tooltip={getuserName(userDetail)}>Welcome <span className='username' >{getuserName(userDetail)}</span></p>
          {/* <p className='mb-1 profilename'>{address}</p> */}
          {address ? <p className='mb-1 profile-cont profilename'><a className="btn btn-light btn-sm text-primary p-2" href="#">{address}</a></p> :
            <p className='mb-1 profile-cont' onClick={connectwallet}><a className="btn btn-light btn-sm text-primary p-2" href="#">Connect Wallet</a></p>}
          {/* {user.includes('user') ? '' : <div className='userOrg'>
            <img src={require('../../assets/images/icons/college.png')} loading="lazy" />
            <h5 className='fw-bolder text-uppercase'>{userOrg}</h5>

          </div>} */}
          {/* {address ? <p>{address}</p> : ''} */}
          {/* <p className='lastlogin mb-0'>Last Login: Jul-17-2022 | 10:00</p> */}
          {/* <p className='lastlogin mb-0'><button className="btn btn-light btn-sm text-primary" onClick={logout} ><i data-eva="log-out-outline"></i> Logout</button></p> */}
        </div>
        <div className="dropdown">
          <span
            id="dropdownMenuButton"
            data-mdb-toggle="dropdown"
            aria-expanded="false"
          >
            <div className='profile-icon'>
              <img className='headerprofilepic' src={require('../../assets/images/photo10.png')} loading="lazy" />
            </div>
          </span>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {/* <li><a className="dropdown-item text-primary">{address}</a></li> */}            
            <li><NavLink to="/profile" className="dropdown-item"><i data-eva="person-outline"></i> Account</NavLink></li>
            <li><NavLink to="/billing" className="dropdown-item"><i data-eva="credit-card-outline"></i> Billing & Payments</NavLink></li>            
            <li><a className="dropdown-item" href="#" onClick={logout}><i data-eva="log-out-outline"></i> Logout</a></li>
          </ul>
        </div>
      </div>
    </div>


  );
}

export default ProfileArea;