import { getAuth, signOut } from 'firebase/auth';
import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getOrgName, getSelectedUserOid, getSelectedUserRole, getUserRole, getUsername, logoutUser } from '../../helpers/authData';
import { resetWalletAddressSuccess } from 'actions/exampleAction';
import { canAccessMenu, canAccessMenuWithRole, getuserName } from 'utils/utils';
import SidebarDropdown from './SidebarDropdown';
import { configPaths } from 'config';

const Sidemenu = (props) => {
  let userDetail = {}
  let services = []
  let oid = getSelectedUserOid()
  const userProfileWithWallet = useSelector(state => state.demoReducer.userProfile);

  if (userProfileWithWallet && userProfileWithWallet.data && userProfileWithWallet.data.name) {
    userDetail = userProfileWithWallet.data
    if (userDetail && userDetail.services) {
      services = userDetail.services

    }
  }
 
  const [selectType, setSelectType] = "1"
  const [address, setAddress] = useState('')
  const navigate = useNavigate();
  const [isActive5, setActive5] = useState(false);

  const onChangeValue = () => { }
  const walletaddress = useSelector(state => state.demoReducer.walletAddress);

  const dispatch = useDispatch()
  useEffect(() => {
    if (walletaddress && walletaddress !== null && walletaddress.length > 0) {
      setAddress(walletaddress)
      dispatch(resetWalletAddressSuccess())
    }

  }, [walletaddress])


  function logout() {
    signOut(getAuth()).then(() => {
      logoutUser()
      navigate("/");
    }).catch((error) => {
      logoutUser()
      navigate("/");
    });
  }

  const toggleNav = () => { props.toggleNav() };
  // console.log(isActive5)
  const handleToSwitch = (items) => {
    if(items && items.oid){
      localStorage.setItem("selectedRole",JSON.stringify(items))
      navigate(`/institution/${items.oid}/dashboard`)
    }
  }
  const backToUser = () => {
    localStorage.removeItem('selectedRole')
    navigate('/dashboard')
  }

  
  return (
    <nav id="sidebarMenu" className={(props.isopen) ? 'collapse sidebar pt-0 show' : 'collapse sidebar pt-0'}  >
      <div className="position-sticky">
        <div className="list-group list-group-flush text-start sidemenupanel">
          <SidebarDropdown/>
          <div className="accordion accordion-borderless" id="accordionFlushExampleX">
            {/* /*dashboard */}
            {canAccessMenuWithRole(getSelectedUserRole()) ? <div className="accordion-item" onClick={toggleNav}>
              <h2 className="accordion-header" id="flush-headingOneX1">
                <div data-mdb-toggle="collapse" data-mdb-target="#flush-collapseOneX1" aria-expanded="true" aria-controls="flush-collapseOneX1">
                  <NavLink to={`/institution/${oid}/dashboard`} className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'>
                      <img
                        src={require('../../assets/images/icons/airplay.png')}
                        className=''
                      />
                    </div>
                    <span>Dashboard</span>
                  </NavLink>
                </div>
              </h2>
              <div id="flush-collapseOneX1" className="accordion-collapse collapse show" aria-labelledby="flush-collapseOneX1" data-mdb-parent="#accordionFlushExampleX"></div>
            </div> : <div className="accordion-item" onClick={toggleNav}>
              <h2 className="accordion-header" id="flush-headingOneX1">
                <div data-mdb-toggle="collapse" data-mdb-target="#flush-collapseOneX1" aria-expanded="true" aria-controls="flush-collapseOneX1">
                  <NavLink to="/dashboard" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'>
                      <img
                        src={require('../../assets/images/icons/airplay.png')}
                        className=''
                      />
                    </div>
                    <span>Dashboard</span>
                  </NavLink>
                </div>
              </h2>
              <div id="flush-collapseOneX1" className="accordion-collapse collapse show" aria-labelledby="flush-collapseOneX1" data-mdb-parent="#accordionFlushExampleX"></div>
            </div>}
            
            <div className="accordion-item" onClick={toggleNav}>
              <h2 className="accordion-header" id="flush-headingOneX3">
                <div data-mdb-toggle="collapse" data-mdb-target="#flush-collapseOneX3" aria-expanded="true" aria-controls="flush-collapseOneX3">
                  <NavLink to={`/institution/${oid}/create/all`} className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'>
                      <img
                        src={require('../../assets/images/icons/award.png')}
                        className=''
                      />
                    </div>
                    <span>Create</span>
                  </NavLink>
                </div>
              </h2>
              <div id="flush-collapseOneX3" className="accordion-collapse collapse show" aria-labelledby="flush-collapseOneX3" data-mdb-parent="#accordionFlushExampleX">

              </div>
            </div>
            {canAccessMenuWithRole(getSelectedUserRole()) ? <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOneX2M">
                <div data-mdb-toggle="collapse" data-mdb-target="#flush-collapseOneX2M" aria-expanded="true" aria-controls="flush-collapseOneX2M">


                  <button className="list-group-item list-group-item-action text-uppercase accordion-button" type="button" data-mdb-toggle="collapse"
                    data-mdb-target="#flush-collapseOneX2M" aria-expanded="fasle" aria-controls="flush-collapseOneX2M">
                    <div className='sidebaricons'>
                      <img
                        src={require('../../assets/images/icons/folder-with-file.png')}
                        className=''
                      />
                    </div>
                    <span>My Documents</span>
                  </button>
                </div>
              </h2>
              <div id="flush-collapseOneX2M" className="accordion-collapse collapse" aria-labelledby="flush-collapseOneX2M" data-mdb-parent="#accordionFlushExampleX">
                <div className="accordion-body p-0 ps-3">
                  <NavLink onClick={toggleNav} to={`/institution/${oid}/certificates/all`} className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/certificate.png')} className='' /></div> <span>CERTIFICATES</span>
                  </NavLink>
                  <NavLink className={({ isActive }) => (isActive ? " list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} style={{pointerEvents:"none"}}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/documents-1.png')} className='' /></div> <span>DOCUMENTS</span>
                  </NavLink>
                  <NavLink  className={({ isActive }) => (isActive ? " list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} style={{pointerEvents:"none"}}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/letters.png')} className='' /></div> <span>LETTERS</span>
                  </NavLink>
                  <NavLink  className={({ isActive }) => (isActive ? " list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} style={{pointerEvents:"none"}}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/ticket.png')} className='' /></div> <span>TICKETS</span>
                  </NavLink>
                </div>
              </div>
            </div> : ''}
            {canAccessMenuWithRole(getSelectedUserRole()) ? <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOneX2">
                <div data-mdb-toggle="collapse" data-mdb-target="#flush-collapseOneX2" aria-expanded="true" aria-controls="flush-collapseOneX2">


                  <button className="list-group-item list-group-item-action text-uppercase accordion-button" type="button" data-mdb-toggle="collapse"
                    data-mdb-target="#flush-collapseOneX2" aria-expanded="fasle" aria-controls="flush-collapseOneX2">
                    <div className='sidebaricons'>
                      <img
                        src={require('../../assets/images/icons/users.png')}
                        className=''
                      />
                    </div>
                    <span>Users</span>
                  </button>
                </div>
              </h2>
              <div id="flush-collapseOneX2" className="accordion-collapse collapse" aria-labelledby="flush-collapseOneX2" data-mdb-parent="#accordionFlushExampleX">
                <div className="accordion-body p-0 ps-3">
                  <NavLink onClick={toggleNav} to={`/institution/${oid}/users`} className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/users.png')} className='' /></div> <span>USERS</span>
                  </NavLink>
                  <NavLink onClick={toggleNav} to={`/institution/${oid}/add-user`} className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/plus.png')} className='' /></div> <span>ADD USER</span>
                  </NavLink>

                  {/* <NavLink onClick={toggleNav} to={`/institutions/${oid}/users-import`}  className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/import.png')} className='' /></div> <span>IMPORT USERS</span>
                  </NavLink> */}
                </div>
              </div>
            </div> : ''}

            {/* <div className="accordion-item" onClick={toggleNav}>
              <h2 className="accordion-header" id="flush-headingOneX3">
                <div data-mdb-toggle="collapse" data-mdb-target="#flush-collapseOneX5" aria-expanded="true" aria-controls="flush-collapseOneX5">
                  <NavLink to={`/institution/${oid}/certificates/all`} className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'>
                      <img
                        src={require('../../assets/images/icons/certificate.png')}
                        className=''
                      />
                    </div>
                    <span>Certificates</span>
                  </NavLink>
                </div>
              </h2>
              <div id="flush-collapseOneX5" className="accordion-collapse collapse show" aria-labelledby="flush-collapseOneX5" data-mdb-parent="#accordionFlushExampleX">

              </div>
            </div> */}
            {canAccessMenuWithRole(getSelectedUserRole()) ? 
            <div className="accordion-item" onClick={toggleNav}>
              <h2 className="accordion-header" id="flush-library">
                <div data-mdb-toggle="collapse" data-mdb-target="#flush-template" aria-expanded="true" aria-controls="flush-template">
                  <NavLink to={`/institution/${oid}/templates/all`} className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/templates.png')} className='' /></div> <span>Templates</span>

                  </NavLink>
                </div>
              </h2>
              <div id="flush-template" className="accordion-collapse collapse show" aria-labelledby="flush-template" data-mdb-parent="#accordionFlushExampleX">

              </div>
            </div> : ''}

            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-settings">
                <button className="list-group-item list-group-item-action text-uppercase accordion-button" type="button" data-mdb-toggle="collapse"
                  data-mdb-target="#flush-set" aria-expanded="fasle" aria-controls="flush-set">
                  <div className='sidebaricons'>
                    <img
                      src={require('../../assets/images/icons/settings.png')}
                      className=''
                    />
                  </div>
                  <span>Settings</span>
                </button>
              </h2>
              <div id="flush-set" className="accordion-collapse collapse" aria-labelledby="flush-settings" data-mdb-parent="#accordionFlushExampleX">
                <div className="accordion-body p-0 ps-3">
                  {canAccessMenu(services, services.MEMBERSHIP) ? <NavLink onClick={toggleNav} to={`/institution/${oid}/members`} className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} >
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/users.png')} className='' /></div> <span>Members</span>
                  </NavLink> : ''}

                  {canAccessMenuWithRole(getSelectedUserRole()) ? <NavLink onClick={toggleNav} className={({ isActive }) => (isActive ? " list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} style={{ pointerEvents: "none" }}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/edit-3.png')} className='' /></div> <span>Signers</span>
                  </NavLink> : ''}

                 
                  {/* <NavLink onClick={toggleNav} to="/create-institution" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} >
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/college.png')} className='' /></div> <span>Institution</span>
                  </NavLink> */}

                  {canAccessMenuWithRole(getSelectedUserRole()) ? <NavLink onClick={toggleNav} className={({ isActive }) => (isActive ? " list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} style={{ pointerEvents: "none" }}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/verified.png')} className='' /></div> <span>Security</span>
                  </NavLink> : ''}
                </div>
              </div>

            </div>

           
            

            {canAccessMenuWithRole(getSelectedUserRole()) ? <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOneX">
                <button className="list-group-item list-group-item-action text-uppercase accordion-button" type="button" data-mdb-toggle="collapse"
                  data-mdb-target="#flush-collapseOneX" aria-expanded="fasle" aria-controls="flush-collapseOneX">
                  <div className='sidebaricons'>
                    <img
                      src={require('../../assets/images/icons/code.png')}
                      className=''
                    />
                  </div>
                  <span>Developer</span>
                </button>
              </h2>
              <div id="flush-collapseOneX" className="accordion-collapse collapse" aria-labelledby="flush-headingOneX" data-mdb-parent="#accordionFlushExampleX">
                <div className="accordion-body p-0 ps-3">
                  <NavLink onClick={toggleNav} className={({ isActive }) => (isActive ? "list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} style={{ pointerEvents: "none" }}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/web.png')} className='' /></div> <span>Domains</span>
                  </NavLink>
                  
                  {/* <NavLink onClick={toggleNav} to={`${configPaths.apiPath}api-docs/`} target="_blank" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/document.png')} className='' /></div><span>API DOCS</span>
                  </NavLink> */}
                  <NavLink onClick={toggleNav} to={`https://flow-view-source.com/testnet/account/${process.env.REACT_APP_CONTRACT_ADDRESS}/contract/Certifily`} target="_blank" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/smart-contracts.png')} className='' /></div> <span>Smart Contract</span>
                  </NavLink>

                </div>
              </div>

            </div> : ''}


          </div>


          {/* <NavLink to="/students-import" className={({isActive}) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
            <img
              src={require('../../assets/images/icons/users-import.png')}
              className='sidebaricons me-3'
            />
            <span>Students Import</span>
          </NavLink> */}
          {/* <NavLink to="/select-template" className={({isActive}) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
            <img
              src={require('../../assets/images/icons/file-text.png')}
              className='sidebaricons me-3'
            />
            <span>Select Template</span>
          </NavLink>
          <NavLink to="/customize-template" className={({isActive}) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
            <img
              src={require('../../assets/images/icons/file-edit.png')}
              className='sidebaricons me-3'
            />
            <span>Customize Template</span>
          </NavLink> */}

          {/* <NavLink to="/signer" className={({isActive}) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
            <img
              src={require('../../assets/images/icons/edit-3.png')}
              className='sidebaricons me-3'
            />
            <span>Signers</span>
          </NavLink> */}


          <button type="button" onClick={logout} className={('list-group-item list-group-item-action px-3 py-2 text-uppercase mobvis')}>

            <div className='sidebaricons'>
              <img
                src={require('../../assets/images/icons/log-out-outline.png')}
                className=''
              />
            </div>
            <span>Logout</span>
          </button>


        </div>
      </div>
      <div className="sidebarbottom light-blur text-center">

        <p className='mt-0 mb-1'>
          <img
            src={require('../../assets/images/flow.png')}
            alt="flow"
            loading="lazy"
          />
        </p>
        <select className="form-control mb-1" value={selectType} onChange={onChangeValue}>
          <option value="1">Testnet</option>
          <option value="2">Mainnet</option>
        </select>

        <p className=' mb-0 text-uppercase fw-bolder networkstatus text-primary'>Online</p>
      </div>
    </nav>
  );
}

export default Sidemenu;
