import { getAuth, signOut } from 'firebase/auth';
import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getOrgName, getSelectedUserOid, getSelectedUserRole, getUserRole, getUsername, logoutUser } from '../../helpers/authData';
import { resetWalletAddressSuccess } from 'actions/exampleAction';
import { canAccessMenu, canAccessMenuWithRole, getuserName } from 'utils/utils';

const SuperadminSidemenu = (props) => {
  let userDetail = {}
  let services = []
  let oid = getSelectedUserOid()
  const userProfileWithWallet = useSelector(state => state.demoReducer.userProfile);

  if (userProfileWithWallet && userProfileWithWallet.data && userProfileWithWallet.data.name) {
    userDetail = userProfileWithWallet.data
    if (userDetail && userDetail.services) {
      services = userDetail.services

    }
  }
 
  const [selectType, setSelectType] = "1"
  const [address, setAddress] = useState('')
  const navigate = useNavigate();
  const [isActive5, setActive5] = useState(false);

  const onChangeValue = () => { }
  const walletaddress = useSelector(state => state.demoReducer.walletAddress);

  const dispatch = useDispatch()
  useEffect(() => {
    if (walletaddress && walletaddress !== null && walletaddress.length > 0) {
      setAddress(walletaddress)
      dispatch(resetWalletAddressSuccess())
    }

  }, [walletaddress])


  function logout() {
    signOut(getAuth()).then(() => {
      logoutUser()
      navigate("/");
    }).catch((error) => {
      logoutUser()
      navigate("/");
    });
  }

  const toggleNav = () => { props.toggleNav() };
  // console.log(isActive5)
  const handleToSwitch = (items) => {
    if(items && items.oid){
      sessionStorage.setItem("selectedRole",JSON.stringify(items))
      navigate(`/institution/${items.oid}/dashboard`)
    }
  }
  const backToUser = () => {
    sessionStorage.removeItem('selectedRole')
    navigate('/dashboard')
  }
  return (
    <nav id="sidebarMenu" className={(props.isopen) ? 'collapse sidebar pt-0 show' : 'collapse sidebar pt-0'}  >
      <div className="position-sticky">
        <div className="list-group list-group-flush text-start sidemenupanel">
          <NavLink className="navbar-brand" to="/">
            <img
              src={require('../../assets/images/logo.png')}
              alt="certifily Logo"
              loading="lazy"
              className='sidebarlogo mb-3'
            />
          </NavLink>


          <div className='mobileprofile'>
            <div className='profile-area'>
              <div className='profile-icon'>
                <img className='headerprofilepic' src={require('../../assets/images/photo10.png')} loading="lazy" />
              </div>
              <p className='mb-1 profilename'>Welcome <span className='username' data-tooltip={getuserName(userDetail)}>{getuserName(userDetail)}</span></p>
              <p className='mb-0 profilename'>{address}</p>
              {/* {isUser() ? '' : <div className='userOrg'>
                <img src={require('../../assets/images/icons/college.png')} loading="lazy" />
                <h5 className='fw-bolder text-uppercase'>{userOrg}</h5>
              </div>} */}

            </div>
          </div>






          <div className="accordion accordion-borderless" id="accordionFlushExampleX">
            {/* /*dashboard */}

            <div className="accordion-item" onClick={toggleNav}>
              <h2 className="accordion-header" id="flush-headingOneX1">
                <div data-mdb-toggle="collapse" data-mdb-target="#flush-collapseOneX1" aria-expanded="true" aria-controls="flush-collapseOneX1">
                  <NavLink to="/admin/dashboard" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'>
                      <img
                        src={require('../../assets/images/icons/airplay.png')}
                        className=''
                      />
                    </div>
                    <span>Dashboard</span>
                  </NavLink>
                </div>
              </h2>
              <div id="flush-collapseOneX1" className="accordion-collapse collapse show" aria-labelledby="flush-collapseOneX1" data-mdb-parent="#accordionFlushExampleX"></div>
            </div>
            <div className="accordion-item" onClick={toggleNav}>
              <h2 className="accordion-header" id="flush-headingOneX2">
                <div data-mdb-toggle="collapse" data-mdb-target="#flush-collapseOneX2" aria-expanded="true" aria-controls="flush-collapseOneX2">
                  <NavLink to="/admin/organisations" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'>
                      <img
                        src={require('../../assets/images/icons/users.png')}
                        className=''
                      />
                    </div>
                    <span>Organization</span>
                  </NavLink>
                </div>
              </h2>
              </div>
            <div className="accordion-item" onClick={toggleNav}>
              <h2 className="accordion-header" id="flush-headingOneX3">
                <div data-mdb-toggle="collapse" data-mdb-target="#flush-collapseOneX3" aria-expanded="true" aria-controls="flush-collapseOneX3">
                  <NavLink to="/admin/users" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'>
                      <img
                        src={require('../../assets/images/icons/award.png')}
                        className=''
                      />
                    </div>
                    <span>Users</span>
                  </NavLink>
                </div>
              </h2>
            </div>

            <div className="accordion-item" onClick={toggleNav}>
              <h2 className="accordion-header" id="flush-headingOneX3">
                <div data-mdb-toggle="collapse" data-mdb-target="#flush-collapseOneX4" aria-expanded="true" aria-controls="flush-collapseOneX4">
                  <NavLink to="/admin/mints" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'>
                      <img
                        src={require('../../assets/images/icons/award.png')}
                        className=''
                      />
                    </div>
                    <span>Mints</span>
                  </NavLink>
                </div>
              </h2>
            </div>


            {/* <div className="accordion-item">
              <h2 className="accordion-header" id="flush-settings">
                <button className="list-group-item list-group-item-action text-uppercase accordion-button" type="button" data-mdb-toggle="collapse"
                  data-mdb-target="#flush-set" aria-expanded="fasle" aria-controls="flush-set">
                  <div className='sidebaricons'>
                    <img
                      src={require('../../assets/images/icons/settings.png')}
                      className=''
                    />
                  </div>
                  <span>Settings</span>
                </button>
              </h2>
              <div id="flush-set" className="accordion-collapse collapse" aria-labelledby="flush-settings" data-mdb-parent="#accordionFlushExampleX">
                <div className="accordion-body p-0 ps-3">
                  {canAccessMenu(services, services.MEMBERSHIP) ? <NavLink onClick={toggleNav} to="/members" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} >
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/users.png')} className='' /></div> <span>Members</span>
                  </NavLink> : ''}

                  {canAccessMenuWithRole(getSelectedUserRole()) ? <NavLink onClick={toggleNav} className={({ isActive }) => (isActive ? " list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} style={{ pointerEvents: "none" }}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/edit-3.png')} className='' /></div> <span>Signers</span>
                  </NavLink> : ''}

                  <NavLink onClick={toggleNav} to="/profile" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} >
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/user.png')} className='' /></div> <span>Profile</span>
                  </NavLink>
                  <NavLink onClick={toggleNav} to="/create-institution" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} >
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/college.png')} className='' /></div> <span>Institution</span>
                  </NavLink>

                  {canAccessMenuWithRole(getSelectedUserRole()) ? <NavLink onClick={toggleNav} className={({ isActive }) => (isActive ? " list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} style={{ pointerEvents: "none" }}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/verified.png')} className='' /></div> <span>Security</span>
                  </NavLink> : ''}
                </div>
              </div>

            </div>

            {canAccessMenuWithRole(getSelectedUserRole()) ? <div className="accordion-item">
              <h2 className="accordion-header" id="flush-library">
                <button className="list-group-item list-group-item-action text-uppercase accordion-button" type="button" data-mdb-toggle="collapse"
                  data-mdb-target="#flush-lib" aria-expanded="fasle" aria-controls="flush-lib">
                  <div className='sidebaricons'>
                    <img
                      src={require('../../assets/images/icons/settings.png')}
                      className=''
                    />
                  </div>
                  <span>library</span>
                </button>
              </h2>
              <div id="flush-lib" className="accordion-collapse collapse" aria-labelledby="flush-library" data-mdb-parent="#accordionFlushExampleX">
                <div className="accordion-body p-0 ps-3">
                  <NavLink onClick={toggleNav} to={'/customize-template'} className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} >
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/users.png')} className='' /></div> <span>Customize Certificate</span>
                  </NavLink>
                </div>
              </div>
            </div> : ''} */}

            {/* {canAccessMenuWithRole(getSelectedUserRole()) ? <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOneX">
                <button className="list-group-item list-group-item-action text-uppercase accordion-button" type="button" data-mdb-toggle="collapse"
                  data-mdb-target="#flush-collapseOneX" aria-expanded="fasle" aria-controls="flush-collapseOneX">
                  <div className='sidebaricons'>
                    <img
                      src={require('../../assets/images/icons/code.png')}
                      className=''
                    />
                  </div>
                  <span>Developer</span>
                </button>
              </h2>
              <div id="flush-collapseOneX" className="accordion-collapse collapse" aria-labelledby="flush-headingOneX" data-mdb-parent="#accordionFlushExampleX">
                <div className="accordion-body p-0 ps-3">
                  <NavLink onClick={toggleNav} className={({ isActive }) => (isActive ? "list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')} style={{ pointerEvents: "none" }}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/web.png')} className='' /></div> <span>Domains</span>
                  </NavLink>
                  <NavLink onClick={toggleNav} to="/create-token" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/key.png')} className='' /></div> <span>API Keys</span>
                  </NavLink>
                  <NavLink onClick={toggleNav} to="https://betaapi.certifi.ly/api-docs/" target="_blank" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/document.png')} className='' /></div><span>API DOCS</span>
                  </NavLink>
                  <NavLink onClick={toggleNav} to={`https://flow-view-source.com/testnet/account/${process.env.REACT_APP_CONTRACT_ADDRESS}/contract/Certifily`} target="_blank" className={({ isActive }) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
                    <div className='sidebaricons'><img src={require('../../assets/images/icons/smart-contracts.png')} className='' /></div> <span>Smart Contract</span>
                  </NavLink>

                </div>
              </div>

            </div> : ''} */}


          </div>


          {/* <NavLink to="/students-import" className={({isActive}) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
            <img
              src={require('../../assets/images/icons/users-import.png')}
              className='sidebaricons me-3'
            />
            <span>Students Import</span>
          </NavLink> */}
          {/* <NavLink to="/select-template" className={({isActive}) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
            <img
              src={require('../../assets/images/icons/file-text.png')}
              className='sidebaricons me-3'
            />
            <span>Select Template</span>
          </NavLink>
          <NavLink to="/customize-template" className={({isActive}) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
            <img
              src={require('../../assets/images/icons/file-edit.png')}
              className='sidebaricons me-3'
            />
            <span>Customize Template</span>
          </NavLink> */}

          {/* <NavLink to="/signer" className={({isActive}) => (isActive ? "active list-group-item list-group-item-action px-3 py-2 text-uppercase" : 'list-group-item list-group-item-action px-3 py-2 text-uppercase')}>
            <img
              src={require('../../assets/images/icons/edit-3.png')}
              className='sidebaricons me-3'
            />
            <span>Signers</span>
          </NavLink> */}


          <button type="button" onClick={logout} className={('list-group-item list-group-item-action px-3 py-2 text-uppercase mobvis')}>

            <div className='sidebaricons'>
              <img
                src={require('../../assets/images/icons/log-out-outline.png')}
                className=''
              />
            </div>
            <span>Logout</span>
          </button>


        </div>
      </div>
      <div className="sidebarbottom light-blur text-center">

        <p className='mt-0 mb-1'>
          <img
            src={require('../../assets/images/flow.png')}
            alt="flow"
            loading="lazy"
          />
        </p>
        <select className="form-control mb-1" value={selectType} onChange={onChangeValue}>
          <option value="1">Testnet</option>
          <option value="2">Mainnet</option>
        </select>

        <p className=' mb-0 text-uppercase fw-bolder networkstatus text-primary'>Online</p>
      </div>
    </nav>
  );
}

export default SuperadminSidemenu;
