import React, { useState, useRef } from 'react';
import { createReactEditorJS } from 'react-editor-js'
const ReactEditorJS = createReactEditorJS()

function LetterPad() {
  const [content, setContent] = useState("");
  const instanceRef = useRef(null);


  async function handleSave() {
    const savedData = await instanceRef.current;

    console.log("savedData", savedData);
    // const edjsParser = EditorHtml();
    // const html = edjsParser.parse(savedData);
    // console.log("ini html", html);
  }


  return (
    <div className='formscroldiv1 createfile'>
      <h4>Create Letter</h4>
      <div className='container-fluid1'>
        <div className='tableblur fadein'>

          {/* <button onClick={handleSave}>Save!</button> */}

          <ReactEditorJS holder="custom">
            <div id="custom" ref={(instance) => (instanceRef.current = instance)} />
          </ReactEditorJS>
        </div>
      </div>
    </div>
  );
}

export default LetterPad;
