import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonError, getCertificateByCertificateNumber } from '../actions/exampleAction';
import { getCertificateFailed } from '../actions/exampleAction';
import { useParams, useNavigate } from 'react-router-dom';
import * as eva from 'eva-icons';
import { getUserRole, isUserLoggedIn } from '../helpers/authData';
import { mainRoles, userRoles } from './shared/Roles';

export default function CertificateVerification() {
    const [viewCertificate, setViewCertificate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [nftId, setNftId] = useState('');
    const [erroMessage, setErroMessage] = useState('');
    const [certificate, setCertifcate] = useState({});

    const { certificateNumber } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getFailed = useSelector(
        (state) => state.demoReducer.getCertificateFailed
    );
    const getSucceeded = useSelector(
        (state) => state.demoReducer.getCertificate
    );

    useEffect(() => {
        if (
            getFailed &&
            typeof getFailed === 'string' &&
            getFailed.length > 0
        ) {
            dispatch(getCertificateFailed());
            setIsLoading(false);
            setViewCertificate(false);
            dispatch(commonError(getFailed))
            // setErroMessage(getFailed);
        }
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFailed]);

    useEffect(() => {
        if (getSucceeded.statusCode === 200) {
            // console.log('getSucceeded', getSucceeded);
            setCertifcate({
                ...getSucceeded.data.certificate,
            });
            setIsLoading(false);
            setViewCertificate(true);
        }
    }, [getSucceeded]);

    useEffect(() => {
        if (certificateNumber && certificateNumber.length) {
            setNftId(certificateNumber);
            setErroMessage('');
            setIsLoading(true);
            dispatch(getCertificateByCertificateNumber(certificateNumber));
        }
    }, [certificateNumber, dispatch]);

    useEffect(() => {
        document.body.classList.add('home-layout')
        return () => {
          document.body.classList.remove('home-layout')
        }
      }, [])

    function handleSubmit(event) {
        event.preventDefault();
        if (!nftId) {
            dispatch(commonError('Please enter certificate number'))
            // setErroMessage();
            return;
        }
        navigate(`/verify/${nftId}`);
    }

    function handleInputChange(event) {
        const { value } = event.target;
        setNftId(value);
    }
    useEffect(() => {
        eva.replace();
    });
    const shareOnLinkedIn = (mycert) => {
        const message = "Certifily";

        const linkUrl = "https://dev.certifi.ly/verify";

        const summary = "Certifily certificate"

        // Replace this with your LinkedIn share URL
        const shareUrl = "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(mycert) + "&title=" + encodeURIComponent(message) + "&source=" + encodeURIComponent(linkUrl) + "&summary=" + encodeURIComponent(summary);

        window.open(shareUrl, "_blank");
    }
    const role = getUserRole()
    const gotoLogin = () => {
        
            navigate('/login')
        
    }
    const gotoSignup = () => {
        
            navigate('/signup')
        
        
    }
    const gotoHome = () => {
        navigate('/')
    }
  
    
    return (
        <div className='hl-inner'>
        <div className='hl-innerheader'>
          <div className='container'><h1 className='text-center'>Verify</h1></div>
        </div>
        <div className="container-fluid height100per verifycontent">
           
            <div className="row fadein align-items-center">
                <div div className="col-md-6 offset-md-3 text-start">
                    <div className="backgroundblur h-auto p-3 my-3">
                        <VerificationFrom
                            erroMessage={erroMessage}
                            handleInputChange={handleInputChange}
                            handleSubmit={handleSubmit}
                            isLoading={isLoading}
                            nftId={nftId}
                        />
                    </div>
                    {viewCertificate ? (
                        <div className="backgroundblur h-auto p-3 mb-3">
                            <div className="ctemp height100per mb-0">
                                <label htmlFor="cert-1" className="w-100">
                                    <div className="row align-items-center vfpdfcont">
                                        <div className="col-sm-4 text-start">
                                            <p className="mt-3 mb-2">
                                                Issued on{' '}
                                                <span className="fw-bold">
                                                    {new Date(
                                                        certificate.datetimeCreated
                                                    ).toLocaleDateString(
                                                        'en-US',
                                                        {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                        }
                                                    )}
                                                </span>
                                            </p>
                                            <p className="">
                                                by{' '}
                                                <span className="fw-bold text-primary">
                                                    {certificate.org['name']}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col-sm-4">
                                            <p className="text-center w-100 mb-3">
                                                <b>{certificateNumber}</b>{' '}
                                            </p>
                                        </div>
                                        <div className="col-sm-4 text-end">
                                            {' '}
                                            <p className="text-end w-100 mb-1">
                                                <img src={require('../assets/images/icons/Certifily-icon.png')} loading="lazy" width={16} /> <a href={"https://testnet.flowview.app/account/"+certificate.user.flowAddress+"/collection/CertifilyNFTCollection/"+certificate.nftId} target="_blank">NFT #{certificate.nftId}</a>{' '}
                                                <span className="badge badge-primary ms-2 text-uppercase">
                                                    Verified
                                                </span>
                                            </p>
                                            <span className='vtxtlinks'>
                                                
                                                    <a className="d-inline-flex align-items-center text-primary eva-hover me-2"
                                                        href={
                                                            certificate.certificateHash
                                                        }
                                                    >
                                                        <i data-eva="external-link-outline" data-eva-animation="flip"></i> 
                                                        <span className='ms-1'>View on IPFS</span>
                                                    </a>{' '}
                                                
                                                <span className="eva-hover d-inline-flex align-items-center text-primary cursor-pointer" onClick={() => shareOnLinkedIn(certificate.thumbnailHash)} >
                                                    <i
                                                        className="me-2"
                                                        data-eva="share-outline"
                                                        data-eva-animation="flip"
                                                    ></i>{' '}
                                                    <span className='ms-1'>Share</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <embed
                                        className="iframe verifyiframe"
                                        src={certificate.certificateHash}
                                    />
                                </label>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
        </div>
    );
}

export function VerificationFrom({
    erroMessage,
    handleInputChange,
    handleSubmit,
    isLoading,
    nftId,
}) {
    return (
        
        <div className="searchform border-none d-block p-0">
            <form onSubmit={handleSubmit}>
                <div className="fom-group">
                    <label className="mb-2 fw-bold w-100 text-uppercase text-center">Certificate number</label>
                    <div className="input-group mb-3 inputbtngroup">
                        <input
                            name="nftId"
                            type={'text'}
                            value={nftId}
                            onChange={handleInputChange}
                            className="form-control hl-vip"
                            placeholder="Certificate number"
                        />
                        
                        {isLoading ? (
                             <button
                             type="button"
                             className="btn btn-light btn-icon btn-disabled bg-white filter-none"
                         >
                                <span className="loaderbtn input-group-text m-0">
                                    <img
                                        src={require('../assets/images/certifi-loader.gif')}
                                        loading="lazy"
                                        alt="Loading..." width={24}
                                    />
                                </span>
                                </button>
                            ) : (
                                <button
                            type="submit"
                            className="btn btn-primary btn-icon"
                        >
                            Verify
                            
                        </button>
                            )}
                    </div>
                </div>
                {erroMessage && (
                    <div
                        className="alert alert-danger text-center col-sm-6 mx-auto fadein"
                        role="alert"
                    >
                        {erroMessage}
                    </div>
                )}
            </form>
        </div>
       
    );
}
