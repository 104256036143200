import axios from 'axios';
import { configPaths } from '../config';
import { setup } from './setup';
import { ErrorResponseSetup } from './errorResponseSetup';

const apiWithToken = axios.create({
  baseURL: configPaths.apiPath,
  headers: {
    'Content-Type': `application/json`,
  },
});
export const apiWithMultipartFormData = axios.create({
  baseURL: configPaths.apiPath,
  headers: {
    'Content-Type': `multipart/form-data`,
  },
});
// Request interceptor for API calls
apiWithToken.interceptors.request.use((config) => setup(config,false),error => { Promise.reject(error)});

apiWithToken.interceptors.response.use(
  (res) => {
    return res;
  },ErrorResponseSetup
  
);
apiWithMultipartFormData.interceptors.request.use((config) => setup(config,false),error => { Promise.reject(error)});

apiWithMultipartFormData.interceptors.response.use(
  (res) => {
    return res;
  },ErrorResponseSetup
  
);

export default apiWithToken;