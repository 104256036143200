import { Fragment, React, useEffect, useState, useRef } from 'react';
import * as eva from 'eva-icons';
import AOS from 'aos';
import "aos/dist/aos.css";
import { useNavigate } from 'react-router-dom';
import { getUserRole, isUserLoggedIn } from '../helpers/authData';
import { mainRoles, userRoles } from './shared/Roles';
import { NavLink } from 'react-router-dom';


const DocumentsWeb3DigitalSigningOnBlockchain = () => {
  useEffect(() => { eva.replace() });

  const [scroll, setScroll] = useState(false);


  useEffect(() => {
    document.body.classList.add('home-layout')
    return () => {
      document.body.classList.remove('home-layout')
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
    AOS.init({
      duration: 2000
    });
  }, []);


  const [isActive, setActive] = useState(false);
  const [isActive1, setActive1] = useState(false);
  const [isActive2, setActive2] = useState(false);
  const [isActive3, setActive3] = useState(false);
  const [isActive4, setActive4] = useState(false);
  const [certNo, setCertNo] = useState('')
  const toggleClass = () => { setActive(!isActive); };
  const toggleClass1 = () => { setActive1(!isActive1); };
  const toggleClass2 = () => { setActive2(!isActive2); };
  const toggleClass3 = () => { setActive3(!isActive3); };
  const toggleClass4 = () => { setActive4(!isActive4); };

  const navigate = useNavigate()
  const gotoLogin = () => {
    navigate('/login')
  }
  const gotoVerify = () => {
    navigate('/verify')
  }
  const gotoVerifyWithNumber = () => {
    if (certNo) {
      navigate(`/verify/${certNo}`)
    } else {
      navigate('/verify')
    }
  }
  const gotoDashboard = () => {
    const role = getUserRole()
    if (mainRoles.includes(role)) {
      navigate('/dashboard')
    } else if (userRoles.includes(role)) {
      navigate('/user-dashboard')
    } else {
      navigate('/login')
    }
  }

  const getYear = () => {
    return new Date().getFullYear();
  }

  const topRef = useRef(null)
  useEffect(() => {
    if(topRef && topRef.current){
      topRef.current.scrollIntoView({ behavior: 'smooth'})
    }
  },[])

  return (
    <Fragment>
    

      <div className='hl-inner fadein' ref={topRef}>
      <div className='hl-innerheader'>
          <div className='container'> <h1>For Businesses</h1></div>
        </div>
        <div className='container'>
         
          <h3>Introducing Certifi</h3>
          <p className='mb-5'>Revolutionizing Document Signing and Storage with Flow Blockchain Welcome to Certifi, a cutting-edge blockchain platform designed to enable businesses and individuals to sign and store documents, agreements, and contracts using their Web3 credentials from Flow blockchain. Certifi offers a highly secure, efficient, and transparent solution to document signing and storage, harnessing the power of blockchain technology to streamline business processes.</p>

          <h3 className='mb-5'>Advantages of Web3 Signing on Flow Blockchain</h3>
          
          <ul className="list-unstyled list-numered row">
            <li className="col-md-4">
              <span className="numered">1</span>
              <span className="shadowtippy"></span>
              <h4>Enhanced Security</h4>
              <p>Web3 signing with Flow blockchain adds an extra layer of security to your documents. The decentralized nature of the blockchain ensures your data is stored securely and prevents unauthorized access or tampering. </p>
            </li>
            <li className="col-md-4">
              <span className="numered">2</span>
              <span className="shadowtippy"></span>
              <h4>Trust and Transparency</h4>
              <p>Blockchain technology guarantees the integrity of your documents, as each transaction is recorded on a public ledger. This creates trust between all parties involved in the signing process and ensures complete transparency. </p>
            </li>
            <li className="col-md-4">
              <span className="numered">3</span>
              <span className="shadowtippy"></span>
              <h4>Time and Cost Efficiency</h4>
              <p>Web3 signing on the Flow blockchain eliminates the need for traditional paper-based processes, reducing the time and costs associated with printing, shipping, and storage. </p>
            </li>
            <li className="col-md-4">
              <span className="numered">4</span>
              <span className="shadowtippy"></span>
              <h4>Immutability</h4>
              <p>Once a document is signed and stored on the blockchain, it cannot be altered or deleted, ensuring the authenticity and integrity of the signed document. </p>
            </li>
            <li className="col-md-4">
              <span className="numered">5</span>
              <span className="shadowtippy"></span>
              <h4>Global Accessibility</h4>
              <p>Documents signed and stored on the blockchain can be easily accessed and verified from anywhere in the world, promoting seamless communication and collaboration between parties. </p>
            </li>            
          </ul>

          <h3 className='mb-3'>Why Certifi's Approach is the Best Way</h3>
          <p>Certifi's unique approach to handling digital document signing offers unparalleled security and privacy. By storing only the encrypted file's hash on a public blockchain, Certifi ensures the integrity of the file while keeping the encrypted text private. This means that although anyone can verify the authenticity of the document, the encrypted content remains inaccessible on the public blockchain or IPFS. <br/><br/>
 

 Furthermore, Certifi's fully encrypted document storage ensures that sensitive information remains confidential, making it the ideal solution for professional and business documents. By combining the best of both worlds - the trust and transparency of blockchain technology with the security of encrypted storage - Certifi offers a groundbreaking solution for document signing and storage in the digital age. <br/><br/>
  
 
 Choose Certifi for a secure, efficient, and transparent document signing experience that you can trust. Embrace the future of document management with Certifi and the Flow blockchain. </p>
        </div>
      </div>





    </Fragment>


  );
}

export default DocumentsWeb3DigitalSigningOnBlockchain;