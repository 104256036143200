import { resetCommonError, resetCommonSuccess } from 'actions/exampleAction';
import { isEmailVerified, isUserLoggedIn } from 'helpers/authData';
import { useAuthStatus } from 'helpers/authHook';
import { updateFirebaseAuth } from 'helpers/updateFirebaseAuth';
import { Fragment, React, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FullLoader from './shared/FullLoader';

var timeout;
const Notification = (props) => {
  const dispatch = useDispatch()

  const errorMessageshown = useSelector(state => state.demoReducer.commonErrorMessage);
  const successMessageshown = useSelector(state => state.demoReducer.commonSuccessMessage);
  const emailVerifyStatus = useSelector(state => state.demoReducer.emailVerificationStatus);

  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showClass, setShowClass] = useState('')
  const [msgClass, setMsgClass] = useState('')
  const [timeoutIds, setTimeoutIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  var timeout = null;
  let clicked = false;
  let timeouts = [];
  let firstTimeout;
  let secondTimeout;
  let thirdTimeout;
  let fourthTiemout;
  useEffect(() => {
    if ((successMessageshown && successMessageshown !== '') || (errorMessageshown && errorMessageshown !== '')) {

      if (errorMessageshown !== '') {

        setErrorMessage(errorMessageshown)
        dispatch(resetCommonError())
        setSuccessMessage('')
      } else {
        setSuccessMessage(successMessageshown)
        dispatch(resetCommonSuccess())
        setErrorMessage('')
      }

      if (timeoutIds.length > 0) {
        timeoutIds.forEach(timeoutId => {
          clearTimeout(timeoutId);
        });
        setTimeoutIds([]);
      }
      firstTimeout = setTimeout(() => {
        setShowClass('show')
      }, 200)
      setTimeoutIds([firstTimeout]);
      secondTimeout = setTimeout(() => {
        setMsgClass('show')
      }, 750)

      setTimeoutIds(timeoutIds => [...timeoutIds, secondTimeout]);

      thirdTimeout = setTimeout(() => {
        setShowClass('hide')
        setMsgClass('hide')
      }, 4500)
      setTimeoutIds(timeoutIds => [...timeoutIds, thirdTimeout]);

      fourthTiemout = setTimeout(() => {
        console.log("Not cleared")
        if (successMessage !== '') {
          setSuccessMessage('')
          setShowClass('')
          setMsgClass('')
        } else if (errorMessage !== '') {
          setErrorMessage('')
          setShowClass('')
          setMsgClass('')
        }
        setTimeoutIds([]);

      }, 5000)
      setTimeoutIds(timeoutIds => [...timeoutIds, fourthTiemout]);
    }








  }, [successMessageshown, errorMessageshown])
  useEffect(() => {
    clearNestedTimeout()

  }, [successMessage, errorMessage])
  const clearNestedTimeout = () => {

    timeouts.forEach(timeout1 => {
      clearTimeout(timeout1)
    })
  }
  const [isemailVerified, setEmailVerified] = useState(null)
  useEffect(() => {

    if (emailVerifyStatus && emailVerifyStatus.status === 200) {
      if (timeoutIds.length > 0) {
        timeoutIds.forEach(timeoutId => {
          clearTimeout(timeoutId);
        });
        setTimeoutIds([]);
      }
      

      if (emailVerifyStatus && emailVerifyStatus.isEmailVerified) {
        setEmailVerified(emailVerifyStatus.isEmailVerified)
      } else {
        setEmailVerified(false)
      }
      firstTimeout = setTimeout(() => {
        setShowClass('show')
      }, 200)
      setTimeoutIds([firstTimeout]);
      secondTimeout = setTimeout(() => {
        setMsgClass('show')
      }, 750)

      setTimeoutIds(timeoutIds => [...timeoutIds, secondTimeout]);

      thirdTimeout = setTimeout(() => {
        setShowClass('hide')
        setMsgClass('hide')
      }, 4900)
      setTimeoutIds(timeoutIds => [...timeoutIds, thirdTimeout]);

      fourthTiemout = setTimeout(() => {
          setEmailVerified(null)
          setShowClass('')
          setMsgClass('')
        
        setTimeoutIds([]);

      }, 5300)
      setTimeoutIds(timeoutIds => [...timeoutIds, fourthTiemout]);
    } else if (emailVerifyStatus && emailVerifyStatus.status === 400) {
      setEmailVerified(null)
    }
  }, [emailVerifyStatus])

  const updateFirebase = async () => {
    setEmailVerified(null);
    setShowClass('')
    setMsgClass('')
    if (timeoutIds.length > 0) {
      timeoutIds.forEach(timeoutId => {
        clearTimeout(timeoutId);
      });
      setTimeoutIds([]);
    }
    setIsLoading(true)
    await updateFirebaseAuth()
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }
  return (
    <div>

    {isLoading ? <FullLoader></FullLoader> : ''}
      {(successMessage !== '') ? <div className={`alert alert-primary cf-alert ${showClass} alert-top chcro`} role="alert">
        <div className='svgicon'>
          {(msgClass === 'show') ? <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle className="path circle" fill="none" stroke="#0062ff" strokeWidth="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
            <polyline className="path check" fill="none" stroke="#0062ff" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
          </svg> : ''}
        </div>
        <span className="alertmsg">{successMessage}</span>
      </div> : ''}

      {(errorMessage !== '') ? <div className={`alert alert-danger cf-alert ${showClass} alert-top chcro`} role="alert">
        <div className='svgicon'>
          {(msgClass === 'show') ? <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
            <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
            <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
          </svg> : ''}
        </div>
        <span className="alertmsg">{errorMessage}</span>
      </div> : ''}

      {(!isemailVerified && isemailVerified !== null) ? <div className={`alert alert-danger cf-alert ${showClass} alert-top chcro`} role="alert">
        <div className='svgicon'>
          {(msgClass === 'show') ? <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
            <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
            <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
          </svg> : ''}
        </div>
        <span className="alertmsg">Email verification pending.</span>
        {/* &nbsp;&nbsp;<button type="button" className="btn btn-link cursor-pointer" onClick={updateFirebase}>If already verified, please click this link and access</button> */}
      </div> : ''}
    </div>


  );
}

export default Notification;