import { React, useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router'
import * as eva from 'eva-icons';
import CountUp from 'react-countup';
import { useDispatch, useSelector } from 'react-redux';
import { commonError, getAllStats, getRecentCertificate, reseRecentCertificate, reseRecentCertificatefailed, resetGetAllStats, resetGetAllStatsFailed } from '../actions/exampleAction';
import TableLoader from './shared/TableLoader';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltipb from 'react-bootstrap/Tooltip';
import { NavLink, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { logoutUser } from 'helpers/authData';

const Dashboard = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch();
  const [recentCertData, setRecentCertData] = useState([]);
  const [fetched, setFetched] = useState(false)
  const [statsDetail, setStatsDetail] = useState({})
  const [isStatsFetched,setIsStatsFetched] = useState(false)
  const recentcertificate = useSelector(state => state.demoReducer.recentcertificate);
  const failedrecentcertificate = useSelector(state => state.demoReducer.getRecentCertificateFailed);

  const stats = useSelector(state => state.demoReducer.allstats);
  const statsFailed = useSelector(state => state.demoReducer.statsFailed);

  const params = useParams()
  const [oid,setOid] = useState(params.oid)
  useEffect(() => {
    const orgID = params.oid
    dispatch(getRecentCertificate(orgID));
    dispatch(getAllStats(orgID))
  }, []);
  useEffect(() => {
    if(params.oid !== oid){
      setOid(params.oid)
      const orgID = params.oid

      setRecentCertData([])
      setFetched(false)
      setStatsDetail({})
      setIsStatsFetched(false)
      dispatch(getRecentCertificate(orgID));
      dispatch(getAllStats(orgID))
    }
    
  },[params])
  useEffect(() => {
    if (recentcertificate.statusCode == 200) {
      let recentCert = recentcertificate.data.certificates;
      setTimeout(() => {
        setRecentCertData(recentCert);
        dispatch(reseRecentCertificate());
        setFetched(true);
      }, 1500);
      
    }
  }, [recentcertificate]);

  const gotoIssueCert = () => {
    navigate(`/institution/${oid}/create/all`)
  }
  useEffect(() => { eva.replace() });

  function formatDate(date){
    const formatter = Intl.DateTimeFormat('en-IN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
  });
  const issDate = formatter.format(date).split(' ');
  return `${issDate[1]} ${issDate[0]}, ${issDate[2]}`;
  }
  useEffect(() => {
    if (failedrecentcertificate && failedrecentcertificate.statusCode) {
        dispatch(reseRecentCertificatefailed());
        setFetched(true);
        if(failedrecentcertificate.statusCode === 401){
          navigate('/login')
        }
        else if(failedrecentcertificate.statusCode === 403){
          dispatch(commonError('Access denied'))
        }
    }
  }, [failedrecentcertificate]);
  useEffect(() => {
    if (stats && stats.statusCode == 200) {
      let recentStats = stats.data;
      setIsStatsFetched(true)
      dispatch(resetGetAllStats())
      setStatsDetail(recentStats)
      
      
    }
  }, [stats]);
  useEffect(() => {
    if (statsFailed && statsFailed.statusCode) {
        dispatch(resetGetAllStatsFailed());
        setIsStatsFetched(true);
        setStatsDetail({totalCertificates : 0,totalUsers:0,totalSigners : 0})
        if(statsFailed.statusCode === 401){
          navigate('/login')
        }if(statsFailed.statusCode === 403){
          dispatch(commonError('Access denied'))
        }
    }
  }, [statsFailed]);
  const gotoUsers = () => {
    navigate(`/institution/${oid}/users`)
  }
  const gotoCertificates = () => {
    navigate(`/institution/${oid}/certificates/all`)
  }
  return (
   
        <div className='col-md-12 text-start'>
          <div className='foldersview'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='createcetr'>
                  <h5>Create a</h5>
                  <h4 className='fw-bolder text-black'>DOCUMENT NFT</h4>
                  <p className='pe-md-5'>Create documents as NFTs on flow blockchain & share with people</p>
                  <span className='icontext eva-hover' onClick={gotoIssueCert}>
                    <span className='icon'><i data-eva="plus-outline" data-eva-animation="flip"></i></span>
                    <span className='text'>Create</span>
                  </span>
                </div>
              </div>
              <div className='col-md-9'>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='folder'>
                      <div className='foldercut'>
                        <div className='cltitle'>
                          <div className='climgcont'>
                            <img src={require('../assets/images/icons/Certifily-icon.png')} loading="lazy" />  Certificates
                          </div>
                        </div>
                      </div>
                      <div className="card dashboardboxContainer light-blur">
                        <div className='foldcont'>
                          {/* <p className="card-text mb-1 ccondi">Good</p>
                    <p className=" cpartitle">CLINICAL PRACTICES</p> */}
                          <h2 className='fw-medium text-center'>
                            {(isStatsFetched) ? <CountUp
                              start={0}
                              end={statsDetail.totalCertificates}
                              duration={4}
                            /> : '-'}
                          </h2>
                        </div>
                        <div className='foldpicshare'>
                          <div className='foldpics'>
                            {/* <ul className='list-unstyled'>
                        <li><span className='flpic'><img src={require('../assets/images/photo1.png')} loading="lazy" /></span></li>
                        <li><span className='flpic'><img src={require('../assets/images/photo2.png')} loading="lazy" /></span></li>
                        <li><span className='flpic'><img src={require('../assets/images/photo3.png')} loading="lazy" /></span></li>
                        <li><span className='flpic'><img src={require('../assets/images/photo4.png')} loading="lazy" /></span></li>
                        <li><span className='flpic'><img src={require('../assets/images/photo5.png')} loading="lazy" /></span></li>
                        <li><span className='flpic last-count'>+25</span></li>
                      </ul> */}
                          </div>
                          {statsDetail.totalCertificates ? <span className='icontext viewall eva-hover' onClick={gotoCertificates}>
                            <span className='icon'><i data-eva="arrow-ios-forward-outline"></i></span>
                            <span className='text'>View all</span>
                          </span> : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='folder'>
                      <div className='foldercut'>
                        <div className='cltitle'>
                          <div className='climgcont'>
                            <img src={require('../assets/images/icons/students.png')} loading="lazy" />  Users
                          </div>
                        </div>
                      </div>
                      <div className="card dashboardboxContainer light-blur">
                        <div className='foldcont'>
                          {/* <p className="card-text mb-1 ccondi">Best</p>
                    <p className=" cpartitle">Distribution Practices</p> */}
                          <h2 className='fw-medium text-center'>
                          {(isStatsFetched) ? <CountUp
                              start={0}
                              end={statsDetail.totalUsers}
                              duration={4}
                            /> : '-'}
                          </h2>
                        </div>
                        {(statsDetail.totalUsers) ? <div className='foldpicshare'>

                          <div className='foldpics'>
                            <ul className='list-unstyled'>
                              {/* <li><span className='flpic'><img src={require('../assets/images/photo5.png')} loading="lazy" /></span></li> */}
                              {(statsDetail.totalUsers >= 1) ? <li><span className='flpic'><img src={require('../assets/images/photo4.png')} loading="lazy" /></span></li> : ''}
                              {(statsDetail.totalUsers >= 2) ? <li><span className='flpic'><img src={require('../assets/images/photo3.png')} loading="lazy" /></span></li> : ''}
                              {(statsDetail.totalUsers >= 3) ? <li><span className='flpic'><img src={require('../assets/images/photo2.png')} loading="lazy" /></span></li> : ''}
                              {(statsDetail.totalUsers >= 4) ? <li><span className='flpic'><img src={require('../assets/images/photo1.png')} loading="lazy" /></span></li> : ''}
                              {(statsDetail.totalUsers >= 5) ? <li><span className='flpic last-count'>{Number(statsDetail.totalUsers)}+</span></li> : ''}
                            </ul>
                          </div>

                          <span className='icontext viewall eva-hover' onClick={gotoUsers}>
                            <span className='icon'><i data-eva="arrow-ios-forward-outline"></i></span>
                            <span className='text'>Manage</span>
                          </span>

                        </div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='folder'>
                      <div className='foldercut'>
                        <div className='cltitle'>
                          <div className='climgcont'>
                            <img src={require('../assets/images/icons/signers.png')} loading="lazy" />  Signers
                          </div>
                        </div>
                      </div>
                      <div className="card dashboardboxContainer light-blur">
                        <div className='foldcont'>
                          {/* <p className="card-text mb-1 ccondi">Awesome</p>
                    <p className=" cpartitle">Interior Design</p> */}
                          <h2 className='fw-medium text-center'>
                          {(isStatsFetched) ? <CountUp
                              start={0}
                              end={statsDetail.totalSigners}
                              duration={3}
                            /> : '-'}
                          </h2>
                        </div>
                        {statsDetail.totalSigners ? <div className='foldpicshare'>

                          {/* <div className='foldshare eva-hover icon-rht' >View all <i data-eva="arrow-ios-forward-outline" data-eva-animation="flip"></i></div> */}

                          <div className='foldpics'>
                            <ul className='list-unstyled'>
                              {/* <li><span className='flpic'><img src={require('../assets/images/photo2.png')} loading="lazy" /></span></li>
                              <li><span className='flpic'><img src={require('../assets/images/photo4.png')} loading="lazy" /></span></li> */}
                              <li><span className='flpic'><img src={require('../assets/images/photo1.png')} loading="lazy" /></span></li>
                              <li><span className='flpic'><img src={require('../assets/images/photo2.png')} loading="lazy" /></span></li>
                              <li><span className='flpic'><img src={require('../assets/images/photo3.png')} loading="lazy" /></span></li>
                              <li><span className='flpic'><img src={require('../assets/images/photo5.png')} loading="lazy" /></span></li>
                              {/* <li><span className='flpic last-count'>+25</span></li> */}
                            </ul>
                          </div>

                          <span className='icontext viewall eva-hover'>
                            <span className='icon'><i data-eva="arrow-ios-forward-outline"></i></span>
                            <span className='text'>View all</span>
                          </span>
                        </div> : <div className='foldpicshare'></div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=''>
            {(recentCertData.length == 0 && !fetched) ? (
              <div className="mt-4">
                <TableLoader />
              </div>
            ) : (recentCertData.length == 0 && fetched) ?
              <div className='tableblur mt-4'>
                <div className='row align-items-center mb-3'>
                  <div className='col-12'><span className='sitetextblue bluetxttitle'>RECENT CERTIFICATES</span></div>
                  <div className='col-12 text-center'>
                    <div className='nocertimg'>
                      <img src={require('../assets/images/no-cert.png')} loading="lazy" />
                      <span className='text'>No certificates found</span>                      
                    </div>
                  </div>
                </div>
              </div> : (
                <div className='tableblur mt-4'>
                  <div className='row align-items-center mb-3'>
                    <div className='col-12'><span className='sitetextblue bluetxttitle'>RECENT CERTIFICATES</span></div>
                    {/* <div className='col-5 text-right'>
                  <span className='icontext pull-right viewall'>
                    <span className='icon'><i data-eva="arrow-ios-forward-outline"></i></span>
                    <span className='text'>View all</span>
                  </span>
                </div> */}
                  </div>

                  <div className='table-responsive'>
                    <table className="table align-middle mb-0 custable table-hover">
                      <thead className="">
                        <tr>
                          <th>Name</th>
                          <th>Course</th>
                          <th>Batch</th>
                          <th>Status</th>
                          <th>Issued on</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {recentCertData.map((user, index) => (
                          <tr key={index}>
                            <td>
                              <span className="text-dark">{user.user.name}</span>
                            </td>
                            <td>
                              <p className="fw-normal mb-1">{user.course.name}</p>
                            </td>
                            <td> {user.certificateBatch.name} </td>
                            <td>
                              <span className="text-primary text-uppercase badge badge-primary fw-normal">Verified</span>
                            </td>
                            <td>
                              {formatDate(new Date(user.datetimeCreated))}
                            </td>
                            <td className='text-center' >                              
                                <a href={user.certificateHash} target="_blank" className='text-primary' data-tooltip="IPFS Link" ><i data-eva="link-outline"></i></a>
                            </td>
                            <td className='text-center'>
                              {/* <span className="dropdown">
                                <a href="#" className='text-secondary dropdown-toggle' type="button"
                                  id="dropdownMenuButton"
                                  data-mdb-toggle="dropdown"
                                  aria-expanded="false"><i data-eva="more-vertical-outline"></i></a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                  <li><a className="dropdown-item" href="#">Edit</a></li>
                                  <li><a className="dropdown-item" href="#">View</a></li>
                                </ul>
                              </span> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
          </div>
        </div> 
  );
}

export default Dashboard;