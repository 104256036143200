import { React, useEffect, useState } from 'react';
import * as eva from 'eva-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getSuperAdminAllUsers, resetSuperAdminAllUsers } from '../../actions/superAction';
import TableLoader from '.././shared/TableLoader';


const SuperadminUser = () => {
  const dispatch = useDispatch();
 
  const superadminUserList = useSelector(state => state.superadminReducer.superadminUserList);

  const [userList, setuserList] = useState([]);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    dispatch(getSuperAdminAllUsers());
  }, []);

  useEffect(() => {
    if (superadminUserList.statusCode == 200) {
      console.log(superadminUserList.data.users);
      let UserData = superadminUserList.data.users;
      setTimeout(() => {
        setuserList(UserData);
        dispatch(resetSuperAdminAllUsers());
        setFetched(true);
      }, 2000);
    }
  }, [superadminUserList]);

  useEffect(() => { eva.replace() });

  function formatDate(date){
    const formatter = Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });
    const issDate = formatter.format(date).split(' ');
    return `${issDate[0]} ${issDate[1]} ${issDate[2]} ${issDate[3]} ${issDate[4]} ${issDate[5]}`;
  }

  return (
    <div className='row fadein'>
      <div className='col-md-12 text-start'>
        {(userList.length == 0 && !fetched) ? (
            <div className="mt-4">
            <TableLoader />
          </div>
        ) : (
          <div className='tableblur mt-3'>                    
            <div className='searchform pt-0'>
              <div className='fields'>Search & Filters</div>
              <div className='fields'><input type={'text'} className="form-control" placeholder='Name' readOnly disabled/></div>
              <div className='fields'><input type={'text'} className="form-control" placeholder='Email' readOnly disabled/></div>
              <div className='fields'><input type={'text'} className="form-control" placeholder='Organization' readOnly disabled/></div>
              <div className='fields'><input type={'text'} className="form-control" placeholder='Status' readOnly disabled/></div>
            </div>
            <div className='table-responsive'>
            <table className="table align-middle mb-0 custable table-hover">
              <thead className="">
                <tr>
                  <th>ID</th>
                  <th>UID</th>
                  {/* <th>Profile</th> */}
                  <th>Name</th>
                  <th>Email</th> 
                  {/* <th>Organization</th> */}
                  <th>Flow Address</th>
                  {/* <th>Status</th> */}
                  <th>Date created</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                  {userList.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">{index+1}</div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">{user.uid}</div>
                      </td>
                      {/* <td>
                        <img className='superadminuserprofile' src={user.photoUrl} alt="" />
                      </td> */}
                      <td>
                        <p className="mb-1">{user.name}</p>
                      </td>
                      <td>
                        <p className="mb-1">{user.email}</p>
                      </td>
                      {/* <td>
                        <p className="mb-1">{user.org}</p>
                      </td> */}
                      <td>
                        <p className="mb-1">{user.flowaddress}</p>
                      </td>
                      {/* <td>
                        <p className="mb-1">{user.status}</p>
                      </td> */}
                      <td>
                        <p className="mb-1">{formatDate(new Date(user.datecreated))}</p>
                      </td>
                      <td className='text-center'>
                        <div className='btngrouprht'>
                          <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="View" >< i data-eva-animation="flip" data-eva="eye-outline"></i></a>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SuperadminUser;