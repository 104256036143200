import { Fragment, React, useEffect, useState, useRef } from 'react';
import * as eva from 'eva-icons';
import AOS from 'aos';
import "aos/dist/aos.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { getSelectedUserOid, getSelectedUserRole, getUserRole, isUserLoggedIn } from '../helpers/authData';
import PhoneInput from 'react-phone-input-2'
import { resetScheduleDemo, resetScheduleDemoFailed, scheduleDemoCall } from '../actions/authuserAction';
import { useDispatch, useSelector } from 'react-redux';
import FullLoader from './shared/FullLoader';
import { checkemail, isOrgRoleEnabled } from '../utils/utils';
import { commonError, commonSuccess } from 'actions/exampleAction';

var timeout;
const Home = () => {
  useEffect(() => { eva.replace() });

  const [scroll, setScroll] = useState(false);


  useEffect(() => {
    document.body.classList.add('home-layout')
    return () => {
      document.body.classList.remove('home-layout')
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
    AOS.init({
      duration: 2000
    });
  }, []);

  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [number, setNumber] = useState('')
  const [website, setWebsite] = useState('')
  const [isValidPhnNo, setIsValidPhnNo] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [addClass, setAddClass] = useState('')
  const dispatch = useDispatch()
  const scheduleDemo = useSelector(state => state.authUserReducer.scheduleSuccess);
  const scheduleDemoFailed = useSelector(state => state.authUserReducer.scheduleFailed);
  const navigate = useNavigate()
  const [showClass, setShowClass] = useState('')

  const oid = getSelectedUserOid()

  const gotoCreateDocument = () => {
    if (isUserLoggedIn()) {
      if (isOrgRoleEnabled(getSelectedUserRole())) {
        navigate(`institution/${oid}/document/create`)
      } else {
        navigate('/document/create')
      }
    } else {
      navigate('/login')
    }
  }
  const gotoCertificate = () => {
    if (isUserLoggedIn()) {
      if (isOrgRoleEnabled(getSelectedUserRole())) {
        navigate(`/institution/${oid}/mint-certificate`)
      } else {
        navigate('/dashboard')
      }

    } else {
      navigate('/login')
    }
  }
  const priceRef = useRef(null)
  const sadRef = useRef(null)
  const location = useLocation()
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });

    if (location.pathname === '/' && location.state?.myProp) {
      const newState = { ...location.state };
      delete newState.myProp;
      navigate(location.pathname, { state: newState });
      if (priceRef.current) {
        priceRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }

  }, [location, priceRef]);
  const gotoSignup = () => {
    navigate('/signup')
  }
  const isNumbervalid = (value, country) => {
    if (value.match(/12345/)) {
      setIsValidPhnNo(false)
      return 'Invalid value: ' + value + ', ' + country.name;
    } else if (value.match(/1234/)) {
      setIsValidPhnNo(false)
      return false
    } else {
      setIsValidPhnNo(true)
      return true;
    }
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value.toLowerCase());
    } else if (name === 'firstname') {
      setFirstname(value);
    } else if (name === 'website') {
      setWebsite(value)
    }
  };
  useEffect(() => {
    if (scheduleDemo && scheduleDemo.statusCode == 202) {
      setIsLoading(false)
      setSubmitted(false)
      dispatch(resetScheduleDemo())
      dispatch(commonSuccess("Request sent"))
      setFirstname('')
      setWebsite('')
      setEmail('')
      setNumber('')
      setIsValidPhnNo(false)
      setTimeout(() => {
        setShowClass('show')
      }, 200)

      setTimeout(() => {
        setShowClass('hide')
      }, 4000)

      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setSuccessMessage('')
        setShowClass('')
      }, 5000)
    }
  }, [scheduleDemo]);
  useEffect(() => {
    if (scheduleDemoFailed && scheduleDemoFailed.statusCode) {
      setIsLoading(false)
      setSubmitted(false)
        dispatch(resetScheduleDemoFailed());
        if(scheduleDemoFailed.statusCode === 401){
          navigate('/login')
        }else if(scheduleDemoFailed.statusCode === 403){
          dispatch(commonError('Access denied'))
        }else if(scheduleDemoFailed && 
          scheduleDemoFailed.data && scheduleDemoFailed.data.data && 
          scheduleDemoFailed.data.data[0] && 
          scheduleDemoFailed.data.data[0][0] &&
          typeof scheduleDemoFailed.data.data[0][0] === 'string'){
            dispatch(commonError(scheduleDemoFailed.data.data[0][0]))
        }else if(scheduleDemoFailed && scheduleDemoFailed.message){
          dispatch(commonError(scheduleDemoFailed.message))
        }
        setTimeout(() => {
          setShowClass('show')
        }, 200)
        clearTimeout(timeout)
      timeout = setTimeout(() => {
        setErrorMessage('')
        setShowClass('')
      }, 5000)
    }
  }, [scheduleDemoFailed]);
  const submitScheduleReq = (e) => {
    e.preventDefault()
    setSubmitted(true)
    const formData = { email, firstname, website, number };
    if (formData.email && formData.firstname && formData.website && formData.number) {
      let data = {
        "fullName": formData.firstname,
        "email": formData.email,
        "website": formData.website,
        "phone": `+${formData.number}`
      }
      if (isValidPhnNo) {
        if (checkemail(email)) {
          if (checkURL(website)) {
            setIsLoading(true)
            dispatch(scheduleDemoCall(JSON.stringify(data)))
          }
        }

      }
    }


  }

  const sadScroll = () => {
    sadRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    setTimeout(() => {
      setAddClass('hl-boxshadow')
    }, 1000)
    let settime;
    clearTimeout(settime)

    settime = setTimeout(() => {
      setAddClass('')
    }, 5000)
  }
  const checkURL = (value) => {
    const urlReg = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (urlReg.test(value)) {
      return true;
    }
    return false;
  }

  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
  };

  return (
    <Fragment>

      {/* {isLoading ? <FullLoader /> : ''} */}
      <div className={addClass + ' hl-aya'} >
        <div className='container'>
          <div className='hl-title'>
            {/* <button onClick={handleClick}>teste</button>        */}
            <h3 data-aos="fade-up" data-aos-delay="10">Are you an </h3>
            <h4 data-aos="fade-up" data-aos-delay="10">Educational Institution? Online Course Platform? EdTech Platform? LMS Vendor?</h4>
            <p data-aos="fade-up" data-aos-delay="15">Use Certifi to add technological value to your organization.</p>
          </div>
          <div className='hl-listform' >
            <div className='row'>
              <div className='col-md-6'>
                <ul className='list-unstyled hl-list'>
                  <li data-aos="fade-up" data-aos-delay="10"><span className='hllfimg'><img src={require('../assets/images/home/icons/certificate.png')} alt="Certifily" loading="lazy" /></span> Ready to issue digital certificates / badges on blockchain from day 1.
                  </li>
                  <li data-aos="fade-up" data-aos-delay="10"><span className='hllfimg'><img src={require('../assets/images/home/icons/payasyouuse.png')} alt="Certifily" loading="lazy" /></span> Ready to use blockchain “pay as you use” platform
                  </li>
                  <li data-aos="fade-up" data-aos-delay="15"><span className='hllfimg'><img src={require('../assets/images/home/icons/no-installation.png')} alt="Certifily" loading="lazy" /></span> No installation/setup cost
                  </li>
                  <li data-aos="fade-up" data-aos-delay="20"><span className='hllfimg'><img src={require('../assets/images/home/icons/maintance-free.png')} alt="Certifily" loading="lazy" /></span> No annual maintenance cost
                  </li>
                  <li data-aos="fade-up" data-aos-delay="25"><span className='hllfimg'><img src={require('../assets/images/home/icons/no-developer.png')} alt="Certifily" loading="lazy" /></span> No need to hire any blockchain developers for your IT team
                  </li>
                  <li data-aos="fade-up" data-aos-delay="30"><span className='hllfimg'><img src={require('../assets/images/home/icons/no-wallet.png')} alt="Certifily" loading="lazy" /></span> No need to separately install any wallet
                  </li>
                  <li data-aos="fade-up" data-aos-delay="30"><span className='hllfimg'><img src={require('../assets/images/home/icons/no-develop-time.png')} alt="Certifily" loading="lazy" /></span> No need to spend hours of IT development time
                  </li>
                  <li data-aos="fade-up" data-aos-delay="30"><span className='hllfimg'><img src={require('../assets/images/home/icons/no-private-key.png')} alt="Certifily" loading="lazy" /></span> No need to manage seed phrases or private keys
                  </li>
                  <li data-aos="fade-up" data-aos-delay="30"><span className='hllfimg'><img src={require('../assets/images/home/icons/auto-verify.png')} alt="Certifily" loading="lazy" /></span> Automate Certificate Verifications on Blockchain
                  </li>
                  <li data-aos="fade-up" data-aos-delay="30"><span className='hllfimg'><img src={require('../assets/images/home/icons/prevent-data-loss.png')} alt="Certifily" loading="lazy" /></span> Prevent Data Loss
                  </li>
                  <li data-aos="fade-up" data-aos-delay="30"><span className='hllfimg'><img src={require('../assets/images/home/icons/eco-friendly.png')} alt="Certifily" loading="lazy" /></span> Flow is an environment friendly Proof of Stake blockchain
                  </li>
                </ul>
              </div>
              <div className='col-md-6' ref={sadRef}>
                <div className='hl-lform' data-aos="fade-left" data-aos-delay="10" data-aos-offset="10" >
                  <div className='hlfrm'>
                    <h3>SCHEDULE A DEMO</h3>
                    <div className='form-group'>
                      <input
                        type='text'
                        placeholder='Name *'
                        name='firstname'
                        value={firstname}
                        className='form-control'
                        onChange={handleInputChange}
                        required />
                      {submitted && !firstname && <div className='mandatory'>Required</div>}
                    </div>

                    <div className='form-group'>
                      <input
                        type='text'
                        name="email"
                        value={email}
                        placeholder='Work Email Address *'
                        className='form-control'
                        onChange={handleInputChange}
                        maxLength="80"
                        minLength="6"
                        required />
                      {submitted && !email && <div className='mandatory'>Required</div>}
                      {submitted && email && !checkemail(email) && <div className='mandatory'>Invalid email</div>}
                    </div>

                    <div className='form-group'>
                      <input
                        type='text'
                        name="website"
                        value={website}
                        placeholder='Website *'
                        className='form-control'
                        onChange={handleInputChange}
                      />
                      {submitted && !website && <div className='mandatory'>Required</div>}
                      {submitted && website && !checkURL(website) && <div className='mandatory'>Invalid url</div>}

                    </div>

                    <div className='form-group hls-country'>
                      <PhoneInput
                        value={number}
                        onChange={phone => setNumber(phone)}
                        placeholder="Contact Number *"
                        inputClass="form-control"
                        country={'us'}
                        isValid={(value, country) => isNumbervalid(value, country)}
                      />
                      {submitted && !number && <div className='mandatory'>Required</div>}
                    </div>


                    <div className='form-group text-center'>

                      {isLoading ?
                        <button
                          type="button"
                          className="btn btn-light btn-icon btn-disabled bg-white filter-none"
                        >

                          <img
                            src={require('../assets/images/certifi-loader.gif')}
                            loading="lazy"
                            alt="Loading..." width={28}
                          />
                        </button>
                        :
                        <button type='submit' className='btn btn-primary' onClick={submitScheduleReq}>Schedule a demo</button>}

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='hl-cb'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <div className='hlc-img' data-aos="fade-right" data-aos-delay="10">
                <div className='hlc-imgcric'>
                  <img src={require('../assets/images/home/h-circle-opc.png')} alt="Certifily" loading="lazy" />
                </div>
                <img src={require('../assets/images/home/h-certificate-badges.png')} alt="Certifily" loading="lazy" />
              </div>
            </div>
            <div className='col-md-6'>
              <h3 data-aos="fade-down" data-aos-delay="10">Certificates & Badges</h3>
              <h4 data-aos="fade-down" data-aos-delay="10">Customize Certificate Templates & Mint</h4>
              <a onClick={gotoCertificate} className='btn btn-primary' data-aos="fade-down" data-aos-delay="30">Create a Certificate</a>

            </div>
          </div>
        </div>
      </div>



      <div className='hl-cb fullbbg'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <h3 data-aos="fade-down" data-aos-delay="10">Documents & Contracts</h3>
              <h4 data-aos="fade-down" data-aos-delay="10">Create really smart contract documents with public & protected information on blockchain</h4>
              <a onClick={gotoCreateDocument} className='btn btn-primary' data-aos="fade-down" data-aos-delay="30">Create a Document</a>

            </div>

            <div className='col-md-6'>
              <div className='hlc-img hlc-imgrht' data-aos="fade-left" data-aos-delay="10" data-aos-offset="10">
                <div className='hlc-imgcric'>
                  <img src={require('../assets/images/home/h-circle-opc.png')} alt="Certifily" loading="lazy" />
                </div>
                <img src={require('../assets/images/home/h-contract.png')} alt="Certifily" loading="lazy" />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className='hl-price' ref={priceRef} >
        <div className='container'>
          <h3 data-aos="fade-down" data-aos-delay="10" id="pricing">pricing</h3>
          <div className='hl-plisting'>
            <div className='hlp-lft' data-aos="fade-right" data-aos-delay="10">
              <p>For</p>
              <h4>Individuals </h4>
              <h5>Free</h5>
              <ul className='list-unstyled'>
                <li>FREE to receive certificates & badges</li>
                <li>FREE to receive Digital Document Files via IPFS</li>
                <li>Send 500MB of Digital Document Files via IPFS</li>
                <li>Create 100 Smart documents free</li>
              </ul>
              <a onClick={gotoSignup} className='btn btn-primary'>Signup</a>
            </div>

            <div className='hlp-rht' data-aos="fade-left" data-aos-delay="10">
              <p>For</p>
              <h4>Educational Organizations & Businesses  </h4>
              <div className='hlp-blue'>
                <img src={require('../assets/images/home/h-price-image.png')} alt="Certifily" loading="lazy" />
                <span className='btn btn-light' onClick={sadScroll}>Contact</span>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div className='hl-cb hl-tech'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <div className='hlc-img hlcimgcirc' data-aos="fade-right" data-aos-delay="10">
                <div className='imgcirc'>
                <img src={require('../assets/images/icons/Certifily-icon.png')} alt="Certifily" loading="lazy" className='hlccenterimg' />
                  <div className='hlc-imgcirc'>
                    <img src={require('../assets/images/home/h-technology.png')} alt="Certifily" loading="lazy" />
                    
                     
                      <div className='hlc-imgcirc-inner'>
                      <span className='hlclogocir'>
                        <img src={require('../assets/images/home/infura.png')} alt="Certifily" loading="lazy" />
                      </span>
                    </div>
                    <div className='hlc-imgcirc-inner'>
                      <span className='hlclogocir'>
                        <img src={require('../assets/images/home/blocto.png')} alt="Certifily" loading="lazy" />
                      </span>
                      </div>
                    <div className='hlc-imgcirc-inner'>
                        <span className='hlclogocir'>
                          <img src={require('../assets/images/home/flow.png')} alt="Certifily" loading="lazy" />
                        </span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 d-flex align-items-end flex-column'>
              <h4 data-aos="fade-up" data-aos-delay="10">Technology behind Certifi </h4>
              <p data-aos="fade-up" data-aos-delay="10">Flow blockchain with wallet-less onboarding feature allows students & professionals to receive and store their professional documents such as certificates, badges, files & documents on Flow blockchain without the need to manage a blockchain wallet account.</p>

            </div>
          </div>
        </div>
      </div>




    </Fragment>


  );
}

export default Home;