import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../firebase-config";

export function useAuthStatus() {
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    useEffect(() => {
      const unsub = getAuth().onAuthStateChanged(user => {
          if(user && user.uid){
            setIsLoggedIn(true)
          }else{
            setIsLoggedIn(false)
          }
      })
      return unsub();
    }, []);
    return isLoggedIn;
  }