export const mainRoles = ["preparer", "verifier", "issuer", "organisation adminstrator"]
export const userRoles = "user"
export const publicRole = 'public'

export const services =
{
    BADGE: 'badge',
    CARD: 'card',
    CERTIFICATE: 'certificate',
    DOCUMENT: 'document',
    FILE: 'file',
    MEMBERSHIP: 'membership',
    TICKET: 'ticket'
}

export const permissions = {
    READ:'canRead',
    WRITE:'canWrite'
}

export const orgRoles = {
    member : 'member',
    owner : 'owner',
    signer : 'signer',
}

export const certRoles = {
    org : 'org',
    user : 'user'
}