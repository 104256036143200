import { isEmailVerifiedCheck } from "actions/exampleAction"
import { isEmailVerified } from "helpers/authData"
import { getUserDetail, updateLocalStorageFirebaseUser } from "helpers/updateFirebaseAuth"
import { useDispatch } from "react-redux"

function CertifiBox(props){
    const dispatch = useDispatch()
    const onclickHandler = async (e) => {
        if(props.verificationNeed === false){
            props.onClick(e)
        }else{
            if(isEmailVerified()){
                if(props.onClick){
                    props.onClick(e)
                }
            }else{
                e.preventDefault()
                const user = await getUserDetail() 
                if(user && user.claims){
                if(user.claims.email_verified){
                    updateLocalStorageFirebaseUser(user)
                        if(props.onClick){
                            props.onClick(e)
                        }
                    
                }else{
                    updateLocalStorageFirebaseUser(user)
                        if(props.onClick){
                            dispatch(isEmailVerifiedCheck())
                        }
                    
                }
                }
               
            }
        }
        
    }
    return (
        <div className="col-sm-6 col-md-4 ">
        <div className='folder mb-3' onClick={onclickHandler}>
                <div className='foldercut'>
                    <div className='cltitle'>
                    </div>
                </div>
                <div className="card dashboardboxContainer light-blur">
                    <div className='foldcont text-center justify-content-center'>
                        <div className='img mb-3'>
                            <img src={require(`../../assets/images/icons/${props.image}`)} loading="lazy" />
                        </div>
                        <h4 className='text-primary text-uppercase fw-bold mb-3'>{props.heading}</h4>

                        <h2 className='fw-bold mb-0'>{props.count}</h2>
                    </div>
                    {/* <div className='foldpicshare justify-content-center'>
                        <div className='foldshare eva-hover icon-rht' onClick={viewCertificatepage}>View all <i data-eva="arrow-ios-forward-outline" data-eva-animation="flip"></i></div>
                    </div> */}
                </div>
            </div>
        </div> 

    )
}

export {CertifiBox}