import { Fragment, React, useEffect, useState, useRef } from 'react';
import * as eva from 'eva-icons';
import AOS from 'aos';
import "aos/dist/aos.css";
import { useNavigate } from 'react-router-dom';
import { getUserRole, isUserLoggedIn } from '../helpers/authData';
import { mainRoles, userRoles } from './shared/Roles';
import { NavLink } from 'react-router-dom';


const CreateCertificatesOnBlockchain = () => {
  useEffect(() => { eva.replace() });

  const [scroll, setScroll] = useState(false);


  useEffect(() => {
    document.body.classList.add('home-layout')
    return () => {
      document.body.classList.remove('home-layout')
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
    AOS.init({
      duration: 2000
    });
  }, []);


  const topRef = useRef(null)
  useEffect(() => {
    if(topRef && topRef.current){
      topRef.current.scrollIntoView({ behavior: 'smooth'})
    }
  },[])

  



  return (
    <Fragment>
      

      <div className='hl-inner fadein' ref={topRef}>
        <div className='hl-innerheader'>
          <div className='container'><h1>Educational Institutions</h1></div>
        </div>
        <div className='container'>
          
          <p className='mb-5'>Storing educational certificates and badges on the blockchain offers numerous advantages over traditional print or basic digital certificate files, including: </p>

          <ul className="list-unstyled list-numered row">
            <li className="col-md-4">
              <span className="numered">1</span>
              <span className="shadowtippy"></span>
              <h4>Security</h4>
              <p>Blockchain technology provides a highly secure and tamper-proof environment, as each certificate is encrypted and stored on a decentralized network. This significantly reduces the risk of forgery, unauthorized access, and manipulation of records.</p>
            </li>
            <li className="col-md-4">
              <span className="numered">2</span>
              <span className="shadowtippy"></span>
              <h4>Immutability</h4>
              <p>Once a certificate or badge is added to the blockchain, it becomes immutable, ensuring the integrity of the records. This feature prevents unauthorized changes or deletions, maintaining the credibility of the educational certificates. </p>
            </li>
            <li className="col-md-4">
              <span className="numered">3</span>
              <span className="shadowtippy"></span>
              <h4>Verification</h4>
              <p>Blockchain-based certificates are easily verifiable, as employers or educational institutions can quickly and securely access the records through unique digital identifiers. This eliminates the need for lengthy manual verification processes, saving time and effort for all parties involved.</p>
            </li>
            <li className="col-md-4">
              <span className="numered">4</span>
              <span className="shadowtippy"></span>
              <h4>Accessibility</h4>
              <p>    Blockchain records are accessible from anywhere in the world, as long as the user has the necessary credentials and an internet connection. This promotes seamless sharing of certificates with potential employers, academic institutions, or other relevant parties, without the need for physical copies. </p>
            </li>
            <li className="col-md-4">
              <span className="numered">5</span>
              <span className="shadowtippy"></span>
              <h4>Cost-effectiveness</h4>
              <p>Storing certificates on the blockchain reduces the costs associated with printing, shipping, and storing physical copies. Moreover, the streamlined verification process also saves resources, making blockchain-based certificates a more cost-effective solution. </p>
            </li>
            <li className="col-md-4">
              <span className="numered">6</span>
              <span className="shadowtippy"></span>
              <h4>Environmentally friendly</h4>
              <p>By eliminating the need for paper-based certificates, blockchain technology contributes to reducing paper waste and the overall environmental impact associated with traditional certificate management. </p>
            </li>
            <li className="col-md-4">
              <span className="numered">7</span>
              <span className="shadowtippy"></span>
              <h4>Lifelong storage</h4>
              <p>Blockchain technology allows for the permanent storage of educational certificates and badges, ensuring that users have access to their records throughout their lives, without the risk of loss or damage. </p>
            </li>
            <li className="col-md-4">
              <span className="numered">8</span>
              <span className="shadowtippy"></span>
              <h4>Interoperability</h4>
              <p>Blockchain-based certificates can be easily integrated with other digital systems, enabling seamless data exchange between different platforms and fostering greater collaboration between educational institutions, employers, and other stakeholders.</p>
            </li>
          </ul>
          <p>Overall, storing educational certificates and badges on the blockchain offers enhanced security, immutability, ease of verification, and accessibility, making it a superior solution compared to traditional print or basic digital certificate files. </p>
        </div>
      </div>



      


    </Fragment>


  );
}

export default CreateCertificatesOnBlockchain;