import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { putUserDetails, getUserByID, resetUserbyid, resetEdituser, commonSuccess, resetEdituserFailed, commonError } from '../actions/exampleAction';
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const StudentsEdit = () => {

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [batch, setBatch] = useState('');
  const [number, setNumber] = useState('');
  const [address, setAddress] = useState('')
  const [isValidPhnNo,setIsValidPhnNo] = useState(false)
  const [isLoading,setIsLoading] = useState(false)

  // console.log(params.studentId);

  const studentID = params.studentId;
  const edituser = useSelector(state => state.demoReducer.edituser);
  const userbyid = useSelector(state => state.demoReducer.userbyid);
  const edituserFailed = useSelector(state => state.demoReducer.edituserFailed);


  useEffect(() => {
    if(edituser.statusCode == 200){
      dispatch(resetEdituser());
      dispatch(commonSuccess("Updated successfully"))
      navigate("/users");
    }
  },[edituser]);

  useEffect(() => {
    if(userbyid.statusCode == 200){
      dispatch(resetUserbyid());
      // console.log(userbyid.data.orgUser);
      setEmail(userbyid.data.orgUser.email);
      setName(userbyid.data.orgUser.name);
      setBatch(userbyid 
        && userbyid.data 
        && userbyid.data.orgUser 
        && userbyid.data.orgUser.slot 
        && userbyid.data.orgUser.slot[0] 
        && userbyid.data.orgUser.slot[0].name);
      setNumber(userbyid.data.orgUser.phone);
      setAddress(userbyid.data.orgUser.flowAddress)
    }
  },[userbyid]);

  useEffect(() => {
    dispatch(getUserByID(studentID));
  },[]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = { email, name, batch, number };
    let data = {
      // "email": formData.email,
      // "name": formData.name,
      "slotName": formData.batch
    }
    // if(number){
    //   if(number && number.charAt(0) === '+'){
    //     data['phone'] = formData.number
    //   }else{
    //     data['phone'] = `+${formData.number}`
    //   }
    //   if(isValidPhnNo){
    //     setIsLoading(true);
    //     dispatch(putUserDetails(data,studentID));
    //   }
    // }else{
      setIsLoading(true);
      // data['phone'] = null;
      dispatch(putUserDetails(data,studentID));
    // }
    
    
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'name') {
      setName(value);
    } else if (name === 'batch') {
      setBatch(value);
    } else if (name === 'number') {
      setNumber(value);
    }
  };
  const isNumbervalid = (value,country) => {
    if (value.match(/12345/)) {
      setIsValidPhnNo(false)
      return 'Invalid value: '+value+', '+country.name;
    } else if (value.match(/1234/)) {
      setIsValidPhnNo(false)
       return false
    } else {
      setIsValidPhnNo(true)
      return true;
    }
  }
  useEffect(() => {
    if (edituserFailed && edituserFailed.statusCode) {
      setIsLoading(false);
      dispatch(resetEdituserFailed())
      if (edituserFailed && (edituserFailed.statusCode === 401)) {
        navigate('/login')
      }else if (edituserFailed && (edituserFailed.statusCode === 403)) {
        dispatch(commonError('Access denied'))
      }else if (edituserFailed && edituserFailed.data && edituserFailed.data[0] && edituserFailed.data[0][0] && typeof edituserFailed.data[0][0] === 'string') {
        dispatch(commonError(edituserFailed.data[0][0]))
      } else if (edituserFailed.message) {
        dispatch(commonError(edituserFailed.message))
      }
    }
  }, [edituserFailed])
  return (
    <div className='scrolldiv1'>
      <div className='row '>
        <div className='col-md-12 text-start'>
          <div className=''>
                  
            <div className='row mb-3 align-items-center'>
              <div className='col-md-12'><h4 className="fw-bolder text-black text-uppercase mb-0"></h4></div>
              {/* <div className='col-md-6 text-end'>
                <div className='btngrouprht'>
                  <a href='' className='btn btn-primary btn-icon'>< i data-eva-animation="flip" data-eva="plus-outline"></i> Add students</a>
                  <a href='' className='btn btn-primary btn-icon'>< i data-eva-animation="flip" data-eva="code-download-outline"></i> Import students</a>
                </div>
              </div> */}
            </div>
            <div className='backgroundblur'>
            
              <div className='searchform border-none d-block'>
              <form onSubmit={handleSubmit}>
              <div className='formscroldiv1 px-3'>
              <h6 className='mb-3 fw-bold'>User Details</h6>
                <div className='row'>
                <div className='col-md-4'>
                    <div className='form-group'>
                      <label className='mb-2'>User ID</label>
                      <input name="studentid" onChange={handleInputChange} value={studentID} type={'text'} className="form-control" placeholder='Email' disabled style={{pointerEvents:"none"}}/>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label className='mb-2'>Email</label>
                      <input name="email" onChange={handleInputChange} value={email} type={'text'} className="form-control" placeholder='Email' disabled style={{pointerEvents:"none"}}/>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label className='mb-2'>Name</label>
                      <input name="name" onChange={handleInputChange} value={name} type={'text'} className="form-control" placeholder='Name' disabled style={{pointerEvents:"none"}}/>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label className='mb-2'>Contact Number</label>
                      <PhoneInput
                              country={'us'}
                              value={number}
                              onChange={phone => setNumber(phone)}
                              placeholder="Contact Number"
                              inputClass="form-control"
                              disabled
                              isValid={(value, country) => isNumbervalid(value, country)}
                            />
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label className='mb-2'>Batch</label>
                      <input name="batch" onChange={handleInputChange} value={batch} type={'text'} className="form-control" placeholder='Batch'/>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label className='mb-2'>Flow Address</label>
                      <input name="batch" onChange={handleInputChange} value={address} type={'text'} className="form-control" placeholder='Address' disabled style={{pointerEvents:"none"}}/>
                    </div>
                  </div>
                  </div>
                  {/* <h6 className='my-3 fw-bold'>College Details</h6>
                  <div className='row'>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label className='mb-2'>College Name</label>
                      <input type={'text'} className="form-control" placeholder='College Name' value={''} />
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label className='mb-2'>Batch</label>
                      <select className="form-control">
                            <option>Select Batch</option>
                            <option value="1" selected>2020</option>
                            <option value="2">2021</option>
                            <option value="3">2022</option>
                          </select>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label className='mb-2'>Department</label>
                      <select className="form-control">
                            <option>Select Department</option>
                            <option value="1">B.Sc</option>
                            <option value="2">B.Com</option>
                            <option value="3" selected>BCA</option>
                          </select>
                    </div>
                  </div>
                </div>*/}
              </div>
              <hr className='light-brd'/>
              <div className='row align-items-center'>                  
                <div className='col-12 text-center'>
                  <div className='btngrouprht'>
                  {isLoading ? 
                           <button
                           type="button"
                           className="btn btn-light btn-icon btn-disabled bg-white filter-none"
                         >
                            <img src={require('../assets/images/certifi-loader.gif')} loading="lazy" width={28} /></button>: 
                            <button className='btn btn-primary btn-icon icon-rht' type='submit'>Save</button>}
                    
                  </div>
                </div>
              </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentsEdit;