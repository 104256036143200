import { Fragment, React, useEffect, useState, useRef } from 'react';
import * as eva from 'eva-icons';
import AOS from 'aos';
import "aos/dist/aos.css";
import { useNavigate } from 'react-router-dom';
import { getSelectedUserOid, getSelectedUserRole, getUserRole, isUserLoggedIn } from '../helpers/authData';
import { NavLink, useLocation } from 'react-router-dom';
import { mainRoles, userRoles } from '.././components/shared/Roles';
import { isOrgRoleEnabled, isUserRoleEnabled } from 'utils/utils';


const HomeLayout = ({ children }) => {

    useEffect(() => { eva.replace() });

    const [scroll, setScroll] = useState(false);
  
  
    useEffect(() => {
      document.body.classList.add('home-layout')
      return () => {
        document.body.classList.remove('home-layout')
      }
    }, [])
  
    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 50);
      });
    }, []);
  
    useEffect(() => {
      AOS.refresh();
      AOS.init({
        duration: 2000
      });
    }, []);
  
  
    const [isActive, setActive] = useState(false);
    const [isActive1, setActive1] = useState(false);
    const [isActive2, setActive2] = useState(false);
    const [isActive3, setActive3] = useState(false);
    const [isActive4, setActive4] = useState(false);
    const [isActive5, setActive5] = useState(false);
    const [certNo, setCertNo] = useState('')
    const toggleClass = () => { setActive(!isActive); };
    const toggleClass1 = () => { setActive1(!isActive1); };
    const toggleClass2 = () => { setActive2(!isActive2); };
    const toggleClass3 = () => { setActive3(!isActive3); };
    const toggleClass4 = () => { setActive4(!isActive4); };

    const toggleNav = () => { setActive5(!isActive5); };
    const oid = getSelectedUserOid()

    const navigate = useNavigate()
    const location = useLocation()
    const gotoLogin = () => {
      navigate('/login')
    }
    
    const gotoVerifyWithNumber = () => {
      if (certNo) {
        navigate(`/verify/${certNo}`)
      } else {
        navigate('/verify')
      }
    }
 
  
    const getYear = () => {
      return new Date().getFullYear();
    }
   
    const priceRef = useRef(null)
   
    
    const executeScroll = (event) => {
            event.preventDefault();
            navigate('/', { state: { myProp: 'pricing' } });
          
    }     
    const gotoDashboard = () => {
      if(isOrgRoleEnabled(getSelectedUserRole())){
        navigate(`/institution/${oid}/dashboard`)
      }else if(isUserRoleEnabled(getSelectedUserRole())){
        navigate('/dashboard')
      }else{
        navigate('/login')
      }
     
    }
    const gotoCreate = () => {
      if(isUserLoggedIn()){
        if(isOrgRoleEnabled(getSelectedUserRole())){
          navigate(`institution/${oid}/create/all`)
        }else{
          navigate('/create/all')
        }
        
      }else{
        navigate('/login')
      }
    }
  return (
    <Fragment>
           <div className='hl-header'>
        <div className={scroll ? "hl-navbar fixednav" : "hl-navbar"}>
          <div className='hl-winnews'><img src={require('../assets/images/home/icons/celebration.png')} alt="Certifily" loading="lazy" /> Certifily <a href='https://flowhackathon.notion.site/Prizes-and-Bounties-06ffb38f2e574afba793ad53b66f6052' target='_blank'><span className='ms-1'>won</span> in <span>Flow Hackathon 2023</span></a></div>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
              <div className='cshnav'>
                <div className='csnavbrand'>
                  <a className="navbar-brand" href="/">
                    <img src={require('../assets/images/logo.png')} alt="Certifily" loading="lazy" />
                  </a>
                </div>

               
                <form className='csv-search' onSubmit={gotoVerifyWithNumber}>
                {(location && location.pathname.includes('verify') ? '' :<div className="input-group mb-0 inputbtngroup">
                    <input type="text" name="certNo" className="form-control" placeholder="Verify a CERT #" value={certNo} onChange={(e) => { setCertNo(e.target.value) }} />
                    <button type="submit" className="btn btn-primary" onClick={gotoVerifyWithNumber}>Verify</button>
                  </div>)}
                </form> 

                <button onClick={toggleNav} className={isActive5 ? 'navbar-toggler ' : 'navbar-toggler collapsed'}  type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarButtonsExample" aria-controls="navbarButtonsExample" aria-expanded={isActive5 ? 'true' : 'false'}  aria-label="Toggle navigation">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div id="navbarButtonsExample" className={isActive5 ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'}  >                
                  <ul className="navbar-nav mb-2 mb-lg-0">
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-mdb-toggle="dropdown" data-mdb-auto-close="true" aria-expanded="false">Explore</a>
                      <ul className="dropdown-menu">
                        <li onClick={toggleNav}><NavLink className={({ isActive }) => (isActive ? "active dropdown-item" : 'dropdown-item')} to="/" onClick={executeScroll}>For Individuals</NavLink></li>
                        <li onClick={toggleNav}><NavLink to="/create-certificates-on-blockchain" className={({ isActive }) => (isActive ? "active dropdown-item" : 'dropdown-item')}>For Educational Institutions</NavLink></li>
                        <li onClick={toggleNav}><NavLink to="/documents-web3-digital-signing-on-blockchain" className={({ isActive }) => (isActive ? "active dropdown-item" : 'dropdown-item')}>For Businesses</NavLink></li>
                      </ul>
                    </li>
                    <li className="nav-item" style={{ cursor: "pointer" }}><NavLink className="nav-link" to="/verify">Verify</NavLink></li>
                    {isUserLoggedIn() ? <li className="nav-item" style={{ cursor: "pointer" }}><a className="nav-link" onClick={gotoDashboard}>Dashboard</a></li> :
                      <li className="nav-item" style={{ cursor: "pointer" }}><a className="nav-link" onClick={gotoLogin}>Login</a></li>}
                  </ul>                           
                </div>
              </div>
            </div>
          </nav>
        </div>

        {(location && location.pathname === "/") ? <div className='hl-banner'>
          <div className='hlb-img'>
            <div className='container'>
              <div className='hlb-content'>
                <h1 data-aos="fade-down">Professional documents on blockchain</h1>
                <p data-aos="fade-up"><span>No Wallet Installation</span>    |     <span>No Private Key</span>     |      <span>No Seed Phrase</span>     |     <span>JUST AN EMAIL IS ENOUGH</span></p>
              </div>
              <div className='hl-wboxes'>
                <div className='hl-wbox' data-aos="fade-up" data-aos-delay="100">
                  <div className='hlwboximg'>
                    <img src={require('../assets/images/home/h-certificate.png')} alt="Certifily" loading="lazy" />
                  </div>
                  <div className='hlwboxcont'>
                    <h2>Issues</h2>
                    <h3> Certificates & Badges</h3>
                  </div>
                  <div className='hlwboxlink'>
                    <a onClick={gotoCreate} className='cursor-pointer'>Issue <i data-eva-animation="flip" data-eva="arrowhead-right-outline"></i></a>
                  </div>
                </div>
                <div className='hl-wbox' data-aos="fade-up" data-aos-delay="200">
                  <div className='hlwboximg'>
                    <img src={require('../assets/images/home/h-create-sign.png')} alt="Certifily" loading="lazy" />
                  </div>
                  <div className='hlwboxcont'>
                    <h2>Create or Upload & sign</h2>
                    <h3>Documents</h3>
                  </div>
                  <div className='hlwboxlink'>
                    <a onClick={gotoCreate} className='cursor-pointer'>Create <i data-eva-animation="flip" data-eva="arrowhead-right-outline"></i></a>
                  </div>
                </div>
                <div className='hl-wbox' data-aos="fade-up" data-aos-delay="300">
                  <div className='hlwboximg'>
                    <img src={require('../assets/images/home/h-store-share.png')} alt="Certifily" loading="lazy" />
                  </div>
                  <div className='hlwboxcont'>
                    <h2>Store & Share</h2>
                    <h3>Documents on IPFS</h3>
                  </div>
                  <div className='hlwboxlink'>
                    <a onClick={gotoCreate} className='cursor-pointer'>Share <i data-eva-animation="flip" data-eva="arrowhead-right-outline"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : ''}
      </div>
      {children}
      <div className='hl-footer'>
        <div className='container'>
          <div className='hl-flist'>
            <div className='hl-flogo'>
              <a className="navbar-brand" href="/#">
                <img src={require('../assets/images/logo.png')} alt="Certifily" loading="lazy" />
              </a>
              <p>Professional documents on blockchain</p>
              <a href='mailto:reach@certifi.ly' className='btn btn-light btn-icon'><i data-eva="email-outline"></i> reach@certifi.ly</a>
            </div>
            <div className='hl-flinks'>
              <div className={isActive ? 'ftr__list nav open' : 'ftr__list nav'}  >
                <h4 onClick={toggleClass}>Products</h4>
                <ul className='list-unstyled'>
                  <li><a href="#">Overview</a></li>
                  <li><a href="#">Features</a></li>
                  <li><a href="#">Solutions</a></li>
                  <li><a href="#">Pricing</a></li>
                </ul>
              </div>
              <div className={isActive1 ? 'ftr__list nav open' : 'ftr__list nav'} >
                <h4 onClick={toggleClass1} >Company</h4>
                <ul className='list-unstyled'>
                  <li><a href="#">About us</a></li>
                  <li><a href="#">Press</a></li>
                  <li><a href="#">News</a></li>
                  <li><a href="#">Contact</a></li>
                </ul>
              </div>
              <div className={isActive2 ? 'ftr__list nav open' : 'ftr__list nav'} >
                <h4 onClick={toggleClass2} >Resources</h4>
                <ul className='list-unstyled'>
                  <li><a href="#">Blog</a></li>
                  <li><a href="#">Newsletter</a></li>
                  <li><a href="#">Events</a></li>
                  <li><a href="#">Help center</a></li>
                </ul>
              </div>
              <div className={isActive3 ? 'ftr__list nav open' : 'ftr__list nav'} >
                <h4 onClick={toggleClass3} >Social</h4>
                <ul className='list-unstyled'>
                  <li><a href="#">Twitter</a></li>
                  <li><a href="#">LinkedIn</a></li>
                  <li><a href="#">Facebook</a></li>
                  <li><a href="#">GitHub</a></li>
                </ul>
              </div>
              <div className={isActive4 ? 'ftr__list nav open' : 'ftr__list nav'}  >
                <h4 onClick={toggleClass4}>Legal</h4>
                <ul className='list-unstyled'>
                  <li><a href="#">Terms</a></li>
                  <li><a href="#">Privacy</a></li>
                  <li><a href="#">Cookies</a></li>
                  <li><a href="#">Licenses</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className='hl-copyrights'>
            <div className='hl-copytxt'>
              &copy; {getYear()} Certifi.ly. All rights reserved.
            </div>
            <div className='hl-socicons'>
              <a href='https://twitter.com/Certifily' target="_blank"><i data-eva="twitter-outline"></i></a>
              <a href='https://www.linkedin.com/company/91352558' target="_blank"><i data-eva="linkedin-outline"></i></a>
              <a href=''><i data-eva="facebook-outline"></i></a>
              <a href=''><i data-eva="github-outline"></i></a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default HomeLayout;