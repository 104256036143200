import { React, useState, useEffect } from 'react';
import * as eva from 'eva-icons';
import { useDispatch, useSelector } from 'react-redux';
import { commonError, getStudentStats, getUserCertList, resetGetAllStudentStats, resetGetAllStudentStatsFailed, resetUserCertList } from '../actions/exampleAction';
import ReactTimeAgo from 'react-time-ago';
import { apiAsBlob } from '../services/api';
import { getFlowAddress, getOrgId, getUserId, getUsername, logoutUser } from '../helpers/authData';
import { useLocation, useNavigate } from 'react-router-dom';
import { canAccessMenu } from 'utils/utils';
import { services } from './shared/Roles';
import { CertifiBox } from './shared/CertifiBox';

const StudentDashboard = (props) => {

    const [viewCertificate, setViewCertificate] = useState(false)
    const [certificateCount, setCertificateCount] = useState(0);
    const [certificateList, setCertificateList] = useState([]);
    const [apiLoading, setApiLoading] = useState(true);
    const [userDetail,setUserDetail] = useState({})
    const [servicesArray,setServicesArray] = useState([])
    const dispatch = useDispatch();
    const userCertList = useSelector(state => state.demoReducer.userCertificateList);

    const statsSuccess = useSelector(state => state.demoReducer.studentStats);
    const statsFailed = useSelector(state => state.demoReducer.studentStatsFailed);
    const userProfile = useSelector(state => state.demoReducer.userProfile);

    let userId = getUserId();
    let userName = getUsername();
    let flowAddress;
  const userProfileWithWallet = useSelector(state => state.demoReducer.userProfile);
  if(userProfileWithWallet && userProfileWithWallet.data && userProfileWithWallet.data.flowAddress){
    flowAddress = userProfileWithWallet.data.flowAddress
  }
    const navigate = useNavigate()
    const viewCertificatepage = () => {
        if (certificateCount > 0) {
            setViewCertificate(true);
        }
    }
    const dismissView = () => {
        setViewCertificate(false)
    }

    useEffect(() => {
        dispatch(getUserCertList(userId));
        dispatch(getStudentStats())
        if(userProfile && userProfile.statusCode === 200){
            setUserDetail(userProfile.data)
        }
    }, []);

    useEffect(() => {
        if (userCertList.statusCode == 200) {
            setApiLoading(false);
            dispatch(resetUserCertList());
            // console.log('userCertList');
            if (userCertList?.data?.count > 0) {
                // setCertificateCount(userCertList.data.count);
                // console.log(userCertList.data.certificates);
                setCertificateList(userCertList.data.certificates);
            }
        }
    }, [userCertList]);

    useEffect(() => { eva.replace() });


    const shareOnLinkedIn = (mycert) => {
        const message = "Certifily";

        const linkUrl = "https://dev.certifi.ly/verify";

        const summary = "Certifily certificate"

        // Replace this with your LinkedIn share URL
        const shareUrl = "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(mycert) + "&title=" + encodeURIComponent(message) + "&source=" + encodeURIComponent(linkUrl) + "&summary=" + encodeURIComponent(summary); 

        window.open(shareUrl, "_blank");
    }

    const buttonStyle = {
        cursor: 'pointer'
    };

    const downloadOnClick = async (url, nftId) => {
        const res = await apiAsBlob(url);
        if (res && res.status === 200) {
            var blob = new Blob([res.data], { type: 'application/pdf' });
            var blob_url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blob_url;
            link.download = `${nftId}.pdf`;
            link.click();
            URL.revokeObjectURL(blob_url);
        }
    };
    useEffect(() => {
        if (statsSuccess && statsSuccess.statusCode == 200) {
            setApiLoading(false);
            dispatch(resetGetAllStudentStats());
            if (statsSuccess?.data?.count > 0) {
                setCertificateCount(statsSuccess.data.count);
            }
        }
    }, [statsSuccess]);
    useEffect(() => {
        if (statsFailed && statsFailed.statusCode) {
            dispatch(resetGetAllStudentStatsFailed());
            setApiLoading(false);
            setCertificateCount(0)
            if(statsFailed.statusCode === 401){
              navigate('/login')
            }else if(statsFailed.statusCode === 403){
                dispatch(commonError('Access denied'))
              }
        }
      }, [statsFailed]);
    useEffect(() => {
        if(props && props.services){
            setServicesArray(props.services)
        }
    },[props])
    return (
        <div className='scrolldiv studentscroll'>
            <div className='row fadein'>
                <div className='col-md-12 text-start'>

                    {!viewCertificate ? <div className='certtemplates mintnft studentdash'>
                        <div className='row'>
                            <div className="col-sm-6 col-md-4">
                                <div className='createcetr'>
                                    <h5>Welcome</h5>
                                    <h4 className='fw-bolder text-primary'>{userName}</h4>
                                    <div className='lastestnfts'>
                                        <h5>Recent Documents</h5>
                                        { apiLoading ?
                                            <div className="text-center">
                                                <img src={require('../assets/images/certifi-loader.gif')} loading="lazy" />
                                            </div>
                                            :
                                            <div>
                                                {certificateCount == 0 ?
                                                    <p>There are no Recent Documents</p>
                                                    :
                                                    <ul className='list-unstyled'>
                                                        {certificateList.map((user, index) => (
                                                            <li key={index}>
                                                                <a href={`${window.location.origin}/verify/`+user.certificateNumber} target="_blank">
                                                                    <span className='img'><img src={require('../assets/images/icons/Certifily-icon.png')} loading="lazy" /></span>
                                                                    <span className='lnftscont nftlistborder'>
                                                                        <ReactTimeAgo date={user.datetimeCreated} locale="en-US" />
                                                                        <h6>Certificate from {user.org.name}</h6>
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {canAccessMenu(servicesArray,services.CERTIFICATE) ? 
                            <CertifiBox onClick={viewCertificatepage} image={'Certifily-icon.png'} heading="Certificates" count={certificateCount} verificationNeed = {false}/> 
                            : ''}
                             {canAccessMenu(servicesArray,services.DOCUMENT) ? 
                            <CertifiBox image={'documents.png'} heading="Documents" count={0}/> 
                            : ''}
                            {canAccessMenu(servicesArray,services.MEMBERSHIP) ? 
                            <CertifiBox image={'Group-16.png'} heading="Memberships" count={0}/> 
                            : ''}
                            {canAccessMenu(servicesArray,services.CARD) ? 
                            <CertifiBox image={'Group-16-2.png'} heading="Cards" count={0}/> 
                            : ''}
                            {canAccessMenu(servicesArray,services.TICKET) ? 
                            <CertifiBox image={'Group-16-3.png'} heading="Tickets" count={0}/> 
                            : ''}
                            


                        </div>
                    </div> :
                        <div>
                            <div className='certtemplates mintnft studentcert'>
                                <div className="row mb-3 align-items-center"><div className="col-md-12">
                                    <div className='backtit'>
                                        <button className='btn btn-light btn-icon ' type="button" onClick={dismissView} >< i data-eva-animation="flip" data-eva="arrow-back-outline"></i> Back </button>
                                        <h4 className="fw-bolder text-black text-uppercase mb-0"><img src={require('../assets/images/icons/Certifily-icon.png')} loading="lazy" width={30} /> Certificates</h4>
                                    </div>
                                </div>
                                </div>
                                <div className='row'>
                                    {certificateList.map((user, index) => (
                                        <div key={index} className="col-md-6">
                                            <div className='ctemp' >
                                                <label className='backgroundblur' htmlFor="cert-1">
                                                    <p className='text-end w-100 mb-3'>
                                                    <a href={"https://testnet.flowview.app/account/"+flowAddress+"/collection/CertifilyNFTCollection/"+user.nftId} target="_blank">
                                                        #{user.nftId} 
                                                    </a>
                                                    <a href={`${window.location.origin}/verify/`+user.certificateNumber} target="_blank">
                                                    <span className='badge badge-primary ms-2 text-uppercase'>
                                                        Verify
                                                    </span>
                                                    </a></p>
                                                    <iframe src={user.certificateHash}></iframe>
                                                    <div className='row align-items-center'>
                                                        <div className='col-md-7 text-start'>
                                                            <p className='mt-3 mb-2'>Issued on <span>
                                                                {
                                                                    new Date(user.datetimeCreated).toLocaleDateString('en-US', {
                                                                        year: 'numeric',
                                                                        month: 'short',
                                                                        day: 'numeric'
                                                                    })
                                                                }</span>
                                                            </p>
                                                            <p className=''>by {user.org['name']}</p>
                                                        </div>
                                                        <div className='col-md-5 text-end'>
                                                            <span className='eva-hover d-inline-flex align-items-center' style={buttonStyle}  onClick={() => shareOnLinkedIn(user.thumbnailHash)}><i className='mr-2' data-eva="share-outline" data-eva-animation="flip"></i> Share</span>
                                                            <span className='ms-2 eva-hover d-inline-flex align-items-center' style={buttonStyle} onClick={() => downloadOnClick(user.certificateHash, user.nftId)}><i className='mr-2' data-eva="code-download-outline" data-eva-animation="flip"></i> Download</span>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    ))}

                                    {/* <div className="col-md-6">
                                        <div className='ctemp' >
                                            <label className='backgroundblur' htmlFor="cert-1">
                                                <p className='text-end w-100 mb-3'>#234254 <span className='badge badge-success ms-2 text-uppercase'>Verify</span></p>
                                                <iframe src={require('../assets/images/certificate-ela.pdf#toolbar=0&navpanes=0&scrollbar=0')}></iframe>
                                                <div className='row align-items-center'>
                                                    <div className='col-md-7 text-start'>
                                                        <p className='mt-3 mb-2'>Issued on Feb 23 2023</p>
                                                        <p className=''>by Madurai Kamaraj University</p>
                                                    </div>
                                                    <div className='col-md-5 text-end'>
                                                        <span className='eva-hover d-inline-flex align-items-center'><i className='mr-2' data-eva="share-outline" data-eva-animation="flip"></i> Share</span>
                                                    </div>
                                                </div>

                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 " >
                                        <div className='ctemp' >
                                            <input type={'radio'} id="cert-2" name='cert-2' disabled />
                                            <label className='backgroundblur' htmlFor="cert-2">
                                                <p className='text-end w-100 mb-3'>#234254 <span className='badge badge-success ms-2 text-uppercase'>Verify</span></p>
                                                <iframe src={require('../assets/images/certificate-shun.pdf')}></iframe>
                                                <div className='row align-items-center'>
                                                    <div className='col-md-7 text-start'>
                                                        <p className='mt-3 mb-2'>Issued on Feb 23 2023</p>
                                                        <p className=''>by Madurai Kamaraj University</p>
                                                    </div>
                                                    <div className='col-md-5 text-end'>
                                                        <span className='eva-hover d-inline-flex align-items-center'><i className='mr-2' data-eva="share-outline" data-eva-animation="flip"></i> Share</span>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>}



                </div>

            </div>
        </div >
    );
}

export default StudentDashboard;
