import { authpostSignIn } from '../actions/authuserAction';
import * as eva from 'eva-icons';
import {
    applyActionCode,
    confirmPasswordReset,
    getAuth,
    verifyPasswordResetCode,
} from 'firebase/auth';
import { useQuery } from '../helpers/useQuery';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { commonError, isEmailVerifiedCheck } from 'actions/exampleAction';
import { updateFirebaseAuth } from 'helpers/updateFirebaseAuth';

export function EmailActionHandler() {
    const queryParams = useQuery();
    const [isValidUrl, setIsValidUrl] = useState(true);
    const [erroMessage, setErroMessage] = useState('Invalid Url');
    const [erroDetail, setErroDetail] = useState(
        'Your request could not be verified. please check the link'
    );
    const [isLoading, setIsLoading] = useState(true);
    const [showResetForm, setShowResetForm] = useState(false);
    const [mode, setMode] = useState('');
    const [actionCode, setActionCode] = useState('');
    const [auth] = useState(getAuth());
    const [accountEmail, setAccountEmail] = useState('');
    const dispatch = useDispatch();
    useEffect(() => { eva.replace() });
    useEffect(() => {
        setIsLoading(true);
        const mode = queryParams.get('mode');
        const actionCode = queryParams.get('oobCode');
        const continueUrl = queryParams.get('continueUrl');
        const lang = queryParams.get('lang') || 'en';
        setActionCode(actionCode);
        setMode(mode);
        if (!mode || !actionCode) {
            setIsValidUrl(false);
        }
        switch (mode) {
            case 'verifyEmail':
                handleVerifyEmail(auth, actionCode, continueUrl, lang).then(async res => {
                    await updateFirebaseAuth()
                    // dispatch(isEmailVerifiedCheck())
                }).catch(
                    (e) => {
                        setIsValidUrl(false);
                        setIsLoading(false);
                        switch (e.code) {
                            case 'auth/invalid-action-code':
                                setErroMessage('Try verifing your email Again');
                                setErroDetail(
                                    'Your request to verify your email ' +
                                    'has expired or the link ' +
                                    'has already been used.'
                                );
                                break;
                            default:
                                setErroMessage('Try verifing your email Again');
                                setErroDetail(
                                    'Something went wrong while trying to verify your email.'
                                );
                                break;
                        }
                    }
                );
                break;
            case 'resetPassword':
                handleResetPassword(auth, actionCode, continueUrl, lang).catch(
                    (e) => {
                        setIsValidUrl(false);
                        setIsLoading(false);
                        switch (e.code) {
                            case 'auth/invalid-action-code':
                                setErroMessage(
                                    'Try resetting your password Again'
                                );
                                setErroDetail(
                                    'Your request to reset your password ' +
                                    'has expired or the link ' +
                                    'has already been used.'
                                );
                                break;
                            default:
                                setErroMessage(
                                    'Try verifing your password Again'
                                );
                                setErroDetail(
                                    'Something went wrong while trying to resetting your password.'
                                );
                                break;
                        }
                    }
                );
                break;
            default:
                setIsValidUrl(false);
                setIsLoading(false);
                console.error('Invalid mode of action');
        }
    }, [queryParams, auth]);

    const handleVerifyEmail = async (auth, actionCode, continueUrl, lang) => {
        await applyActionCode(auth, actionCode);
        
        setIsLoading(false);
    };

    const handleResetPassword = async (auth, actionCode, continueUrl, lang) => {
        const email = await verifyPasswordResetCode(auth, actionCode);
        setAccountEmail(email);
        setIsLoading(false);
        setShowResetForm(true);
    };

    const handleNewPassword = (formData) => {
        setIsLoading(true);
        setShowResetForm(false);
        confirmPasswordReset(auth, actionCode, formData.confirmPassword)
            .then(() => {
                setIsLoading(false);
                // dispatch(
                //     authpostSignIn(accountEmail, formData.confirmPassword)
                // );
            })
            .catch((e) => {
                console.error(e);
                setIsValidUrl(false);
                if (e.code === 'auth/invalid-action-code') {
                    setErroMessage('Try resetting your password Again');
                    setErroDetail(
                        'Your request to reset your password ' +
                        'has expired or the link ' +
                        'has already been used.'
                    );
                } else {
                    setErroMessage('Try verifing your password Again');
                    setErroDetail(
                        'Something went wrong while trying to resetting your password.'
                    );
                }
                setShowResetForm(false);
                setIsLoading(false);
            });
    };

    return (
        <div className="logouter">
            <div className="logincon">
                <div className="loginimg">
                    <img src={require('../assets/images/login-bg.jpg')} alt="" loading="lazy" />
                    <div className="logtxtbox">
                        <h1>Professional documents on <span className="dartxtclr">blockchain</span></h1>
                    </div>
                </div>
                <div className="loginform fadein">
                    <div className="main-content">
                        <div className="main-logo main-logo--cert justify-content-center"><a className="cursor-pointer"><img src={require('../assets/images/logo.png')} alt="certifily" loading="lazy" /></a></div>
                        <div id="log-in" className="base-layout">
                            {showResetForm === false ? (
                                <div className="row">
                                    {isValidUrl ? (
                                        <div className="col-sm-12">
                                            {isLoading ? (
                                                <div className="text-center">
                                                    <img
                                                        src={require('../assets/images/certifi-loader.gif')}
                                                        loading="lazy"
                                                        width={28}
                                                        alt="loding indicator"
                                                    />
                                                    <p>
                                                        <em>Processing...</em>
                                                    </p>
                                                </div>
                                            ) : (
                                                <SuccessMessage
                                                    className=" mt-5 fadein"
                                                    mode={mode}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className="text-center fadein" role="alert">
                                            <h1 className='mb-3'>{erroMessage}</h1>
                                            <p className='mb-3'>
                                                {erroDetail} If you have any
                                                questions or concerns, please don't
                                                hesitate to contact our support team
                                                at                                            
                                            </p>
                                            <a className="alert-link btn btn-primary btn-icon" href="mailto:reach@certifi.ly"><i data-eva="email-outline"></i> reach@certifi.ly</a>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ResetPasswordForm
                                            handleAction={handleNewPassword}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

function SuccessMessage(props) {
    console.log('SuccessMessage props', props);
    switch (props.mode) {
        case 'verifyEmail':
            return (
                <div className="container text-center mx-auto fadein mt-5">
                    <h1>Thank you for verifing your email</h1>
                    <p>
                        Your account is now fully activated and ready to use. We
                        appreciate your time and look forward to serving you. If
                        you have any questions or concerns, please don't
                        hesitate to contact our support team at&nbsp;
                        <a href="mailto:reach@certifi.ly">reach@certifi.ly</a>.
                    </p>
                    <p className="text-center">
                        <Link to="/dashboard">
                            <button type="button" className="btn btn-primary">
                                Go to Dashboard
                            </button>
                        </Link>
                    </p>
                </div>
            );
        case 'resetPassword':
            return (
                <div className="container text-center mx-auto fadein mt-5">
                    <h1>Success!</h1>
                    <p>
                        Your Password has been reset. Please use your new
                        password for future logins. If you have any questions or
                        concerns, please don't hesitate to contact our support
                        team at&nbsp;
                        <a href="mailto:reach@certifi.ly">reach@certifi.ly</a>.
                    </p>
                    <p className="text-center">
                        <Link to="/login">
                            <button type="button" className="btn btn-primary">
                                Login
                            </button>
                        </Link>
                    </p>
                </div>
            );
        default:
            return (
                <div className="container text-center mt-5">
                    <h1>Your request has been processed</h1>
                    <p>
                        If you have any questions or concerns, please don't
                        hesitate to contact our support team at&nbsp;
                        <a href="mailto:reach@certifi.ly">reach@certifi.ly</a>.
                    </p>
                    <p className="text-center">
                        <Link to="/login">
                            <button type="button" className="btn btn-primary">
                                Login
                            </button>
                        </Link>
                    </p>
                </div>
            );
    }
}

function ResetPasswordForm(props) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formInvalid, setFromInvalid] = useState(false);
    const [validationError, setValidationError] = useState('');
    const dispatch = useDispatch()
    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === '' || confirmPassword === '') {
            setFromInvalid(true);
            dispatch(commonError('Please fill all the fields'))
            return;
        }
        if (password.length < 8) {
            setFromInvalid(true);
            dispatch(commonError('Password must be atleast 8 characters long'))
            return;
        }
        if (password !== confirmPassword) {
            setFromInvalid(true);
            dispatch(commonError("Password and Confirm Password doesn't match"))
            
            return;
        }
        props.handleAction({
            password,
            confirmPassword,
        });
    };
    const handlePwdInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        switch (name) {
            case 'newPassword':
                setPassword(value);
                break;
            case 'confirmNewPassword':
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };
    return (
        <div className="searchform border-none d-block">
            <form onSubmit={handleSubmit}>
                <div className="formscroldiv1 px-3">
                    <h6 className="mb-3 fw-bold">Change your password</h6>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="mb-2">New password *</label>
                                <input
                                    name="newPassword"
                                    type={'password'}
                                    value={password}
                                    onChange={handlePwdInputChange}
                                    className="form-control"
                                    placeholder="New password"
                                />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="mb-2">
                                    Confirm new password *
                                </label>
                                <input
                                    name="confirmNewPassword"
                                    type={'password'}
                                    value={confirmPassword}
                                    onChange={handlePwdInputChange}
                                    className="form-control"
                                    placeholder="Confirm new password"
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-12">
                            {formInvalid ? (
                                <div className="alert alert-danger text-center mx-auto fadein">
                                    {validationError}
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div> */}
                </div>
                <hr className="light-brd" />
                <div className="row align-items-center">
                    <div className="col-12 text-center">
                        <div className="btngrouprht">
                            {/* {isLoading ? (
                                <button
                                    type="button"
                                    className="btn btn-light btn-icon btn-disabled bg-white"
                                >
                                    <img
                                        src={require('../assets/images/certifi-loader.gif')}
                                        loading="lazy"
                                        width={28}
                                        alt="loding indicator"
                                    />
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-icon icon-rht"
                                >
                                    Change password
                                </button>
                            )} */}
                            <button
                                type="submit"
                                className="btn btn-primary btn-icon icon-rht"
                            >
                                Change password
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
