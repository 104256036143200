import { Fragment, React, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as eva from 'eva-icons';
import { NavLink } from 'react-router-dom';
import { EditorState, convertToRaw, Modifier, Entity, RichUtils, convertFromRaw, AtomicBlockUtils, Image, DefaultDraftBlockRenderMap } from 'draft-js';
import Editor, {composeDecorators} from '@draft-js-plugins/editor';
import 'draft-js/dist/Draft.css';
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import createSideToolbarPlugin from '@draft-js-plugins/side-toolbar';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import { useDrag, useDrop } from 'react-dnd'
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  UnorderedListButton,
  OrderedListButton,
  AlignBlockDefaultButton,
  AlignBlockLeftButton,
  AlignBlockCenterButton,
  AlignBlockRightButton
} from '@draft-js-plugins/buttons';

import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/side-toolbar/lib/plugin.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import SignatureCanvas from 'react-signature-canvas';
import { Modal, Button } from "react-bootstrap"
import createImagePlugin from '@draft-js-plugins/image';


const mentions1 = [{
  "id": 1,
  "name": "Kart",
  "title": "VP Product Management"
}, {
  "id": 2,
  "name": "Client",
  "title": "Blaze 275 E. Hillcrest Dr, Unit 160-184, Thousand Oaks, CA 91360"
}, {
  "id": 3,
  "name": "Contractor",
  "title": "Blaze 275 E. Hillcrest Dr, Unit 160-184, Thousand Oaks, CA 91360"
}, {
  "id": 4,
  "name": "Insurance",
  "title": "Blaze 275 E. Hillcrest Dr, Unit 160-184, Thousand Oaks, CA 91360"
}, {
  "id": 5,
  "name": "Insurance agreement",
  "title": "Blaze 275 E. Hillcrest Dr, Unit 160-184, Thousand Oaks, CA 91360"
}, {
  "id": 6,
  "name": " Client Address",
  "title": "Blaze 275 E. Hillcrest Dr, Unit 160-184, Thousand Oaks, CA 91360"
}, {
  "id": 7,
  "name": " Client Email",
  "title": "kart@blaze.ws"
}]
const mentions = [{
  "id": 1,
  "name": "VP Product Management",
  "title": "Brian Thompson",
  "icon": <i data-eva-animation="flip" data-eva="person-done-outline"></i>
}, {
  "id": 2,
  "name": "123 Main Street Cityville, USA Zip Code: 12345",
  "title": "Client",
  "icon": <i data-eva-animation="flip" data-eva="lock-outline"></i>
}, {
  "id": 3,
  "name": "456 Oak Avenue Townsville, USA Zip Code: 67890",
  "title": "Contractor",
  "icon": <i data-eva-animation="flip" data-eva="person-outline"></i>
}, {
  "id": 4,
  "name": "789 Elm Street Metropolis City, USA Zip Code: 54321",
  "title": "Insurance",
  "icon": <i data-eva-animation="flip" data-eva="shield-outline"></i>
}, {
  "id": 5,
  "name": "987 Maple Avenue Cityville, USA Zip Code: 56789",
  "title": "Insurance agreement",
  "icon": <i data-eva-animation="flip" data-eva="file-text-outline"></i>
}, {
  "id": 6,
  "name": "456 Oak Street Springfield, USA Zip Code: 12345",
  "title": "Client Address",
  "icon": <i data-eva-animation="flip" data-eva="globe-outline"></i>
}, {
  "id": 7,
  "name": "info@acmesolutionsltd.com",
  "title": "Client Email",
  "icon": <i data-eva-animation="flip" data-eva="email-outline"></i>
}]

const Entry = (props) => {
  console.log(props)
  const {    
    mention,
    theme,
    isFocused,
    searchValue, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;

  return (
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div className='cusmention'>
        <div className={theme.mentionSuggestionsEntryContainerLeft}>
          {mention.icon}
        </div>
        <div className={theme.mentionSuggestionsEntryContainerRight}>
        <div className={theme.mentionSuggestionsEntryTitle}>
            {mention.title}
          </div>
          <div className={theme.mentionSuggestionsEntryText}>
            {mention.name.substring(0, 20)}
          </div>
          </div>

        </div>
      </div>
    </div>
  );
};
const Agreement_Contract = () => {
  useEffect(() => { eva.replace() });
  const ref = useRef(null);
  const [editorState1, setEditorState1] = useState(
    () => EditorState.createEmpty(),
  );
  const [editorState2, setEditorState2] = useState(
    () => EditorState.createEmpty(),
  );
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(mentions);
  const [callBack, setCallBack] = useState(false)
  const editorRef = useRef();
  const [search, setSearch] = useState('')
  const [suggestionFilter, setSuggestionFilter] = useState(mentions)
  const [isAllowedToCreate, setIsAllowedToCreate] = useState(false)
  const [editname, setEditname] = useState('')
  const [editTitle, setEditTitle] = useState('')
  const canvasRef = useRef(null);
  const [signatureData, setSignatureData] = useState(null);
  const [signatureSet,setSignatureSet] = useState([])
  const [isSignReq,setIsSignReq] = useState(false)
  //----------------------------------------------------Editor 1
  const sideToolbarPlugin1 = createSideToolbarPlugin();
  const toolbarPlugin1 = createToolbarPlugin();
  // const { Toolbar } = toolbarPlugin1;
 
  const imagePlugin = createImagePlugin();
  // Entity.get(props.entityKey).getData();
  const mentionPlugin1 = createMentionPlugin({
    mentions,

    mentionComponent(props) {
      const mention = props.mention;
      const {children} = props
      const { entityKey } = props;
      const data = Entity.get(entityKey).getData();
      const mentionData = {mention};
      // const rawContentState = {
      //   entityMap: {
      //     '0': {
      //       type: 'mention',
      //       mutability: 'IMMUTABLE',
      //       data: mentionData,
      //     },
      //   },
      //   blocks: [
      //     {
      //       text: mention.title,
      //       type: 'unstyled',
      //       entityRanges: [
      //         {
      //           key: 0,
      //           length: 10,
      //           offset: 6,
      //         },
      //       ],
      //     },
      //   ],
      // };
      // const contentState = convertFromRaw(rawContentState);
      // const editorState = EditorState.createWithContent(contentState);
      // const blockKey = contentState.getBlockMap().keySeq().toArray()[0];

      // const block = contentState.getBlockForKey(blockKey);

      // const offset = 6
      // const mentiondata = block.getEntityAt(offset);
      // const start = 0;
      // const end = block.getLength();
      // const text = block.getText();
      

      // const fragments = block.getText().split(/(@[^\s]+)/);


      // const children = [];
      // if (mentiondata) {
      //   const entity = contentState.getEntity(mentiondata);
      //   const mentionDatas = entity.getData();
      //   console.log(entity,mentionDatas)
      
      
      //   children.push(
      //     <span key={blockKey} data-offset-key={blockKey}>
      //       {mentionDatas.mention.title}
      //     </span>
      //   );
      
       
      // } else {
      //   children.push(text);
      // }
      // console.log(props,children)
      return (
        <span
          className={props.className}
          onClick={(e) => {
            e.preventDefault();
          }}
          title={props.mention.title}
          // onKeyDown={handleKeyDown}
          // role="option"
          aria-selected={props.isFocused}
        >
           {/* <span className="mention-name"><span data-offset-key={key}><span data-text="true">@{props.mention.title}</span></span></span> */}
          <span className="mention-name">{children}</span>

        </span>
      );
    },
    entityMutability: 'IMMUTABLE',
    // mentionPrefix: '@',



    supportWhitespace: false,

  });
  const [plugins1] = useMemo(() => {


    return [[toolbarPlugin1, sideToolbarPlugin1, mentionPlugin1, imagePlugin]]
  },
    []);

  const [SideToolbar] = useMemo(() => {

    return [sideToolbarPlugin1.SideToolbar]


  }, [])


  const [MentionSuggestions] = useMemo(() => {
    return [mentionPlugin1.MentionSuggestions]
  }, [])
  const [Toolbar] = useMemo(() => {
    return [toolbarPlugin1.Toolbar]
  }, [])
  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(({ value }) => {
    console.log(value, defaultSuggestionsFilter)
    if (value) {
      setSuggestions(mentions.filter((mention) => mention.title.toLowerCase().includes(value.toLowerCase())),
      );
    }
  }, []);
  const handleEdit = (e) => {
    // e.target.contentEditable = true;
  };
  const handleInputChange = (e, idx) => {
    // const newText = e.target.textContent;
    //     mentions[idx].title = newText
    //     setSuggestionFilter(mentions)
    // console.log(`Item ${idx} text changed to: ${newText}`);
  };
  const TOOL = 'tool';
  const Tool = ({ name, title, id, idx, icon }) => {
    const [, drag] = useDrag({
      type: TOOL,
      item: {
        name, title, id
      }
    });
    return (
      <li className="tool" ref={drag}>
        <div 
          className='attribname' 
          onDoubleClick={handleEdit} 
          onInput={(e) => handleInputChange(e, idx)} 
          onBlur={(e) => (e.target.contentEditable = false)}
          style={{ border: 'none', outline: 'none' }}>
          {icon}{title}
        </div>
        <div className='attribcont'>
          <p>{name}</p>
        </div>
      </li>
    );
  }
  
  const [{ isOver }, dropRef] = useDrop({
    accept: TOOL,
    drop: (item) => { 
      console.log(item)
      if(item.id){
        handleDropAttribute(item)
      }
       },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  })

  const handleEditState = (value) => {
    // console.log(value)
    setEditorState1(value)
    const blocks = convertToRaw(editorState1.getCurrentContent()).blocks;
    const value1 = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    let inlineStyles = editorState1.getCurrentInlineStyle();

    const editorStateWithoutUndo = EditorState.set(editorState1, {
      allowUndo: false,
    });
    console.log(blocks, value1, editorStateWithoutUndo, inlineStyles)


  }
  const handleDropAttribute = (value) => {
    const selection = editorState1.getSelection();
    const contentState = editorState1.getCurrentContent();
    // let inlineStyles = editorState1.getCurrentInlineStyle();
    // const ncs = Modifier.insertText(contentState, selection, ` ${value.title}`,inlineStyles);
    // const es1 = EditorState.push(editorState1, ncs, 'insert-fragment');
    // console.log(inlineStyles)
    // console.log(es1)
    // setEditorState1(es)

    const stateWithEntity = Entity.create(
      'mention',
      'IMMUTABLE',
      {
        mention: { id: value.id, name: value.name, title: value.title },
      },
    )
    const stateWithText = Modifier.insertText(contentState, selection, ` ${value.name}`, null, stateWithEntity)
    const es = EditorState.push(editorState1, stateWithText, 'insert-mention')
    setEditorState1(es)
  }
  const focus = () => {
    if (editorRef) {
      editorRef.current.focus();
    }
  }

  const searchList = (searchAttr) => {
    const filtered = mentions.filter(mention => mention.title.toLowerCase().includes(searchAttr));
    setSuggestionFilter(filtered)

    if (filtered && filtered.length === 0) {
      setIsAllowedToCreate(true)
    }
  

  }
  const handleSearchChange = (e) => {
    setSearch(e.target.value)
    searchList(e.target.value)
  }
  const handleEditname = (e) => {
    const regex = /^\S.*[a-zA-Z\s]*$/g
    if (regex.test(e.target.value)) {
      setEditname(e.target.value)
    } else {
      setEditname('')
    }
  }
  const handleEditTitle = (e) => {
    const regex = /^\S.*[a-zA-Z\s]*$/g
    if (regex.test(e.target.value)) {
      setEditTitle(e.target.value)
    } else {
      setEditTitle('')
    }

  }
  const handleCreateAttribute = (e) => {
    e.preventDefault()
    if (editname !== '' && editTitle !== '') {
      const length = mentions.length
      const newMention = {
        id: length + 1,
        name: editTitle,
        title: editname,
        icon: <i data-eva-animation="flip" data-eva="plus-circle-outline"></i>
      };
      mentions.unshift(newMention)
      setIsAllowedToCreate(false)
      setSuggestionFilter(mentions)
      setSearch('')
      setEditTitle('')
      setEditname('')
    }
  }

  const handleDoubleClick = (e) => {
    const selection = editorState1.getSelection();
    const anchorKey = selection.getAnchorKey();
    const focusKey = selection.getFocusKey();
    const anchorOffset = selection.getAnchorOffset();
    const focusOffset = selection.getFocusOffset();

    const contentState = editorState1.getCurrentContent();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();

    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();

    const blockWithText = contentState.getBlockForKey(anchorKey);
    const text = blockWithText.getText().slice(startOffset,endOffset);
    const anchorBlock = contentState.getBlockForKey(anchorKey);
      const focusBlock = contentState.getBlockForKey(focusKey);
      const isBackward = selection.getIsBackward();
      const start = isBackward ? focusOffset : anchorOffset;
      const end = isBackward ? anchorOffset : focusOffset;
    
    if (e.target.tagName === 'SPAN') {
      console.log('double click on text:', text);
      // do something with the text, such as apply a custom style
      
    if (text.length > 0) {
      // setShowInlineToolbar(true)
      // const newEditorState = RichUtils.toggleInlineStyle(editorState1, 'BOLD');
      // setEditorState1(newEditorState);
      }
      
    

  
    

    }

  };

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
// Define the key binding function
const myKeyBindingFn = (event) => {
  if (event.keyCode === 66 && event.ctrlKey) { // 66 is the keyCode for B
    return 'apply-bold-style';
  }
  if (event.keyCode === 73 && event.ctrlKey) { // 73 is the keyCode for I
    return 'apply-italic-style';
  }
  if (event.keyCode === 85 && event.ctrlKey) { // 85 is the keyCode for U
    return 'apply-underline-style';
  }
  return undefined;
}
// Define the handleKeyCommand function
const handleKeyCommand = (command) => {
  if (command === 'apply-bold-style') {
    const newEditorState = RichUtils.toggleInlineStyle(editorState1, 'BOLD');
      setEditorState1(newEditorState);
  
  }
  if (command === 'apply-italic-style') {
    const newEditorState = RichUtils.toggleInlineStyle(editorState1, 'ITALIC');
    setEditorState1(newEditorState);
  }
  if (command === 'apply-underline-style') {
    const newEditorState = RichUtils.toggleInlineStyle(editorState1, 'UNDERLINE');
    setEditorState1(newEditorState);
  }
}


const handleClear = () => {
  canvasRef.current.clear();
};
const handleSaveSign = () => {
  
  const data = canvasRef.current.toDataURL('image/png');
    const dataset = [...signatureSet,data]
    setSignatureSet(dataset)
  setTimeout(() => {
    closeModal()
  },200)
};
const handleLoadSign = () => {
  if (signatureData) {
    canvasRef.current.fromDataURL(signatureData);
  }
};
const alignmentStyles = ['left', 'right', 'center'];
const applyAlignment = (newStyle) => {
  console.log(newStyle)
    let styleForRemove = alignmentStyles.filter(style => style !== newStyle);
    let currentContent = editorState1.getCurrentContent();
    let selection = editorState1.getSelection();
    let focusBlock = currentContent.getBlockForKey(selection.getFocusKey());
    let anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey());
    let isBackward = selection.getIsBackward();

    let selectionMerge = {
      anchorOffset: 0,
      focusOffset: focusBlock.getLength(),
    };

    if (isBackward) {
      selectionMerge.anchorOffset = anchorBlock.getLength();
    }
    let finalSelection = selection.merge(selectionMerge);
    let finalContent = styleForRemove.reduce((content, style) => Modifier.removeInlineStyle(content, finalSelection, style), currentContent);
    let modifiedContent = Modifier.applyInlineStyle(finalContent, finalSelection, newStyle);
    const nextEditorState = EditorState.push(editorState1, modifiedContent, 'change-inline-style');
    setEditorState1(nextEditorState);
  };
const blockStyleFn = (block) => {
  let alignment = 'left';
  block.findStyleRanges((e) => {
    if (e.hasStyle('center')) {
      alignment = 'center';
    }
    if (e.hasStyle('right')) {
      alignment = 'right';
    }
  });
  return `editor-alignment-${alignment}`;
};

const requestSignature = () => {
  setIsSignReq(true)
}
const closeModal = () => {
  setIsSignReq(false)
}
const saveEditorData = () => {
  const contentState = editorState1.getCurrentContent();
const rawContentState = convertToRaw(contentState);
localStorage.setItem('editorState', JSON.stringify(rawContentState));
}
// useEffect(() => {
//   handleRetrieve()
// },[])
 // Retrieve editor state
 const handleRetrieve = () => {
  const rawContentState = JSON.parse(localStorage.getItem('editorState'));
  if (rawContentState) {
    const contentState = convertFromRaw(rawContentState);
    const data = (EditorState.createWithContent(contentState));
    setEditorState1(data)
  }
};
const IMAGETOOL = 'image';
  const Imagetool = ({ src }) => {
    const [, dragImgRef] = useDrag({
      type: IMAGETOOL,
      item: {
        src
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    });
    return (
      <li className="image" ref={dragImgRef}>
        <div 
          className='attribname' >
          <img src={src} className='mw-100'/>
        </div>
       
      </li>
    );
  }
  const [, dropImgRef] = useDrop({
    accept: IMAGETOOL,
    drop: (item) => { handleImageUpload(item.src)
       },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  })
const handleImageUpload = (item) => {
 
    const imageSrc = item;
    const contentState = editorState1.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'image',
      'IMMUTABLE',
      { src: imageSrc }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState1,
      { currentContent: contentStateWithEntity },
      'create-entity'
    );
    setEditorState1(
      AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        ' '
      )
    );
  
};

const mediaBlockRenderer = (block) => {
  if (block.getType() === 'atomic') {
    return {
      component: MediaBlock,
      editable: false,
    };
  }
 
  return null;
};

const MediaBlock = (props) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src, alignment } = entity.getData();
  const { height, width } = entity.getData().size || {};

  const style = {
     // float: 'right',
    // padding: '5px',
    // width: '35%',
    // height: 'auto',
    // textAlign: 'right'
  };
  // if (alignment === 'center') {
  //   style.margin = '0 auto';
  //   style.display = 'block';
  // }
  return (
    <div style={style}>
      <img src={src} />
    </div>
  );
};

  return (
    <Fragment>


      <div className='formscroldiv1 createfile'>
        <div className='container-fluid1'>
        <Modal show={isSignReq} onHide={closeModal} size="md" aria-labelledby="contained-modal-title-vcenter"
        centered backdrop="static">

        <Modal.Body>
        <span className='mcls' onClick={closeModal}>&times;</span>
          <div className="modal-body d-flex flex-column align-items-center">
            
            <div className="form-group1 text-center">
              <div className=' mb-5'><img src={require('../assets/images/logo.png')} loading="lazy" /></div>
              <h3 className='mb-3'>Draw a Signature</h3>
              <div className="canvas-container">                       
                        <SignatureCanvas
                          penColor='black'
                          ref={canvasRef}
                          canvasProps={{
                          width: 400,
                          height: 200,
                          className: 'canvas'
                          }}
                        />
                      </div>
                      <div className="canvas-buttons d-flex justify-content-between">                        
                        <button onClick={handleClear} type="button" className="btn btn-light btn-icon minw-auto">Clear</button>
                        <button onClick={handleSaveSign} type="button" className="btn btn-primary btn-icon minw-auto"><i data-eva-animation="flip" data-eva="save-outline"></i> Save </button>
                      </div>
            </div>
          </div>

        </Modal.Body>
       
      </Modal>
          {/* <ul className="nav nav-pills mb-3 justify-content-center" id="ex1" role="tablist">
          <li className="nav-item" role="presentation"><a className="nav-link active finished" id="ex1-tab-1" data-mdb-toggle="pill" href="#ex1-pills-1" role="tab" aria-controls="ex1-pills-1" aria-selected="true">Terms</a></li>
          <li className="nav-item btn-disabled" role="presentation"><a className="nav-link" id="ex1-tab-2" data-mdb-toggle="pill" href="#ex1-pills-2" role="tab" aria-controls="ex1-pills-2" aria-selected="false">Attributes</a></li>
          <li className="nav-item btn-disabled" role="presentation"><a className="nav-link" id="ex1-tab-3" data-mdb-toggle="pill" href="#ex1-pills-3" role="tab" aria-controls="ex1-pills-3" aria-selected="false">Signers</a></li>
        </ul> */}
          <div className='cs-mention'  >
            <div className='cs-meditor' onClick={focus}>
              <div className='tableblur fadein' ref={dropRef}>
                <div className='acboxs'  ref={dropImgRef}>
                  <div className='albox'>
                    <div className='altermsbox'>
                      <div className='altcont'>
                        <Editor
                          editorState={editorState1}
                          onChange={handleEditState}
                          plugins={plugins1}
                          placeholder="Create Document"
                          ref={editorRef}
                          autoFocus
                          handleKeyCommand={handleKeyCommand}
                          keyBindingFn={myKeyBindingFn}
                          blockStyleFn={blockStyleFn}
                          blockRendererFn={mediaBlockRenderer}
                          
                        />
                      </div>
                     
     
                    </div>
                  </div>
                </div>

                {/* {isOver && <div>Drop Here!</div>} */}
                
                <MentionSuggestions
                  open={open}
                  onOpenChange={onOpenChange}
                  suggestions={suggestions}
                  onSearchChange={onSearchChange}
                  onAddMention={() => {

                  }}
                  entryComponent={Entry}
                />
                 <SideToolbar>
                {
            (externalProps) => (
              <div>
                <HeadlineOneButton {...externalProps}/>
                <HeadlineTwoButton {...externalProps}/>
                <BoldButton {...externalProps}/>
                <ItalicButton {...externalProps}/>
                <AlignBlockCenterButton {...externalProps} setAlignment={() => applyAlignment('center')}/>
                <AlignBlockLeftButton {...externalProps}  setAlignment={() => applyAlignment('left')}/>
                <AlignBlockRightButton {...externalProps}  setAlignment={() => applyAlignment('right')}/>
                <UnderlineButton {...externalProps}/>
                <CodeButton {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
              </div>
            )
          }
                </SideToolbar>
                  
            
                
                {/* <Toolbar /> */}
              </div>
              {/* <div>
                    {mentions && mentions.map((items, index) => (
                      <Tool name={items.name} title={items.title} />
                    ))}
                  </div> */}

              {/* <div className={`drop-area ${canDrop ? 'highlight' : ''}`} ref={drop}>
                    Drag here
                  </div> */}
            </div>
            <div className={!isActive ? 'cs-mrhtsidebar show': 'cs-mrhtsidebar'}>
              <div className='cs-sharrow'  onClick={toggleClass}>
                  <span className={!isActive ? 'hide': 'show'}><i data-eva-animation="flip" data-eva="arrow-ios-back-outline"></i></span>
                  <span className={!isActive ? 'show': 'hide'}><i data-eva-animation="flip" data-eva="arrow-ios-forward-outline"></i></span>
              </div>
              <div className='tableblur fadein'>
                <div className='csm-select form-group'>
                  <select className='form-control w-100'>
                    <option>Current Document</option>
                    <option>Current Document 1</option>
                    <option>Current Document 2</option>
                    <option>Current Document 3</option>
                  </select>
                </div>
                <div className='cs-mrhtstab'>
                  <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className="nav-link active"
                        id="ex1-tab-1"
                        data-mdb-toggle="tab"
                        href="#ex1-tabs-1"
                        role="tab"
                        aria-controls="ex1-tabs-1"
                        aria-selected="true"
                      >Attributes</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className="nav-link"
                        id="ex2-tab-2"
                        data-mdb-toggle="tab"
                        href="#ex2-tabs-2"
                        role="tab"
                        aria-controls="ex2-tabs-2"
                        aria-selected="false"
                      >Signatures</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="ex3-tab-3"
                        data-mdb-toggle="tab"
                        href="#ex3-tabs-3"
                        role="tab"
                        aria-controls="ex3-tabs-3"
                        aria-selected="false"
                      >Terms</a>
                    </li>
                  </ul>

                  <div className="tab-content" id="ex1-content">
                    <div
                      className="tab-pane fade show active fadein"
                      id="ex1-tabs-1"
                      role="tabpanel"
                      aria-labelledby="ex1-tab-1"
                    >
                      <div className='form-group'>
                        <input type='text' className='form-control' placeholder='Search or Create Attribute' value={search} onChange={handleSearchChange} />
                      </div>
                      <div className='csms-attributes'>
                        <ul className='list-unstyled'>
                          {(suggestionFilter && suggestionFilter.length > 0) ? (suggestionFilter.map((items,index) => (
                            <Tool name={items.name} title={items.title} id={items.id} idx={index} icon={items.icon}/>))) : (isAllowedToCreate) ?
                            <div>
                              <li>
                                <div className='form-group'>
                                  <input type="text" className='form-control' value={editname} onChange={handleEditname} placeholder="Name" />
                                </div>
                                <div className='form-group'>
                                  <input type="text" className='form-control' value={editTitle} onChange={handleEditTitle} placeholder="Value" />
                                </div>
                                <div className='text-center'>
                                 <button className="btn btn-primary btn-icon" type='button' onClick={handleCreateAttribute}>< i data-eva-animation="flip" data-eva="plus-outline"></i> Create Attribute</button>
                                </div>
                              </li>
                             

                            </div>
                            : ''
                          }
                        </ul>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade fadein"
                      id="ex2-tabs-2"
                      role="tabpane2"
                      aria-labelledby="ex1-tab-2"
                    >
                       <div className='form-group text-center'>
                        <button className="btn btn-primary btn-icon mb-3" type="button" ><i data-eva-animation="flip" data-eva="paper-plane-outline"></i> Signature Request</button>
                        <button className="btn btn-primary btn-icon" type="button" onClick={requestSignature}><i data-eva-animation="flip" data-eva="plus-outline"></i> Create a Signature</button>

                      </div>
                      <div className='csms-attributes csms-attributessign'>
                        <ul className='list-unstyled'>
                          {(signatureSet && signatureSet.length > 0) ? (signatureSet.map((items,index) => (
                            <Imagetool src={items}/>)))
                           
                            : ''
                          }
                        </ul>
                      </div>
                     
                    </div>
                    <div className="tab-pane fade fadein" id="ex3-tabs-3" role="tabpane3" aria-labelledby="ex1-tab-3">
                      <div className='form-group'>
                      <input type='text' className='form-control' placeholder='Search or Create Term' value={search} onChange={handleSearchChange} />
                      </div>
                      <div className='csms-attributes'>
                        <ul className='list-unstyled'>
                        {(suggestionFilter && suggestionFilter.length > 0) ? (suggestionFilter.map((items,index) => (
                            <Tool name={items.name} title={items.title} id={items.id} icon={items.icon} idx={index}/> ))) : (isAllowedToCreate) ?
                            <div>
                              <li>
                                <div className='form-group'>
                                  <input type="text" className='form-control' value={editname} onChange={handleEditname} placeholder="Name" />
                                </div>
                                <div className='form-group'>
                                  <input type="text" className='form-control' value={editTitle} onChange={handleEditTitle} placeholder="Value" />
                                </div>
                                <div className='text-center'>
                                 <button className="btn btn-primary btn-icon" type='button' onClick={handleCreateAttribute}>< i data-eva-animation="flip" data-eva="plus-outline"></i> Create Attribute</button>
                                </div>
                              </li>
                             

                            </div>
                            : ''
                          }
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>





        </div>
      </div>
      {/* <div className='row align-items-center'>
        <div className='col-12 text-end'>
          <div className='btngrouprht'>
            <button type="button" className='btn btn-primary btn-icon icon-rht' onClick={saveEditorData}>Save</button>
          </div>
        </div>
      </div> */}
    </Fragment>

  );
}

export default Agreement_Contract;