import { orgRoles, permissions } from "components/shared/Roles";

export const getUserAddress = async () => {

    let useraddress = ""
 
    if (window.sessionStorage.getItem("CURRENT_USER"))
       useraddress = JSON.parse(window.sessionStorage.getItem("CURRENT_USER"))["addr"];
 
    return useraddress
 
 }
export const checkemail = (value) => {
   var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   if (emailReg.test(value)) {
       return true
   } else {
       return false
   }
}

export const checkWithSpace = (value) => {
    const regex = /^\S.*[a-zA-Z\s]*$/g
    if(regex.test(value)){
        return true
    }else{
        return false
    }
}
export const isUserRoleEnabled = (currentRole) => {
    const organisationRoles = [orgRoles.member,orgRoles.signer, orgRoles.owner]
    const isOrgRole = organisationRoles.some(e => e === currentRole)
    if(!isOrgRole){
        return true
    }else{
        return false
    }
    
}

export const canAccessMenu = (services,accessedService) => {
    let canAccess;
    const accessService = services.find(s => s.name === accessedService);
    const permissionsArr=[permissions.READ, permissions.WRITE]
    if(accessService && accessService.serviceid){
        canAccess =  permissionsArr && permissionsArr.some(element => {
          return accessService[element] === true
         });
       }
    if(canAccess){
        return true
    }else{
        return false
    }
}

export const canAccessMenuWithRole = (role) => {
    if(role){
        if(role === orgRoles[role]){
            return true
        }else{
            return false
        }
    }else{
        return false
    }
    
}

export const hasRead = (services,servicename) => {
    const canAccess = services.find(s => s.name === servicename);
    if(canAccess.canRead){
        return true
    }else{
        return false
    }

}
export const hasWrite = (services,servicename) => {
    if(services && services.length > 0){
        const canAccess = services.find(s => s.name === servicename);
        if(canAccess.canWrite){
            return true
        }else{
            return false
        }
    }else{
        return false
    }
    

}
export const hasDelete = (services,servicename) => {
    const canAccess = services.find(s => s.name === servicename);
    if(canAccess.canDelete){
        return true
    }else{
        return false
    }

}
 export const getuserName = (user) => {
    if(user && user.name){
        return user.name
    }else{
        return ''
    }
 }
 export const getUID = (user) => {
    if(user && user.uid){
        return user.uid
    }else{
        return ''
    }
 }
 export const getPhoneNumber = (user) => {
    if(user && user.phone){
        return user.phone
    }else{
        return ''
    }
 }

 export const isOrgRoleEnabled = (currentRole) => { 
    if(currentRole === orgRoles[currentRole]){
        return true
    }else{
        return false
    }
    
}