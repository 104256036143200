import { Fragment, React, useState, useEffect } from 'react';
import * as eva from 'eva-icons';
import { useNavigate } from 'react-router-dom';


const Members = () => {
    const navigate = useNavigate()
    useEffect(() => { eva.replace() });

    return (
        <Fragment>
              
            <div className=''>

                <div className='formscroldiv'>
                    {/* <button type='button' className='btn btn-primary btn-icon' onClick={gotoInviteMembers}>< i data-eva-animation="flip" data-eva="paper-plane-outline"></i> Invite</button> */}

                    <div className='tableblur mt-3'>

                        <div className='table-responsive'>
                            <table className="table align-middle mb-0 custable table-hover">
                                <thead className="">
                                    <tr>

                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Invited on</th>
                                        <th>Invited by</th>

                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                Kart
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                kart@blaze.ws
                                            </div>
                                        </td>
                                        <td>
                                            13-07-2022  10:00 AM
                                        </td>
                                        <td>
                                            <p className="fw-normal mb-1"><span className="text-dark">Anderson</span></p>
                                        </td>

                                        <td className='text-center'>
                                            <div className='btngrouprht'>
                                                <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="View" >< i data-eva-animation="flip" data-eva="eye-outline"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                John
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                john@blaze.ws
                                            </div>
                                        </td>
                                        <td>
                                            13-07-2022  10:00 AM
                                        </td>
                                        <td>
                                            <p className="fw-normal mb-1"><span className="text-dark">Anderson</span></p>
                                        </td>


                                        <td className='text-center'>
                                            <div className='btngrouprht'>
                                                <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="View" >< i data-eva-animation="flip" data-eva="eye-outline"></i></a>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                Maria
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                maria@blaze.ws
                                            </div>
                                        </td>
                                        <td>
                                            13-07-2022  10:00 AM
                                        </td>
                                        <td>
                                            <p className="fw-normal mb-1"><span className="text-dark">Anderson</span></p>
                                        </td>


                                        <td className='text-center'>
                                            <div className='btngrouprht'>
                                                <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="View" >< i data-eva-animation="flip" data-eva="eye-outline"></i></a>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                Anderson
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                anderson@blaze.ws
                                            </div>
                                        </td>
                                        <td>
                                            13-07-2022  10:00 AM
                                        </td>
                                        <td>
                                            <p className="fw-normal mb-1"><span className="text-dark">Anderson</span></p>
                                        </td>


                                        <td className='text-center'>
                                            <div className='btngrouprht'>
                                                <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="View" >< i data-eva-animation="flip" data-eva="eye-outline"></i></a>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                Paim
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                paim@blaze.ws
                                            </div>
                                        </td>
                                        <td>
                                            13-07-2022  10:00 AM
                                        </td>
                                        <td>
                                            <p className="fw-normal mb-1"><span className="text-dark">Anderson</span></p>
                                        </td>


                                        <td className='text-center'>
                                            <div className='btngrouprht'>
                                                <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="View" >< i data-eva-animation="flip" data-eva="eye-outline"></i></a>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>


    );
}

export default Members;