import { commonError, commonSuccess, resetUpdateProfile, resetUpdateProfileFailed, updateProfile } from 'actions/exampleAction';
import { getAuth } from 'firebase/auth';
import { getUserEmail, getUserId, getUserPhone, getUsername, isEmailVerified } from 'helpers/authData';
import { Fragment, React, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkemail, getPhoneNumber, getuserName } from 'utils/utils';
import { CertifiButton } from './shared/CertifiButton';
import { resendVerificationEmail, resetResendVerificationEmail, resetResendVerificationEmailFailed } from 'actions/authuserAction';
import FullLoader from './shared/FullLoader';

const Profile = () => {

  const [email,setEmail] = useState('')
  const [fname,setFname] = useState('')
  const [cnumber,setCnumber] = useState('')
  const [isLoading,setIsLoading] = useState(false)
  const [isValidPhnNo,setIsValidPhnNo] = useState(false)
  const [submitted,setSubmitted] = useState(false)
  const [isFullLoading,setIsFullLoading] = useState(false)
  const [emailVerified,setIsEmailVerified] = useState(isEmailVerified())
  const profiledata = useSelector(state => state.demoReducer.updatedProfile);
  const profiledatafailed = useSelector(state => state.demoReducer.updatedProfileFailed);
  const userProfileWithWallet = useSelector(state => state.demoReducer.userProfile);

  const resentEmail = useSelector(state => state.authUserReducer.resendEmailSuccess);
  const resentEmailFailed = useSelector(state => state.authUserReducer.resendEmailFailed);

  let userDetail;
  if(userProfileWithWallet && userProfileWithWallet.data && userProfileWithWallet.data.name){
    userDetail = userProfileWithWallet.data
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    let interval;
    setEmail(getUserEmail())
    if(emailVerified === false){
      interval = setInterval(() => {
        setIsEmailVerified(isEmailVerified())
      },5000)
    }
    return () => {
      clearInterval(interval)
    }
  },[])

  useEffect(() => {
    setFname(getuserName(userDetail))
    setCnumber(getPhoneNumber(userDetail))
  },[userProfileWithWallet])
  const isNumbervalid = (value,country) => {
    if (value.match(/12345/)) {
      setIsValidPhnNo(false)
      return 'Invalid value: '+value+', '+country.name;
    } else if (value.match(/1234/)) {
      setIsValidPhnNo(false)
       return false
    } else {
      setIsValidPhnNo(true)
      return true;
    }
  }
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handleName = (e) => {
    setFname(e.target.value)
  }
  const submitProfile = (e) => {
    e.preventDefault()
    setSubmitted(true)
    const formData = {email,fname,cnumber}
    if(!formData.fname ){
      return;
    }else{
          let data = {
            name : formData.fname,
          }
          if(cnumber !== ''){
            if(isValidPhnNo){
              if(cnumber && cnumber.charAt(0) === '+'){
                data['phone'] = `${formData.cnumber}`
              }else{
                data['phone'] = `+${formData.cnumber}`
              }
              setIsLoading(true)
              dispatch(updateProfile(data))
            }
          }else{
            setIsLoading(true)
            dispatch(updateProfile(data))
          }
          
      
    }
  }
  useEffect(() => {
    if(profiledata && profiledata.statusCode === 200){
      setIsLoading(false)
      dispatch(commonSuccess("Profile updated"))
      // navigate('/user-dashboard')
    }
  },[profiledata])
  useEffect(() => {
    if(profiledatafailed && profiledatafailed.statusCode){
      setIsLoading(false)
      if(profiledatafailed.statusCode === 403){
        navigate('/login')
      }else if(profiledatafailed && profiledatafailed.data && profiledatafailed.data[0] && profiledatafailed.data[0][0] && typeof profiledatafailed.data[0][0] === 'string'){
        if(profiledatafailed.data[0][0].toLowerCase().includes('phone must be a valid')){
          dispatch(commonError('Invalid contact number'))
        }else{
          dispatch(commonError(profiledatafailed.data[0][0]))
        }
      }
      else if(profiledatafailed && profiledatafailed.message){
        dispatch(commonError(profiledatafailed.message))
      }
    }
  },[profiledatafailed])
 
  const handleResendVerification = () => {
    const uid = getUserId()
    setIsFullLoading(true)
    dispatch(resendVerificationEmail(uid))
  }

  useEffect(() => {
    if(resentEmailFailed && resentEmailFailed.statusCode){
      setIsFullLoading(false)
      if(resentEmailFailed.statusCode === 401){
        navigate('/login')
      }else if(resentEmailFailed.statusCode === 403){
        dispatch(commonError('Access denied'))
      }
      else if(resentEmailFailed && resentEmailFailed.data && resentEmailFailed.data[0] && resentEmailFailed.data[0][0] && typeof resentEmailFailed.data[0][0] === 'string'){
        
          dispatch(commonError(resentEmailFailed.data[0][0]))
      }
      else if(resentEmailFailed && resentEmailFailed.message){
        dispatch(commonError(resentEmailFailed.message))
      }
      dispatch(resetResendVerificationEmailFailed())
    }
  },[resentEmailFailed])
  useEffect(() => {
    if(resentEmail && resentEmail.statusCode === 200){
      setIsFullLoading(false)
      dispatch(resetResendVerificationEmail())
      dispatch(commonSuccess("Verification link sent"))
      // navigate('/user-dashboard')
    }
  },[resentEmail])
  return (
    <Fragment>                  
         <div className='scrolldiv1'>
      <div className='row '>
        <div className='col-md-12 text-start'>
        {isFullLoading ? <FullLoader /> : ''}
          <div className=''>
                    
            <div className='backgroundblur'>
              <div className='searchform border-none d-block'>
                <form >
                  <div className='formscroldiv1 px-3'>
                   
                    <h6 className='mb-3 fw-bold'>Profile Details</h6>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='mb-2'>Email *</label>

                          <div class="input-group mb-0 inputbtngroup">
                            <input 
                            name="email" 
                            type={'text'} 
                            value={email}  
                            className="form-control" 
                            placeholder='Email'
                            onChange={handleEmail}
                            disabled
                            style={{cursor:'not-allowed'}}
                          />
                            {emailVerified ? <button type="button" class="btn btn-primary btn-icon verify" data-tooltip="Verified">
                            <i data-eva="checkmark-outline" className='m-0'></i>
                            </button> :''}
                            </div>
                          
                          
                          {emailVerified ? '' : <div className='text-end mt-2'>
                          <span className='cursor-pointer text-right text-link text-sm' onClick={handleResendVerification}>Resend verification link</span>
                          </div>}

                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='mb-2'>Name *</label>
                          <input 
                            name="name" 
                            type={'text'}  
                            value={fname} 
                            className="form-control" 
                            placeholder='Name' 
                            onChange={handleName}
                            
                          />
                          {submitted && !fname && <div className='mandatory'>Required</div>}
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='mb-2'>Contact Number</label>
                            <PhoneInput
                              value={cnumber}
                              onChange={phone => setCnumber(phone)}
                              placeholder="Contact Number"
                              inputClass="form-control"
                              isValid={(value, country) => isNumbervalid(value, country)}
                              country={'us'}
                            />          
                        </div>
                      </div>
                     
                    </div>
                 
                  </div>                  
                  <hr className='light-brd'/>
                  <div className='row align-items-center'>                  
                    <div className='col-12 text-center'>
                      <div className='btngrouprht'>
                      {isLoading ?
                    <button
                      type="button"
                      className="btn btn-light btn-icon btn-disabled bg-white filter-none"
                    >

                      <img
                        src={require('../assets/images/certifi-loader.gif')}
                        loading="lazy"
                        alt="Loading..." width={28}
                      />
                    </button>
                    :
                    <CertifiButton type="submit" className='btn btn-primary btn-icon' onClick={submitProfile} name={'Save'} image="save-outline.png"/>
                    }
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Fragment>


  );
}

export default Profile;