import { React, useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap"

const FailureModal = (props) => {
    const [isOpen, setIsOpen] = useState(true)
    const [message, setMessage] = useState('')
    const [isLong,setIsLong] = useState(false)

    useEffect(() => {
        if(props && props.error && props.error.statusCode === 403){
            if(props.error.message.toLowerCase().includes(`please complete the organisation verification`)){
                setIsLong(true)
              }else{
                setMessage('Access denied')
                setIsLong(false)
              }
        }else{
            if(props.error && props.error.message && typeof props.error.message === 'string'){
                setMessage(props.error.message)
                setIsLong(false)

            }else if(props.error && props.error.data && props.error.data[0] && props.error.data[0][0] && typeof props.error.data[0][0] === 'string'){
                setMessage(props.error.data[0][0])
                setIsLong(false)

            }else{
                setMessage("Mint Failed")
                setIsLong(false)

            }
        }
    },[props])
    const closeModal = () => {
        setIsOpen(false)
        props.closemodal()
    }
    return (
        <Modal show={isOpen} onHide={(e) => closeModal(e, this)} size="lg" aria-labelledby="contained-modal-title-vcenter"
            centered backdrop="static">

            <Modal.Body>
            <span className='mcls' onClick={closeModal}>&times;</span>
                <div className="modal-body d-flex flex-column align-items-center">
                {isLong ? <div className="form-group text-center checkcross">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#F96262" strokeWidth="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <line className="path line" fill="none" stroke="#F96262" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                            <line className="path line" fill="none" stroke="#F96262" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                        </svg>
                        <h4 className='mt-3'>Hello there!</h4>
                        <p> You are almost done with the process of minting your certificate.</p>
                        <p> However, before proceeding, please make sure to verify your institution.</p>
                        <p> Once you have completed this step, you can proceed to mint your certificate and distribute it to your desired receivers.</p>
                        <h6> Thank you!</h6>
                    </div> : 
                    <div className="form-group text-center checkcross">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#F96262" strokeWidth="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <line className="path line" fill="none" stroke="#F96262" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                            <line className="path line" fill="none" stroke="#F96262" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                        </svg>
                        <h4 className='mt-3 popcontent'>{message}</h4>
                    </div>}

                </div>

            </Modal.Body>
            {/* <Modal.Footer className='bg-light justify-content-center'>
                <Button variant="outline-secondary" onClick={closeModal}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default FailureModal;