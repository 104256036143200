import { Fragment, React, useEffect, useState } from 'react';
import * as eva from 'eva-icons';
import { useDispatch } from 'react-redux';
import { getUserList, isMintCertificateDocTypeClicked, resetMintDocsType } from '../actions/exampleAction';
import { useNavigate } from 'react-router-dom';
import { canAccessMenu, hasWrite } from '../utils/utils';
import { NavLink } from 'react-router-dom';
import { getOrgId, getSelectedUserOid } from '../helpers/authData';
import { certRoles, services } from './shared/Roles';
import { CertifiCreateBox } from './shared/CertifiCreateBox';

const CreateUI = (props) => {

  const [servicesArray,setServicesArray] = useState([])
  const dispatch = useDispatch();
  let orgID = getOrgId();
  let oid = getSelectedUserOid()

  const navigate = useNavigate();
  useEffect(() => { eva.replace() })

  const handleSelectMinType = () => {
    navigate(`/institution/${oid}/mint-certificate`)
  }
  const handleSelectDocumentType = () => {
    if(props.role === certRoles.org){
      navigate(`/institution/${oid}/documentTypes`)
      // navigate(`/institution/${oid}/document/create`)
    }else{
      navigate(`/documentTypes`)
      // navigate("/document/create")
    }
  }
  const handleSelectFileType = () => {
    if(props.role === certRoles.org){
      navigate(`/institution/${oid}/create/files`)
    }else{
      navigate("/create/files")
    }
  }
  useEffect(() => {
    dispatch(resetMintDocsType());
  }, []);


  const gotoMintCert = () => {
    navigate('/mint-certificate')
  }
  useEffect(() => {
    console.log(props)
    if(props && props.services){
      setServicesArray(props.services)
    }
  },[props])
  return (
    
        <div className='certtemplates mintnft'>
        <div className='row'>
            {hasWrite(servicesArray,services.CERTIFICATE) ? 
            <CertifiCreateBox 
            image="Certifily-icon.png" heading1="Create a" heading2="Certificate" onClick={handleSelectMinType} id={"certselect-1"}/>
            : ''}
            
            <CertifiCreateBox 
            image="documents.png" heading1="Create a" heading2="Document" onClick={handleSelectDocumentType} id={"certselect-2"}/>
            
            {hasWrite(servicesArray,services.MEMBERSHIP) ? 
            <CertifiCreateBox 
            image="Group-16.png" heading1="Create a" heading2="Membership" id={"certselect-3"}/>
            : ''
            }

            <CertifiCreateBox 
            image="send-file.png" heading1="Send a" heading2="File" onClick={handleSelectFileType} id={"certselect-4"}/>

            {hasWrite(servicesArray,services.CARD) ? 
            <CertifiCreateBox 
            image="Group-16-2.png" heading1="Create a" heading2="Card" id={"certselect-5"}/>
            : ''
            }
            {hasWrite(servicesArray,services.TICKET) ? 
            <CertifiCreateBox 
            image="Group-16-3.png" heading1="Create a" heading2="Ticket" id={"certselect-6"}/>
            : ''
            }

            {/* <div className="col-6 col-md-4 ">
                  <div className='ctemp dis-bfore' >
                    <input type={'radio'} id="certselect-4" name='cerselect' />
                    <label className='backgroundblur' htmlFor="certselect-4">
                      <div className='img'>
                        <img src={require('../assets/images/icons/Group-16-1.png')} loading="lazy" />
                      </div>
                      <h4>Create a</h4>
                      <h3>Letter</h3>
                    </label>
                  </div>
                </div> */}



          </div>

        </div> 


  );
}

export default CreateUI;