import { React, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, NavLink } from "react-router-dom";
import { authpostSignIn, getAuthUserDetail, resetauthPostSignIn, resetauthPostSignInFailed, resetAuthUserDetail, resetAuthUserDetailFailed } from '../actions/authuserAction';
import { commonError, firebaseUserUpdate, getUserProfile, isEmailVerifiedCheck, resetUserProfile, resetUserProfileFailed } from '../actions/exampleAction';

import { logoutUser } from '../helpers/authData';
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logInInfo = useSelector(state => state.authUserReducer.signinInfo);
  const userProfile = useSelector(state => state.authUserReducer.fetchuserDetail);
  const logInResponseFailed = useSelector(state => state.authUserReducer.signinFailed);
  //-----------------------------------------------//
  const userProfileWithWallet = useSelector(state => state.demoReducer.userProfile);
  const userProfilefailed = useSelector(state => state.demoReducer.userProfileFailed);


  const [isLoading, setIsLoading] = useState(false)
  const [erroMessage, setErroMessage] = useState("");
  const [isAuthCalled, setIsAuthCalled] = useState(false)
  const [isProfileCalled, setIsProfileCalled] = useState(false)
  const errorRef = useRef(null);
  useEffect(() => {
    if (logInInfo && logInInfo.statusCode == 200 && logInInfo.data._tokenResponse.idToken) {

      dispatch(resetauthPostSignIn())
      setIsAuthCalled(true)
    }
  }, [logInInfo]);

  useEffect(() => {
    if(isAuthCalled){
      dispatch(getAuthUserDetail())
    }
  },[isAuthCalled])
  useEffect(() => {
    if (userProfile && userProfile.statusCode == 200 && isAuthCalled) {
      
      dispatch(resetAuthUserDetail())
      setIsAuthCalled(false)
      if(userProfile && userProfile.data && userProfile.data !== undefined){
        localStorage.setItem('userprofile', JSON.stringify(userProfile.data));
        setIsProfileCalled(true)
        dispatch(getUserProfile())
        dispatch(firebaseUserUpdate(userProfile.data))
        // navigate("/");
      }else{
        dispatch(commonError("Something went wrong. Please try again"))
        logoutUser()
      }
      
    }
  }, [userProfile]);
  useEffect(() => {
    if (userProfileWithWallet && userProfileWithWallet.statusCode == 200 && isProfileCalled) {  
      // dispatch(resetUserProfile())
      setIsLoading(false)
      setIsProfileCalled(false)
      if(userProfileWithWallet && userProfileWithWallet.data && userProfileWithWallet.data !== undefined){
        localStorage.setItem('walletProfile', JSON.stringify(userProfileWithWallet.data));
        sessionStorage.setItem('services',JSON.stringify(userProfileWithWallet.data.services))
        // dispatch(isEmailVerifiedCheck())
        if(userProfileWithWallet.data.admin && userProfileWithWallet.data.admin == true){
          navigate("/admin/dashboard");
        }else{
          navigate("/dashboard");
        }
      }
      
    }
  }, [userProfileWithWallet]);
  
  useEffect(() => {
    if (userProfilefailed && userProfilefailed.statusCode) {  
      dispatch(resetUserProfileFailed())
      setIsProfileCalled(false)
     
    }
  }, [userProfilefailed]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value.toLowerCase());
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = (event) => {
    setErroMessage("");
    event.preventDefault();
    setIsLoading(true)
    const formData = { email, password };
    if (!formData.email || !formData.password) {
      dispatch(commonError("Please fill out all below fields"))
      setIsLoading(false)
      return;
    }
    let data = {
      "email": formData.email,
      "password": formData.password
    }
    
    dispatch(authpostSignIn(email, password))
    // dispatch(postSignIn(data));
  };

  useEffect(() => {
    if (logInResponseFailed 
      && logInResponseFailed.code
      && typeof logInResponseFailed.code === 'string') {
        setIsLoading(false)
        if ((logInResponseFailed.code === 'auth/wrong-password') || (logInResponseFailed.code === 'auth/user-not-found')) {
          dispatch(commonError('Please check your credentials'))
        }else if (logInResponseFailed.code === 'auth/invalid-email') {
          dispatch(commonError('Invalid email'))
        }else{
          dispatch(commonError(logInResponseFailed.code))
        }
      dispatch(resetauthPostSignInFailed())
    }
  }, [logInResponseFailed]);
  
 
  useEffect(() => {
    if(erroMessage && erroMessage !== ''){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
    }
  },[erroMessage])
  const gotoHome = () => {
    navigate('/')
  }
  return (
    <div className='logouter'>
      {/* <div className="alert alert-success cf-alert text-center py-3 fade show fadein alert-top" role="alert"><b>Secret key</b> copied to clipboard!</div>
      <div className="alert alert-danger cf-alert text-center py-3 fade show fadein alert-top" role="alert"><b>Secret key</b> copied to clipboard!</div> */}
    <div className='logincon'>
      <div className='loginimg'>
        <img src={require('../assets/images/login-bg.jpg')} alt="" loading="lazy" />
        <div className='logtxtbox'>
          <h1>Professional documents on <span className='dartxtclr'>blockchain</span></h1>
        </div>
        </div>
      <div className='loginform fadein'>
      {/* {erroMessage && <div className="slideDown" ref={errorRef}>
      <div className="alert alert-danger text-center py-3 fade show fadein alert-top" role="alert">
                        {erroMessage}
                      </div>
        </div>} */}
        <div className="main-content" >
          <div className="main-logo main-logo--cert">
            <a onClick={gotoHome} className='cursor-pointer'>
              <img src={require('../assets/images/logo.png')} alt="certifily" loading="lazy" />
            </a>
          </div>
          <div id="log-in" className="base-layout">
            <h5 className="title">Hey, hello <span>&#128075;</span></h5>
            <p className='lighttxtclr mb-5'>Enter the information you entered while registering.</p>
            <div className="remember-user">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor='email'>Email</label>
                  <input id='email' type="text" name="email" value={email} onChange={handleInputChange} maxLength="80" minLength="6" className='form-control' placeholder='Email address'/>
                </div>
                <div className="form-group">
                  <label htmlFor='password'>Password</label>
                  <input id='password' type="password" name="password" value={password} onChange={handleInputChange} maxLength="80" minLength="6" className='form-control'placeholder='Password'/>
                </div>
                {/* <div className="form-group">
                  <div className='row align-items-center'>
                    <div className='col-6'>
                    <div className="form-group cbwtxt"><input type="checkbox" className="form-check-input" id="rememberme" /><label className="form-check-label" htmlFor="rememberme"> Remember me</label>  </div>
                   
                    </div>
                    <div className='col-6 text-end'>
                    <NavLink to="/Forgot-Password">Forgot password?</NavLink>
                    </div>
                  </div>
                </div> */}
                <div className="form-group text-center loginbtn">

                  {isLoading ?
                    <button
                      type="button"
                      className="btn btn-light btn-icon btn-disabled bg-white filter-none"
                    >

                      <img
                        src={require('../assets/images/certifi-loader.gif')}
                        loading="lazy"
                        alt="Loading..." width={28}
                      />
                    </button>
                    :
                    <button className="btn btn-primary text-uppercase" type="submit">Log in
                    </button>}

                </div>
                <div className='form-group text-center mt-3'>
                    Forgot password? <NavLink to="/forgot-password" className="text-underline">Reset here</NavLink>
                  </div>
                <div className='form-group'>
                  <div className='splitortxt'>
                    <span>or</span>
                  </div>
                </div>
                <div className='form-group '>
                <NavLink to="/signup" className="btn btn-light w-100 text-uppercase">Create an account</NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Login;
