import { Fragment, React, useEffect } from 'react';
import * as eva from 'eva-icons';


const BillingPayments = () => {
    useEffect(() => { eva.replace() });

    return (
        <Fragment>
            <div className='issuecerttabs'>
                <div class="d-none">
                    <h4 class="fw-bolder text-black text-uppercase mb-2">Payment</h4>
                    <h6 class="mb-0">Update your payment information or change your plans acording to your needs.</h6>
                </div>
                <div className='tableblur mt-5 fadein'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <h6 class="fw-bolder text-black text-uppercase mb-3">My Balance</h6>
                            {/* <p>Change your plans acording to your needs.</p> */}
                            <div className='cs-plan cs-plancard'>
                                <div className='carddetail'>
                                    <div className='csplan-name'>1547897 <span className='smallflow'>Flow</span></div>
                                    <div className='csplan-price'><span className='text-secondary m-0'>Available balance</span></div>
                                </div>
                                <div className='changecard w-auto'>
                                    <div className='btngrouprht1'>
                                        <button className='btn btn-primary'>Deposit Flow</button></div>
                                </div>
                            </div>

                        </div>
                        <div className='col-md-6'>
                            <h6 class="fw-bolder text-black text-uppercase mb-3">Quick Deposit</h6>
                            <div className='cs-plan'>
                                <div className='btngrouprht'>
                                    <button className='btn btn-light'>100 Flow</button>
                                    <button className='btn btn-light'>200 Flow</button>
                                    <button className='btn btn-light'>300 Flow</button>
                                    <button className='btn btn-light'>400 Flow</button>
                                    <button className='btn btn-light'>500 Flow</button>
                                    <button className='btn btn-light'>Cutomize Deposit</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div className='tableblur mt-5'>
                    <div class="row">
                       

                        <div className='col-md-6'>
                            <h6 class="fw-bolder text-black text-uppercase mb-0">Payment method</h6>
                            <p>Change how you pay your plan</p>
                            <div className='cs-plan cs-plancard'>
                                <div className='cardtype'></div>
                                <div className='carddetail'>
                                    <div className='csplan-name'>customer@nija.com</div>
                                    <div className='csplan-price'>Expiry 08/2028</div>
                                </div>
                                <div className='changecard'>
                                    <div className='btngrouprht'>
                                        <button className='btn btn-light'>Change</button></div>
                                </div>
                            </div>
                            <div class="row">
                                <div className='col-md-12'>
                                    <span className='text-secondary small'>Notes: Please be careful on choosing our payment method, because we will automatically cut your balance.</span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div> */}
                <div className='tableblur fadein mt-5'>
                    <h6 class="fw-bolder text-black text-uppercase  mb-3">Transaction History</h6>
                    <div className='table-scroller'>
                        <div className='table-responsive'>
                            <table className="table align-middle mb-0 custable table-hover" >
                                <thead className="">
                                    <tr>
                                        <th>Date</th>
                                        <th>Detail</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>8-June-2023</td>
                                        <td>Deposited</td>
                                        <td>30 Flow</td>
                                        <td><span className='badge badge-primary text-uppercase'>Credited</span></td>
                                    </tr>
                                    <tr>
                                        <td>8-June-2023</td>
                                        <td>Deposited</td>
                                        <td>30 Flow</td>
                                        <td><span className='badge badge-primary text-uppercase'>Credited</span></td>
                                    </tr>
                                    <tr>
                                        <td>7-June-2023</td>
                                        <td>Certificate Minted</td>
                                        <td>30 Flow</td>
                                        <td><span className='badge badge-danger text-uppercase'>Debit</span></td>
                                    </tr>

                                    <tr>
                                        <td>6-June-2023</td>
                                        <td>Document Minted</td>
                                        <td>30 Flow</td>
                                        <td><span className='badge badge-danger text-uppercase'>Debit</span></td>
                                    </tr>
                                    <tr>
                                        <td>5-June-2023</td>
                                        <td>Deposited</td>
                                        <td>130 Flow</td>
                                        <td><span className='badge badge-primary text-uppercase'>Credited</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


            </div>


        </Fragment>


    );
}

export default BillingPayments;