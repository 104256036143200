import { React, useState, useEffect, useMyCustomStuff } from 'react';

const ClaimFlowAccount = () => {
  return (
    <div className='scrolldiv1'>
      <p>Claim flow account page</p>
    </div>
  );
}

export default ClaimFlowAccount;