import axios from 'axios';
import { configPaths } from '../config';
import { getOrgId } from '../helpers/authData';
import { ErrorResponseSetup } from './errorResponseSetup';
import { setup } from './setup';

const apiTokenOrgid = axios.create({
  baseURL: configPaths.apiPath,
  headers: {
    'Content-Type': `application/json`,
  },
});
export const apiTokenWithBlob = axios.create({
  baseURL: configPaths.apiPath,
  headers: {
    'Content-Type': `application/json`,
  },
   responseType: 'blob' ,
});

apiTokenOrgid.interceptors.request.use((config) => setup(config,true),error => { Promise.reject(error)});
apiTokenOrgid.interceptors.response.use(
  (res) => {
    return res;
  },ErrorResponseSetup
  
);

apiTokenWithBlob.interceptors.request.use((config) => setup(config,true),error => { Promise.reject(error)});
apiTokenWithBlob.interceptors.response.use(
  (res) => {
    return res;
  },ErrorResponseSetup
  
);
export default apiTokenOrgid;