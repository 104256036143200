import { isEmailVerifiedCheck } from "actions/exampleAction"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAccessToken, isEmailVerified } from "helpers/authData"
import { updateFirebaseAuth, updateLocalStorageFirebaseUser } from "helpers/updateFirebaseAuth";
import { getUserDetail } from "helpers/updateFirebaseAuth";
import { useDispatch } from "react-redux"
import { NavLink } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

function CertifiCreateBox(props){
    const dispatch = useDispatch()
    const onclickHandler = async (e) => {

      if(isEmailVerified()){
        if(props.onClick){
          props.onClick(e)
      }
    }else{
      e.preventDefault()
      const user = await getUserDetail() 
        if(user && user.claims){
          if(user.claims.email_verified){
            updateLocalStorageFirebaseUser(user)
              if(props.onClick){
                props.onClick(e)
              }
            
          }else{
            updateLocalStorageFirebaseUser(user)
              if(props.onClick){
                dispatch(isEmailVerifiedCheck())
              }
            
          }
        }
    }    
    }
    return (
        <div className="col-6 col-md-4 cursor-pointer" >
              <span onClick={onclickHandler} >
                <div className='ctemp dis-bfore' >
                  <input type={'radio'} id={props.id} name='cerselect' />
                  <label className='backgroundblur' htmlFor={props.id}>
                    <div className='img'>
                      <img src={require(`../../assets/images/icons/${props.image}`)} loading="lazy" />
                    </div>
                    <h4>{props.heading1}</h4>
                    <h3>{props.heading2}</h3>
                  </label>
                </div>
              </span>
            </div>
 

    )
}

export {CertifiCreateBox}