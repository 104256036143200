import { Fragment, React, useEffect, useState } from 'react';
import * as eva from 'eva-icons';
import { useNavigate } from 'react-router-dom';
import { certRoles } from './shared/Roles';
import { getSelectedUserOid } from 'helpers/authData';

const DocumentTemplates = (props) => {
    useEffect(() => { eva.replace() });
    const navigate = useNavigate()
    const oid = getSelectedUserOid()
    const gotoCreateDocument = () => {
        if(props.role === certRoles.org){
          navigate(`/institution/${oid}/document/create`)
        }else{
          navigate("/document/create")
        }
      }
  return (
    
         
    <Fragment>
      <div className='formscroldiv1'>
        <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-3' onClick={gotoCreateDocument}>
                <div className='cly-selectD '>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <i data-eva-animation="flip" data-eva="plus-outline"></i>
                  </div>
                  <div className='cly-docont'>
                    New blank document
                  </div>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Generalnotes.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    General Notes
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Bigwaveletterhead.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Big wave letterhead
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Businessprojectscopereport.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Business project scope report
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Businessservicesproposal.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Business services proposal
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/BusinessPlan.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Business plan
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Report.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Report
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Earthtonesletterhead.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                  Earth tones letterhead
                  </div>
                  </div>
                </div>
              </div>
              
            </div>
        </div>
      </div>
    </Fragment>

  );
}

export default DocumentTemplates;