import { React, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { getAllMintedCertificate, getAllMintedOrgCertificate, getUserList, resetGetAllMintedCertFailed, resetGetAllMintedCertSuccess, resetGetAllMintedOrgCertFailed, resetGetAllMintedOrgCertSuccess, resetUserlist, resetUserlistfailed } from '../actions/exampleAction';
import { useDispatch, useSelector } from 'react-redux';
import TableLoader from './shared/TableLoader';
import * as eva from 'eva-icons';
import {getSelectedUserOid, getUserId, logoutUser} from '../helpers/authData'
const FetchOrganisationCertificates = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const certificates = useSelector(state => state.demoReducer.getAllOrgCertificates);
  const certificatesFailed = useSelector(state => state.demoReducer.getAllOrgCertificatesFailed);

  const [certificatesList, setCertificateList] = useState([]);

  const [fetched, setFetched] = useState(false)
  const oid = getSelectedUserOid()

  function formatDate(date){
    const formatter = Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
  });
  const issDate = formatter.format(date).split(' ');
  return `${issDate[0]} ${issDate[1]} ${issDate[2]} ${issDate[3]} ${issDate[4]} ${issDate[5]}`;
}
  useEffect(() => {
    dispatch(getAllMintedOrgCertificate(oid));
  }, []);
  useEffect(() => {
    eva.replace();
  });

  useEffect(() => {
    if (certificates.statusCode == 200 && certificates.data.certificates) {
      let data = certificates.data.certificates;
      setTimeout(() => {
        dispatch(resetGetAllMintedOrgCertSuccess());
        setCertificateList(data);
        setFetched(true)
      }, 1000);
    }
  }, [certificates]);
  useEffect(() => {
    if (certificatesFailed && certificatesFailed.statusCode) {
      dispatch(resetGetAllMintedOrgCertFailed())
      setFetched(true)
      if(certificatesFailed.statusCode === 401){
        navigate('/login')
      }else if(certificatesFailed.statusCode === 403){
        dispatch('Access denied')
      }
      
    }
  }, [certificatesFailed]);

  return (
  
          <div className=''>
           

            {(certificatesList.length == 0 && !fetched) ? (
              <TableLoader />
            ) : (certificatesList.length == 0 && fetched) ?
            <div className='tableblur mt-4 fadein'>
                <div className='row align-items-center'>
                  <div className='col-12 text-center'>
                  <div className='nocertimg'>
                      <img src={require('../assets/images/no-cert.png')} loading="lazy" />
                      <span className='text'>No certificates found</span>                      
                    </div>
                  </div>
                </div>
              </div> : (
              <div className='tableblur mt-4 fadein'>
                <div className='searchform pt-0 d-none'>
                  <div className='fields'>Search & Filters</div>
                  <div className='fields'><input type={'text'} className="form-control" placeholder='Name' readOnly disabled/></div>
                  <div className='fields'><input type={'text'} className="form-control" placeholder='Batch year' readOnly disabled/></div>
                  <div className='fields'><input type={'text'} className="form-control" placeholder='User ID/Email' readOnly disabled/></div>
                  <div className='fields'>
                    <select className="form-control" disabled>
                      <option defaultValue>Batch</option>
                      <option>2020</option>
                      <option>2021</option>
                      <option>2022</option>                      
                      <option>2023</option>

                    </select>

                  </div>
                </div>
                <div className='table-scroller'>
                  <div className='table-responsive'>
                    <table className="table align-middle mb-0 custable table-hover" >
                      <thead className="">
                        <tr>
                          {/* <th>
                    <div className="form-group"><input type="checkbox" className="form-check-input" id="exampleCheck1" /><label className="form-check-label" htmlFor="exampleCheck1"></label></div>
                    </th> */}
                          <th>Name</th>
                          <th>Course name</th>
                          <th>Batch</th>
                          <th>Grade</th>
                          <th>Verifier</th>
                          <th>Issuer</th>
                          <th>Issued on</th>
                          <th className='text-center'>View</th>
                        </tr>
                      </thead>
                      <tbody>
                      {certificatesList.map((user, index) => (
                          <tr key={index}>
                            <td>
                              <span className="text-dark">{user.user.name}</span>
                            </td>
                            <td>
                              <p className="fw-normal mb-1">{user.course.name}</p>
                            </td>
                            <td> {user.certificateBatch.name} </td>
                            <td> {user.grade} </td>
                            {/* <td>
                              <span className="text-primary text-uppercase badge badge-primary fw-normal">Verified</span>
                            </td> */}
                            <td> {user.verifier.name} </td>
                            <td> {user.issuer.name} </td>

                            <td>
                              {formatDate(new Date(user.datetimeCreated))}
                            </td>
                            <td className='text-center' >                              
                                <a href={user.certificateHash} target="_blank" className='text-primary' data-tooltip="VIEW IPFS" ><i data-eva="eye-outline"></i></a>
                            </td>
                            <td className='text-center'>
                              
                            </td>
                          </tr>
                        ))}
                        
                      
                     
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
       
  );
}

export default FetchOrganisationCertificates;