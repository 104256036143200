import { React, useEffect, useState } from 'react';
import './App.css';
import {Routes, Route} from "react-router-dom";
import Login from './components/Login';
import Signup from './components/Signup';
import ForgotPassword from './components/Forgot_Password';
import Dashboard from './components/Dashboard';
import Students from './components/Students';
import StudentsImport  from './components/Students_Import';
import CertificateTemplate  from './components/Certificate_Template';
import CertificateTemplateCustomize  from './components/Certificate_Template_Customize';
import Issue_Certificate from './components/Issue_Certificate';
import PageNotFound from './components/PageNotFound';
import Signer from './components/Signer';
import { PrivateRoute } from './layout/PrivateRoute';
import StudentsAdd from './components/Students_Add';
import StudentsEdit from './components/Students_Edit';
import StudentDashboard from './components/StudentDashboard';
import ClaimFlowAccount from './components/Claim_Flow_Account';
import StudentsAddEmail from './components/Students_Add_Email';
import CreateApiToken from './components/Create_Api_Token';
import CertificateVerification from './components/Certificate_Verification';
import Agreement_Contract from './components/Agreement_Contract';
import Attributes from './components/Attributes';
import AgreeSigners from './components/AgreeSigners';
import SuperAdminDashboard from './components/Super-admin/SuperadminDashboard';
import SuperAdminOrg from './components/Super-admin/SuperadminOrg';
import SuperadminUser from 'components/Super-admin/SuperadminUser';
import SuperadminMints from 'components/Super-admin/SuperadminMints';
import { SuperAdmin } from './layout/SuperAdmin';
import { StudentRoute } from './layout/StudentRoute';
import { AdminRoute } from './layout/AdminRoute';
import All_Documents from './components/All_Documents';
import CreateFiles from './components/CreateFiles';
import Home from './components/Home';
import CreateCertificatesOnBlockchain from './components/create-certificates-on-blockchain';
import DocumentsWeb3DigitalSigningOnBlockchain from './components/documents-web3-digital-signing-on-blockchain';
import HomeLayout from './layout/HomeLayout';
import { UnauthRoute } from './layout/UnauthRoute';
import Members from './components/Members';
import Profile from './components/Profile';
import { EmailActionHandler } from './components/Email_Action_Handler';
import { certRoles, orgRoles, permissions, publicRole, services, userRoles } from 'components/shared/Roles';
import { useDispatch, useSelector } from 'react-redux';
import { firebaseUserUpdate, getOrgSelfPermissions, getUserProfile, isEmailVerifiedCheck } from 'actions/exampleAction';
import { getSelectedUserRole, isEmailVerified, isUserLoggedIn } from 'helpers/authData';
import CreateOrganisation from 'components/CreateOrganisation';
import Notification from 'components/Notification';
import { isOrgRoleEnabled } from 'utils/utils';
import { getSelectedUserOid } from 'helpers/authData';
import CreateUI from 'components/CreateUI';
import NotificationPopup from 'components/shared/NotificationPopup';
import { useAuthStatus } from 'helpers/authHook';
import FetchCertificates from 'components/FetchCertificates';
import FetchOrganisationCertificates from 'components/FetchOrganisationCertificates';
import BillingPayments from 'components/Billing_payments';
import LetterPad from 'components/LetterDocs';
import TemplatePreview from 'components/Templates_preview';
import DocumentTemplates from 'components/document_templates';


function App() {
  const dispatch = useDispatch()
  let userservicesArray = []
  let orgservicesArray = []
  const userProfileWithWallet = useSelector(state => state.demoReducer.userProfile);
  const orgPermissions = useSelector(state => state.demoReducer.selfPermissions);
  useEffect(() => {
    document.title = 'Certifi.ly';
    if(isUserLoggedIn()){

      dispatch(getUserProfile())
      dispatch(firebaseUserUpdate(JSON.parse(localStorage.getItem('userprofile'))))
      // dispatch(isEmailVerifiedCheck())
      if(isOrgRoleEnabled(getSelectedUserRole())){
        dispatch(getOrgSelfPermissions(getSelectedUserOid()))
      }
    }
  }, []);

 
    if (userProfileWithWallet && userProfileWithWallet.statusCode == 200) {  
      if(userProfileWithWallet && userProfileWithWallet.data && userProfileWithWallet.data !== undefined){
        sessionStorage.setItem('services',JSON.stringify(userProfileWithWallet.data.services))
        userservicesArray = userProfileWithWallet.data.services
      }
      
    }
    if (orgPermissions && orgPermissions.statusCode == 200) {  
      if(orgPermissions &&
        orgPermissions.statusCode === 200 && 
        orgPermissions.data && orgPermissions.data.permissions && 
        orgPermissions.data.permissions[0] && orgPermissions.data.permissions[0].services){
          console.log(orgPermissions)
          orgservicesArray = orgPermissions.data.permissions[0].services
     }
      
    }
  const organisationRoles = [orgRoles.member,orgRoles.signer, orgRoles.owner]
  return (
    <div className="App h-100">
      <Notification/>
      <NotificationPopup/>
      <Routes >
        <Route path="/login" element={ <UnauthRoute><Login /></UnauthRoute> } />
        <Route path="/Signup" element={ <UnauthRoute><Signup /></UnauthRoute> } />
        <Route path="/Forgot-Password" element={ <ForgotPassword /> } />
        <Route path="/verify" element={ <HomeLayout><CertificateVerification/></HomeLayout> } />
        <Route path="/verify/:certificateNumber" element={ <HomeLayout><CertificateVerification/></HomeLayout> } />
        <Route path="/" element={<HomeLayout><Home /></HomeLayout>} />
        <Route path="/create-certificates-on-blockchain" element={<HomeLayout><CreateCertificatesOnBlockchain /></HomeLayout>} />
        <Route path="/documents-web3-digital-signing-on-blockchain" element={<HomeLayout><DocumentsWeb3DigitalSigningOnBlockchain /></HomeLayout>} />
        <Route path="/auth" element={ <EmailActionHandler /> } />

        {/* .............Private Routes.................................. */}
        <Route path="/document/create" element={ <StudentRoute service={services.DOCUMENT} permissions={[permissions.READ, permissions.WRITE]} verificationPermission={true}><Agreement_Contract /></StudentRoute>} />
        <Route path="/attributes" element={ <StudentRoute service={services.DOCUMENT} permissions={[permissions.READ, permissions.WRITE]} verificationPermission={true}><Attributes/></StudentRoute>} />
        <Route path="/create/all" element={ <StudentRoute service={services.CERTIFICATE} permissions={[permissions.READ, permissions.WRITE]}><CreateUI services={userservicesArray} role={certRoles.user}/></StudentRoute>} />
        <Route path="/documents/all" element={<StudentRoute service={services.DOCUMENT} permissions={[permissions.READ, permissions.WRITE]}><All_Documents role={certRoles.user}/></StudentRoute>} />
        <Route path="/create/files" element={<StudentRoute service={services.FILE} permissions={[permissions.READ, permissions.WRITE]}><CreateFiles role={certRoles.user}/></StudentRoute>} />
        <Route path="/members" element={<StudentRoute service={services.MEMBERSHIP} permissions={[permissions.READ, permissions.WRITE]}><Members /></StudentRoute>} />
        <Route path="/create-token" element={ <StudentRoute userRole={userRoles}><CreateApiToken /></StudentRoute>} />
        <Route path="/dashboard" element={ <StudentRoute userRole={userRoles}><StudentDashboard services={userservicesArray}/></StudentRoute>} />
        <Route path="/claim-flow-account" element={ <StudentRoute userRole={userRoles}><ClaimFlowAccount /></StudentRoute>} />
        <Route path="/add-email" element={ <StudentRoute userRole={userRoles}><StudentsAddEmail /></StudentRoute>} />
        <Route path="/create-institution" element={<StudentRoute publicRole={publicRole}><CreateOrganisation/></StudentRoute>} />
        <Route path="/profile" element={<StudentRoute publicRole={publicRole}><Profile /></StudentRoute>} />
        <Route path="/certificates/all" element={ <StudentRoute userRole={userRoles}><FetchCertificates /></StudentRoute>} />
        <Route path="/billing" element={<StudentRoute publicRole={publicRole}><BillingPayments /></StudentRoute>} />
        <Route path="/letter" element={<StudentRoute publicRole={publicRole}><LetterPad /></StudentRoute>} />
        <Route path="/documentTypes" element={ <StudentRoute service={services.DOCUMENT} permissions={[permissions.READ, permissions.WRITE]} verificationPermission={true}><DocumentTemplates services={userservicesArray} role={certRoles.user}/></StudentRoute>} />

        {/* Super admin route */}
        <Route path="/admin/dashboard" element={<AdminRoute userRole={userRoles}><SuperAdminDashboard /></AdminRoute>} />
        <Route path="/admin/organisations" element={<AdminRoute userRole={userRoles}><SuperAdminOrg /></AdminRoute>} />
        <Route path="/admin/users" element={<AdminRoute userRole={userRoles}><SuperadminUser /></AdminRoute>} />
        <Route path="/admin/mints" element={<AdminRoute userRole={userRoles}><SuperadminMints /></AdminRoute>} />
        
        <Route path="/institution/:oid/document/create" element={ <StudentRoute role={organisationRoles}><Agreement_Contract /></StudentRoute>} />
        <Route path="/institution/:oid/users" element={ <StudentRoute role={organisationRoles}><Students /></StudentRoute>} />
        <Route path="/institution/:oid/add-user" element={<StudentRoute role={organisationRoles}><StudentsAdd /></StudentRoute>} />
        <Route path="/institution/:oid/edit-user/:studentId" element={ <StudentRoute role={organisationRoles}><StudentsEdit /></StudentRoute>} />
        <Route path="/institution/:oid/users-import" element={<StudentRoute role={organisationRoles}><StudentsImport /></StudentRoute>} />
        <Route path="/institution/:oid/dashboard" element={<StudentRoute role={organisationRoles}><Dashboard /></StudentRoute>} />
        <Route path="/institution/:oid/attributes" element={ <StudentRoute role={organisationRoles}><Attributes/></StudentRoute>} />
        <Route path="/institution/:oid/create/all" element={ <StudentRoute role={organisationRoles}><CreateUI services={orgservicesArray} role={certRoles.org}/></StudentRoute>} />
        <Route path="/institution/:oid/documents/all" element={<StudentRoute role={organisationRoles}><All_Documents role={certRoles.org}/></StudentRoute>} />
        <Route path="/institution/:oid/create/files" element={<StudentRoute role={organisationRoles}><CreateFiles role={certRoles.org}/></StudentRoute>} />
        <Route path="/institution/:oid/members" element={<StudentRoute role={organisationRoles}><Members /></StudentRoute>} />
        <Route path="/institution/:oid/select-template" element={ <StudentRoute role={organisationRoles}><CertificateTemplate /></StudentRoute>} />
        <Route path="/institution/:oid/customize-template" element={<StudentRoute role={organisationRoles}><CertificateTemplateCustomize /></StudentRoute>} />
        <Route path="/institution/:oid/signer" element={ <StudentRoute role={organisationRoles}><Signer /></StudentRoute>} />
        <Route path="/institution/:oid/agreement-signers" element={<StudentRoute role={organisationRoles}><AgreeSigners/></StudentRoute>} />
        <Route path="/institution/:oid/mint-certificate" element={ <StudentRoute role={organisationRoles}><Issue_Certificate/></StudentRoute>} />
        <Route path="/institution/:oid/certificates/all" element={ <StudentRoute role={organisationRoles}><FetchOrganisationCertificates/></StudentRoute>} />
        <Route path="/institution/:oid/templates/all" element={ <StudentRoute role={organisationRoles}><TemplatePreview/></StudentRoute>} />
        <Route path="/institution/:oid/documentTypes" element={ <StudentRoute role={organisationRoles}><DocumentTemplates services={orgservicesArray} role={certRoles.org}/></StudentRoute>} />

       



        <Route path="*" element={ <PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
