import { Fragment, React, useEffect, useState } from 'react';
import * as eva from 'eva-icons';
import { CertifiCreateBox } from './shared/CertifiCreateBox';
import { useNavigate } from 'react-router-dom';
import { getSelectedUserOid } from 'helpers/authData';
import { useDispatch, useSelector } from 'react-redux';
import { commonError, getCertifiateTemplate, resetGetCertificateTemplate, resetGetCertificateTemplateFailed } from 'actions/exampleAction';
import { configPaths } from 'config';

const TemplatePreview = (props) => {
  useEffect(() => { eva.replace() });
  const navigate = useNavigate()
  const oid = getSelectedUserOid()
  const dispatch = useDispatch()
  const templates = useSelector(state => state.demoReducer.certificateTemplates);
  const templatesFailed = useSelector(state => state.demoReducer.certificateTemplatesFailed);


  const [templateImages, setTemplateImages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    // dispatch(getCertifiateTemplate(oid))

  }, [])


  useEffect(() => {
    if (templates && templates.statusCode === 200) {
      dispatch(resetGetCertificateTemplate())
      if (templates && templates.data && templates.data.templates) {
        setTemplateImages(templates.data.templates)
      }
    }
  }, [templates])
  useEffect(() => {
    if (templatesFailed && templatesFailed.statusCode) {
      dispatch(resetGetCertificateTemplateFailed())
      setIsLoading(false)
      if (templatesFailed.statusCode === 401) {
        navigate('/login')
      } else if (templatesFailed.statusCode === 403) {
        dispatch(commonError('Access denied'))
        setTemplateImages([])
      } else {
        setTemplateImages([])
      }
    }
  }, [templatesFailed])
  const gotoCustomiseTemp = () => {
    navigate(`/institution/${oid}/customize-template`)
  }
  return (
    <Fragment>
      <div className='certtemplates mb-5'>
        <div className='row'>
          {/* {templateImages && templateImages.length > 0 && templateImages.map((items, index) => (
          <div className="col-sm-6 col-md-4 col-lg-3" key={index} style={{ cursor: 'not-allowed' }}>
            <div className={(index !== 4) ? 'ctemp' : 'ctemp dis-before'}>

              <label className='backgroundblur' htmlFor={`certselect-${index + 1}`}>
                <div className='img'>
                  <img src={configPaths.apiPath + (items.previewUrl).slice(1)} loading="lazy" />
                </div>
                <div className='cername'>{items.title}</div>
              </label>
            </div>
          </div>
        ))} */}
          <div className="col-sm-6 col-md-4 col-lg-3" onClick={gotoCustomiseTemp}>
            <div className={'ctemp'}>

              <label className='backgroundblur' htmlFor={`certselect-1`}>
                <div className='img'>
                  <img src={require('../assets/images/cert/bg.png')} loading="lazy" />
                </div>
                <div className='cername'>New Template</div>
              </label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3" style={{ cursor: 'not-allowed' }}>
            <div className={'ctemp'}>

              <label className='backgroundblur' htmlFor={`certselect-1`}>
                <div className='img'>
                  <img src={require('../assets/images/cert/cert1.png')} loading="lazy" />
                </div>
                <div className='cername'>Golden Border</div>
              </label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3" style={{ cursor: 'not-allowed' }}>
            <div className={'ctemp'}>

              <label className='backgroundblur' htmlFor={`certselect-2`}>
                <div className='img'>
                  <img src={require('../assets/images/cert/cert2-split/Blue-and-Yellow-full.png')} loading="lazy" />
                </div>
                <div className='cername'>Blue and Yellow</div>
              </label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3" style={{ cursor: 'not-allowed' }}>
            <div className={'ctemp'}>

              <label className='backgroundblur' htmlFor={`certselect-3`}>
                <div className='img'>
                  <img src={require('../assets/images/cert/cert3-split/Modern-Elegant-Certificate-full.png')} loading="lazy" />
                </div>
                <div className='cername'>Morden-Elegant</div>
              </label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3" style={{ cursor: 'not-allowed' }}>
            <div className={'ctemp'}>

              <label className='backgroundblur' htmlFor={`certselect-4`}>
                <div className='img'>
                  <img src={require('../assets/images/cert/cert4-split/White-and-Blue-full.png')} loading="lazy" />
                </div>
                <div className='cername'>White and Blue</div>
              </label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3" style={{ cursor: 'not-allowed' }}>
            <div className={'ctemp'}>

              <label className='backgroundblur' htmlFor={`certselect-5`}>
                <div className='img'>
                  <img src={require('../assets/images/cert/cert5-split/green-modern-formal-full.png')} loading="lazy" />
                </div>
                <div className='cername'>Green morden</div>
              </label>
            </div>
          </div>
        </div>

      </div>

      <div class="pageheader mb-5">
        <h4 class="fw-bolder text-black text-uppercase mb-0">Document Templates</h4>
      </div>

      <div className='formscroldiv1 mb-5'>
        <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='cly-selectD '>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <i data-eva-animation="flip" data-eva="plus-outline"></i>
                  </div>
                  <div className='cly-docont'>
                    New blank document
                  </div>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Generalnotes.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    General Notes
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Bigwaveletterhead.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Big wave letterhead
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Businessprojectscopereport.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Business project scope report
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Businessservicesproposal.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Business services proposal
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/BusinessPlan.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Business plan
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Report.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Report
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/doc-type/Earthtonesletterhead.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                  Earth tones letterhead
                  </div>
                  </div>
                </div>
              </div>
              
            </div>
        </div>
      </div>

      <div class="pageheader mb-5">
        <h4 class="fw-bolder text-black text-uppercase mb-0">Ticket Templates</h4>
      </div>

      <div className='formscroldiv1 tickettemp'>
        <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='cly-selectD '>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <i data-eva-animation="flip" data-eva="plus-outline"></i>
                  </div>
                  <div className='cly-docont'>
                    New blank ticket
                  </div>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/tickets/3tu-ticket.jpg')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Color art
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/tickets/colors.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Candy colors
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/tickets/gold.jpg')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Golden ticket
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/tickets/stripedbg.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                    Striped circle colors
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='cly-selectD cursor-pointer'>
                  <div className='cly-selDC'>
                  <div className='cly-doc'>
                    <img src={require('../assets/images/tickets/voilet.png')} loading="lazy" />
                  </div>
                  <div className='cly-docont'>
                   Light voilet
                  </div>
                  </div>
                </div>
              </div>
                          
            </div>
        </div>
      </div>

    </Fragment>

  );
}

export default TemplatePreview;