import { React, useEffect, useState } from 'react';
import * as eva from 'eva-icons';
import { getSuperAdminOrg, resetSuperAdminOrg, putUpdateOrgStatus, resetUpdateOrgStatus } from '../../actions/superAction';
import { useDispatch, useSelector } from 'react-redux';
import TableLoader from '.././shared/TableLoader';
import { commonSuccess } from 'actions/exampleAction';


const SuperadminOrg = () => {
  const dispatch = useDispatch();
  const [organistaionsList, setOrganistaionsList] = useState([]);
  const [fetched, setFetched] = useState(false)

  const superadminOrgData = useSelector(state => state.superadminReducer.superadminOrgList);
  const updateOrgStatus = useSelector(state => state.superadminReducer.updateOrgStatus);
  
  const handleToggle = (id) => {
    console.log(id);
    const updatedOrganisationsList = organistaionsList.map((organisation) => {
      if (organisation.oid === id) {
        // found the matching object, create a new object with updated status value
        let identityVerifiedVal = 0;
        if(organisation.identityVerified == 0){
          identityVerifiedVal = 1;
        }
        let data = {
          identityVerified : identityVerifiedVal
        }
        dispatch(putUpdateOrgStatus(id,data));  
        return { ...organisation, identityVerified: identityVerifiedVal };
      }
      // not the matching object, return the original object
      return organisation;
    });
    console.log(updatedOrganisationsList);
    setOrganistaionsList(updatedOrganisationsList);
  };

  useEffect(() => {
    dispatch(getSuperAdminOrg());
  }, []);

  useEffect(() => {
    if (updateOrgStatus.statusCode == 200) {
      dispatch(resetUpdateOrgStatus());
      dispatch(commonSuccess("Verification status updated"))
    }
  }, [updateOrgStatus]);
  useEffect(() => {
    if (superadminOrgData.statusCode == 200) {
      console.log(superadminOrgData.data.organisations);
      let OrgData = superadminOrgData.data.organisations;
      setTimeout(() => {
        setOrganistaionsList(OrgData);
        dispatch(resetSuperAdminOrg());
        setFetched(true);
      }, 2000);
    }
  }, [superadminOrgData]);

  useEffect(() => { eva.replace() });
  function formatDate(date){
    const formatter = Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
  });
  const issDate = formatter.format(date).split(' ');
  return `${issDate[0]} ${issDate[1]} ${issDate[2]} ${issDate[3]} ${issDate[4]} ${issDate[5]}`;
}
  return (
    <div className='row fadein'>
      <div className='col-md-12 text-start'>
        {(organistaionsList.length == 0 && !fetched) ? (
            <div className="mt-4">
            <TableLoader />
          </div>
        ) : (
        <div className='tableblur mt-3'>                    
          <div className='searchform pt-0'>
            <div className='fields'>Search & Filters</div>
            <div className='fields'><input type={'text'} className="form-control" placeholder='Organization ID' readOnly disabled /></div>
            <div className='fields'><input type={'text'} className="form-control" placeholder='Organization Name' readOnly disabled /></div>
            <div className='fields'><input type={'text'} className="form-control" placeholder='Owner' readOnly disabled /></div>
            <div className='fields'><input type={'text'} className="form-control" placeholder='Status' readOnly disabled /></div>
          </div>
          <div className='table-responsive'>
            <table className="table align-middle mb-0 custable table-hover">
              <thead className="">
                <tr>
                  <th>ID</th>
                  <th>Organization ID</th>
                  <th>Organization Name</th>
                  <th>Description</th> 
                  {/* <th>Owner</th> */}
                  {/* <th>Status</th> */}
                  <th>Date created</th>
                  <th className='text-center'>Status</th>
                </tr>
              </thead>
              <tbody>
                  {organistaionsList.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">{index+1}</div>
                      </td>
                      <td>
                        <p className="mb-1">{user.oid}</p>
                      </td>
                      <td>
                        <p className="mb-1">{user.name}</p>
                      </td>
                      <td>
                        <p className="mb-1 mw-300">{user.description}</p>
                      </td>
                      {/* <td>
                        <p className="mb-1">{user.createdby}</p>
                      </td> */}
                      {/* <td>
                        <p className="mb-1">Enabled</p>
                      </td> */}
                      <td>
                      
                        <p className="mb-1">{formatDate(new Date(user.datecreated))}</p>
                      </td>
                      <td className='text-center'>
                      <label className="toggleSwitch large" htmlFor={`ts-${user.oid}`} data-tooltip="Disable/Enable">
                        <input
                          type="checkbox"
                          id={`ts-${user.oid}`}
                          checked={user.identityVerified == 1}
                          onChange={() => handleToggle(user.oid)}
                        />
                        <span>
                        </span>
                        <a></a>
                      </label>
                      {/*<div className='btngrouprht'>
                         <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="View" >< i data-eva-animation="flip" data-eva="eye-outline"></i></a> 


                      </div>*/}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

export default SuperadminOrg;