import jwtDecode from "jwt-decode";
import { auth } from '../firebase-config';
import { getOrgId, getSelectedUserOid, logoutUser } from '../helpers/authData';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { store } from "store";
import { commonSuccess, firebaseUserUpdate } from "actions/exampleAction";

let isRefreshInProgress = null;

export const setup = async (config, orgId) => {
    const token = localStorage.getItem('accessToken')

    const decodedJWT = jwtDecode(token);
    const expTime = (decodedJWT.exp * 1000) - 240000;
    const now = Date.now();

    if (
        now >= expTime
    ) {

        if (isRefreshInProgress === null) {
            isRefreshInProgress = await getUserToken();
            
            const refreshResponse = await isRefreshInProgress;
            isRefreshInProgress = null
            if (refreshResponse && refreshResponse.token && refreshResponse.claims) {
                console.log(refreshResponse)

                localStorage.setItem('accessToken', refreshResponse.token)
                localStorage.setItem('userprofile', JSON.stringify(refreshResponse.claims))
                store.dispatch(firebaseUserUpdate(refreshResponse.claims))
                if (orgId) {
                    config.headers = {
                        ...config.headers,
                        authorization: `Bearer ${refreshResponse.token}`,
                        "cerfi-org-oid": getSelectedUserOid()
                    };
                } else {
                    config.headers = {
                        ...config.headers,
                        authorization: `Bearer ${refreshResponse.token}`,
                    };
                }

            } else {
                console.log(refreshResponse)
                if (refreshResponse === null ||
                    (refreshResponse.res.status === 403 ||
                    refreshResponse.res.status === 401)
                ) {
                    signOut(getAuth()).then(() => {
                        logoutUser()
                        console.log("logged out")
                    }).catch((error) => {
                        // An error happened.
                    });
                }
                return new Promise((resolve, reject) => resolve(refreshResponse));
            }



        }



    } else {
        if (token) {

            if (orgId) {
                config.headers = {
                    ...config.headers,
                    authorization: `Bearer ${token}`,
                    "cerfi-org-oid": getSelectedUserOid()
                };
            } else {
                config.headers = {
                    ...config.headers,
                    authorization: `Bearer ${token}`,
                };
            }
        }
    }

    return config;

}
const getUserToken = async () => {
    return new Promise((resolve, reject) => {
        const unsub = onAuthStateChanged(getAuth(), async (user) => {
            if (user) {
                const token = await getAuth().currentUser.getIdTokenResult();
                resolve(token)
            } else {
                console.log("User not logged in")
                resolve(null)
            }
            unsub();
        });
    })
}