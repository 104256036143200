import { Fragment, React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getOrgSelfPermissions, resetWalletAddressSuccess } from 'actions/exampleAction';
import { getuserName } from 'utils/utils';
import { getSelectedOrgName } from 'helpers/authData';
import { orgRoles } from './Roles';

const SidebarDropdown = (props) => {
  let userDetail = {}
  let services = []
  const userProfileWithWallet = useSelector(state => state.demoReducer.userProfile);

  if (userProfileWithWallet && userProfileWithWallet.data && userProfileWithWallet.data.name) {
    userDetail = userProfileWithWallet.data
    if (userDetail && userDetail.services) {
      services = userDetail.services

    }
  }

  const [address, setAddress] = useState('')
  const navigate = useNavigate();

  const walletaddress = useSelector(state => state.demoReducer.walletAddress);

  const dispatch = useDispatch()
  useEffect(() => {
    if (walletaddress && walletaddress !== null && walletaddress.length > 0) {
      setAddress(walletaddress)
      dispatch(resetWalletAddressSuccess())
    }

  }, [walletaddress])


 


  const handleToSwitch = (items) => {
    if(items && items.oid){
      localStorage.setItem("selectedRole",JSON.stringify(items))
      navigate(`/institution/${items.oid}/dashboard`)
      dispatch(getOrgSelfPermissions(items.oid))
      setSbShow(false);
      setIsHovering(false);      
      // props.toggled()
    }
    
  }
  const backToUser = () => {
    localStorage.removeItem('selectedRole')
    navigate('/dashboard')
  }
  const fetchName = (user) => {
    if(getuserName(user) === 'Certifi User'){
      return 'USER'
    }else{
      return getuserName(user)
    }
  }
  const isOrgisSwitch = () => {
    const selectedRole = localStorage.getItem("selectedRole")
    if(selectedRole && selectedRole !== undefined &&selectedRole !== null){
      const roleDetail = JSON.parse(selectedRole)
      if(roleDetail && roleDetail.oid){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
  }

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  
  const handleMouseleave = () => {
    setIsHovering(false);
  };

  const [sbShow, setSbShow] = useState(false);
  
  const gotoCreateOrg = () => {
    // navigate('/create-institution')
    setSbShow(false);
    setIsHovering(false);
  }
  const loadOrganisation = (organisation) => {
    if(organisation.roles && organisation.roles.length > 0){
      const isOrganiserRole = organisation.roles.some(role => role.name === (orgRoles[role.name]))
      if(isOrganiserRole){
        return <li><span className="dropdown-item cursor-pointer" onClick={() => handleToSwitch(organisation)}>{organisation.name}</span></li>
      }
    }
   
  }

  const params = useParams()
  const [oid,setOid] = useState(params.oid)

  useEffect(() => {
    if(params.oid !== oid){

      setOid(params.oid)
    }
    
  },[params])


  return (
    <Fragment >
          <NavLink className="navbar-brand" to="/">
            <img
              src={require('../../assets/images/logo.png')}
              alt="certifily Logo"
              loading="lazy"
              className='sidebarlogo mb-3'
            />
          </NavLink>

          <div className='mobileprofile'>
            <div className='profile-area'>
              <div className='profile-icon'>
                <img className='headerprofilepic' src={require('../../assets/images/photo10.png')} loading="lazy" />
              </div>
              <p className='mb-1 profilename'>Welcome <span className='username' data-tooltip={getuserName(userDetail)}>{getuserName(userDetail)}</span></p>
              <p className='mb-0 profilename'>{address}</p>         

            </div>
          </div>


          {(userDetail && userDetail.organistaions && userDetail.organistaions.length > 0) ? <div className="dropdown sidebarswitch ">
            <span
              id="dropdownMenuButton"
              data-mdb-toggle="dropdown"
              aria-expanded={sbShow ? "true" : 'false'}
              className={sbShow ? 'w-100 badge badge-primary show' : 'w-100 badge badge-primary'}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseleave}
            >
              {isOrgisSwitch() ? <h6 className='institle' data-tooltip={getSelectedOrgName()}>{getSelectedOrgName()}</h6> : 
              <h6 className='institle' data-tooltip={fetchName(userDetail)}>{fetchName(userDetail)}</h6>}
              <span className='darw'></span>
            </span>
            <ul className={sbShow ? 'dropdown-menu show' : 'dropdown-menu'} aria-labelledby="dropdownMenuButton"  style={{display: isHovering ? 'block' : 'none',}} >     
            <li><span className="dropdown-item cursor-pointer bg-white" onClick={backToUser}>{fetchName(userDetail)}</span></li>                       
            <li><hr className="dropdown-divider m-0" /></li>
            {userDetail.organistaions.map((items, index) => loadOrganisation(items))}  
            <li><NavLink className="dropdown-item cursor-pointer icondditem" to="/create-institution" onClick={gotoCreateOrg}><i data-eva-animation="flip" data-eva="plus-outline"></i> New Institution</NavLink></li>            
          
            </ul>
          </div> : <div className="dropdown sidebarswitch ">
            <span
              id="dropdownMenuButton"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
              className='w-100 badge badge-primary '
            >
              {<h6 className='institle' data-tooltip={fetchName(userDetail)}>{fetchName(userDetail)}</h6>}
              <span className='darw'></span>
            </span>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><span className="dropdown-item cursor-pointer">{fetchName(userDetail)}</span></li>            
              <li><NavLink className="dropdown-item cursor-pointer icondditem" to="/create-institution" onClick={gotoCreateOrg}><i data-eva-animation="flip" data-eva="plus-outline"></i> New Institution</NavLink></li>            
            </ul>
          </div>}

          


          </Fragment>
  );
}

export default SidebarDropdown;
