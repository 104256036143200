import { Navigate } from 'react-router-dom';
import { getSelectedUserOid, getSelectedUserRole } from 'helpers/authData';
import { publicRole, userRoles } from 'components/shared/Roles';
import { useAuthStatus } from 'helpers/authHook';
import Admin from './admin';
import { isOrgRoleEnabled, isUserRoleEnabled } from 'utils/utils';
import { SuperAdmin } from './SuperAdmin';


export { AdminRoute };

function AdminRoute({ children, service, permissions, role, userRole, publicRole }) {

  let services = []
  let canAccess;
  let servicesDetail = sessionStorage.getItem('services')
  if(servicesDetail && servicesDetail !== null && servicesDetail !== undefined){
    services = JSON.parse(servicesDetail)
  }

  const accessService = services.find(s => s.name === service);
  if (accessService && accessService.serviceid) {
    canAccess = permissions && permissions.some(element => {
      return accessService[element] === true
    });

  }
  let authUser = localStorage.accessToken;
  let walletProfile = JSON.parse(localStorage.walletProfile);
  let isAdmin = walletProfile.admin;
  const isLoggedin = useAuthStatus()
  if (!authUser || !isLoggedin) {
    return <Navigate to="/login" />
  }
  
  if(!isAdmin || isAdmin == undefined){
    return <Navigate to="/not-found" />
  }

  if ((userRole && (userRole === userRoles))) {

    if (isOrgRoleEnabled(getSelectedUserRole())) {
      return <Navigate to="/not-found" />
    }
    if (isUserRoleEnabled(getSelectedUserRole())) {
      return <SuperAdmin>{children}</SuperAdmin>;
    }
  }
  if ((publicRole)) {

    if (isOrgRoleEnabled(getSelectedUserRole())) {
      return <Admin>{children}</Admin>
    }
    if (isUserRoleEnabled(getSelectedUserRole())) {
      return <SuperAdmin>{children}</SuperAdmin>;
    }
  } else {
    return <Navigate to="/not-found" />
  }


}