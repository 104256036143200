import { Fragment, React, useEffect, useState, useRef } from 'react';
import * as eva from 'eva-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltipb from 'react-bootstrap/Tooltip';
import { FileUploader } from "react-drag-drop-files";
import DropZone from './shared/DropZone';
import DraggableDiv from './shared/DraggableDiv';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const fileTypes = ["File", "Folder", "Zip"];

const CreateFiles = () => {
    useEffect(() => { eva.replace() });
    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };

    const [sharefileShow, setSharefileShow] = useState(false);
    const handleSharefileClose = () => setSharefileShow(false);
    const handleSharefileShow = () => setSharefileShow(true);

    const handleDrop = (item, dropzoneId) => {
        console.log('Dropped item:', item.id);
        console.log('filename:', item.filename);
        console.log('Target:', dropzoneId);

        const targetElem = document.getElementById(dropzoneId);
        // Create a new span element and set its attributes
        const spanElem = document.createElement('span');
        spanElem.setAttribute('class', 'badge badge-info me-1');
        spanElem.setAttribute('title', item.filename);

        // Create the nested span elements and set their attributes and content
        const spanConElem = document.createElement('span');
        spanConElem.setAttribute('class', 'badge-con');
        spanConElem.textContent = item.filename;

        const spanClsElem = document.createElement('span');
        spanClsElem.setAttribute('class', 'badge-cls');
        spanClsElem.textContent = 'X';

        // Append the nested elements to the parent span element
        spanElem.appendChild(spanConElem);
        spanElem.appendChild(spanClsElem);

        // Append the parent span element to the target element
        targetElem.appendChild(spanElem);
    };

    const [isOpened, setIsOpened] = useState(false);
    const ref = useRef(null);
    const doClick = () => ref.current?.scrollIntoView({ behavior: 'smooth' })

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
        setTimeout(() => {
            doClick();
        }, 200);
    }

    useEffect(() => {
        document.body.classList.add('createfile-layout')
        return () => {
            document.body.classList.remove('createfile-layout')
        }
    }, [])


    return (
        <Fragment>
            <div className='formscroldiv createfile'>
                <div className='tableblur fadein'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-5'>
                                <div className='fileslist'>
                                    <div className='filestit'>
                                        <h4>Recent Files</h4>
                                        <span className='btngrouprht'>
                                            <button className='btn btn-primary btn-icon'><i data-eva-animation="flip" data-eva="upload-outline"></i> Upload Files</button>
                                        </span>
                                    </div>
                                    <div className='filesli'>
                                        <ul className='list-unstyled draggableDiv'>

                                            <li>
                                                <DraggableDiv customAttr={{ filename: 'Google-Analytics-Certification.pdf' }} id="div1" >
                                                    <div className='cllcimg'>
                                                        <span className='cl-listimg'>
                                                            <img src={require('../assets/images/icons/Certifily-icon.png')} loading="lazy" />
                                                        </span>
                                                        <div className='cs-listcon'>
                                                            <span className='cs-listtit'>Google-Analytics-Certification.pdf</span>
                                                            <span className='badge badge-primary'>Verified</span>
                                                        </div>
                                                    </div>
                                                    <div className='foldpics'>
                                                        <ul className='list-unstyled'>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo3.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo4.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo5.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic last-count'>25+</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className='cl-badges'>
                                                        {/* <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Users</Tooltipb>}><span className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="people-outline"></i>
                                                            <span className='cl-icocount'>10+</span>
                                                        </span></OverlayTrigger> */}
                                                        <span data-tooltip="Share" onClick={handleSharefileShow} className='btn btn-outline-primary text-primary btn-sm btn-action'><i data-eva-animation="flip" data-eva="share-outline"></i>
                                                        </span>
                                                    </div>
                                                </DraggableDiv>
                                            </li>


                                            <li>
                                                <DraggableDiv customAttr={{ filename: 'AWS-Certified-Solutions-Architect.png' }} id="div2" >
                                                    <div className='cllcimg'>
                                                        <span className='cl-listimg'>
                                                            <img src={require('../assets/images/icons/Certifily-icon.png')} loading="lazy" />
                                                        </span>
                                                        <div className='cs-listcon'>
                                                            <span className='cs-listtit'>AWS-Certified-Solutions-Architect.png</span>
                                                            <span className='badge badge-primary'>Verified</span>
                                                        </div>
                                                    </div>
                                                    <div className='foldpics'>
                                                        <ul className='list-unstyled'>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo3.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo4.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo5.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic last-count'>25+</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className='cl-badges'>
                                                        {/* <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Users</Tooltipb>}><span className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="people-outline"></i>
                                                            <span className='cl-icocount'>10+</span>
                                                        </span></OverlayTrigger> */}
                                                        <span data-tooltip="Share" onClick={handleSharefileShow} className='btn btn-outline-primary text-primary btn-sm btn-action'><i data-eva-animation="flip" data-eva="share-outline"></i>
                                                        </span>
                                                    </div>
                                                </DraggableDiv>
                                            </li>


                                            <li>
                                                <DraggableDiv customAttr={{ filename: 'AWS-Certified-Solutions-Architect.pdf' }} id="div3"  >
                                                    <div className='cllcimg'>
                                                        <span className='cl-listimg'>
                                                            <img src={require('../assets/images/icons/Certifily-icon.png')} loading="lazy" />
                                                        </span>
                                                        <div className='cs-listcon'>
                                                            <span className='cs-listtit'>AWS-Certified-Solutions-Architect.pdf</span>
                                                            <span className='badge badge-primary'>Verified</span>
                                                        </div>
                                                    </div>
                                                    <div className='foldpics'>
                                                        <ul className='list-unstyled'>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo3.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo4.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo5.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic last-count'>25+</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className='cl-badges'>
                                                        {/* <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Users</Tooltipb>}><span className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="people-outline"></i>
                                                            <span className='cl-icocount'>10+</span>
                                                        </span></OverlayTrigger> */}
                                                        <span data-tooltip="Share" onClick={handleSharefileShow} className='btn btn-outline-primary text-primary btn-sm btn-action'><i data-eva-animation="flip" data-eva="share-outline"></i>
                                                        </span>
                                                    </div>
                                                </DraggableDiv>
                                            </li>


                                            <li>
                                                <DraggableDiv customAttr={{ filename: 'Professional-Cloud-Architect.jpg' }} id="div4"  >
                                                    <div className='cllcimg'>
                                                        <span className='cl-listimg'>
                                                            <img src={require('../assets/images/icons/Certifily-icon.png')} loading="lazy" />
                                                        </span>
                                                        <div className='cs-listcon'>
                                                            <span className='cs-listtit'>Professional-Cloud-Architect.jpg</span>
                                                            <span className='badge badge-primary'>Verified</span>
                                                        </div>
                                                    </div>
                                                    <div className='foldpics'>
                                                        <ul className='list-unstyled'>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo3.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo4.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo5.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic last-count'>25+</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className='cl-badges'>
                                                        {/* <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Users</Tooltipb>}><span className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="people-outline"></i>
                                                            <span className='cl-icocount'>10+</span>
                                                        </span></OverlayTrigger> */}
                                                        <span data-tooltip="Share" onClick={handleSharefileShow} className='btn btn-outline-primary text-primary btn-sm btn-action'><i data-eva-animation="flip" data-eva="share-outline"></i>
                                                        </span>
                                                    </div>
                                                </DraggableDiv>
                                            </li>


                                            <li>
                                                <DraggableDiv customAttr={{ filename: 'Cisco-Certified-Network-Associate.pdf' }} id="div5"  >
                                                    <div className='cllcimg'>
                                                        <span className='cl-listimg'>
                                                            <img src={require('../assets/images/icons/Certifily-icon.png')} loading="lazy" />
                                                        </span>
                                                        <div className='cs-listcon'>
                                                            <span className='cs-listtit'>Cisco-Certified-Network-Associate.pdf</span>
                                                            <span className='badge badge-primary'>Verified</span>
                                                        </div>
                                                    </div>
                                                    <div className='foldpics'>
                                                        <ul className='list-unstyled'>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo3.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo4.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic'><img src={require('../assets/images/photo5.png')} loading="lazy" /></span></li>
                                                            <li><span className='flpic last-count'>25+</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className='cl-badges'>
                                                        {/* <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltipb id="tooltip-bottom">Users</Tooltipb>}><span className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="people-outline"></i>
                                                            <span className='cl-icocount'>10+</span>
                                                        </span></OverlayTrigger> */}
                                                        <span data-tooltip="Share" onClick={handleSharefileShow} className='btn btn-outline-primary text-primary btn-sm btn-action'><i data-eva-animation="flip" data-eva="share-outline"></i>
                                                        </span>
                                                    </div>
                                                </DraggableDiv>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'></div>
                            <div className='col-md-6'>
                                <div className='fileslist'>
                                    <div className='filestit'>
                                        <div className='row'>
                                            <div className='col-md-4'><h4>All Users </h4></div>
                                            <div className='col-md-8'></div>
                                        </div>

                                    </div>
                                    <div className='filesli'>
                                        <ul className='list-unstyled'>
                                            <li>
                                                <span className='cl-listimg cl-listuser'>
                                                    <img src={require('../assets/images/photo5.png')} loading="lazy" />
                                                </span>
                                                <div className='cs-listcon'>
                                                    <span className='cs-listtit'>Williams</span>
                                                    <span className='badge badge-primary'>Verified</span>
                                                </div>

                                                {/* <div className='cs-badges'>
                                                        <span className='badge badge-info' title='Google-Analytics-Certification.pdf'><span className='badge-con'>Google-Analytics-Certification</span> <span className='badge-cls'>X</span></span>
                                                        <span className='badge badge-info' title='Google-Analytics-Certification.jpg'><span className='badge-con'>Google-Analytics-Certification</span> <span className='badge-cls'>X</span></span>
                                                    </div> */}
                                                <div className='cs-badges'>
                                                    <DropZone id="drop0" onDrop={handleDrop}>
                                                    </DropZone>
                                                </div>

                                                <div className='cl-badges'>
                                                    <span data-tooltip="Certificates" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="award-outline"></i>
                                                        <span className='cl-icocount'>10+</span>
                                                    </span>
                                                    <span data-tooltip="Add certificate" className='btn btn-outline-primary text-primary btn-sm btn-action'><i data-eva-animation="flip" data-eva="plus-circle-outline"></i>
                                                    </span>

                                                </div>

                                            </li>

                                            <li>
                                                <span className='cl-listimg cl-listuser'>
                                                    <img src={require('../assets/images/photo1.png')} loading="lazy" />
                                                </span>
                                                <div className='cs-listcon'>
                                                    <span className='cs-listtit'>David</span>
                                                    <span className='badge badge-primary'>Verified</span>
                                                </div>
                                                <div className='cs-badges'>
                                                    <DropZone id="drop1" onDrop={handleDrop}>
                                                    </DropZone>
                                                </div>
                                                <div className='cl-badges'>
                                                    <span data-tooltip="Certificates" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="award-outline"></i>
                                                        <span className='cl-icocount'>10+</span>
                                                    </span>
                                                    <span data-tooltip="Add certificate" className='btn btn-outline-primary text-primary btn-sm btn-action'><i data-eva-animation="flip" data-eva="plus-circle-outline"></i>
                                                    </span>

                                                </div>
                                            </li>


                                            <li>
                                                <span className='cl-listimg cl-listuser'>
                                                    <img src={require('../assets/images/photo2.png')} loading="lazy" />
                                                </span>
                                                <div className='cs-listcon'>
                                                    <span className='cs-listtit'>John</span>
                                                    <span className='badge badge-primary'>Verified</span>
                                                </div>
                                                <div className='cs-badges'>
                                                    <DropZone id="drop2" onDrop={handleDrop}>
                                                    </DropZone>
                                                </div>
                                                <div className='cl-badges'>
                                                    <span data-tooltip="Certificates" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="award-outline"></i>
                                                        <span className='cl-icocount'>10+</span>
                                                    </span>
                                                    <span data-tooltip="Add certificate" className='btn btn-outline-primary text-primary btn-sm btn-action'><i data-eva-animation="flip" data-eva="plus-circle-outline"></i>
                                                    </span>

                                                </div>
                                            </li>


                                            <li>
                                                <span className='cl-listimg cl-listuser'>
                                                    <img src={require('../assets/images/photo3.png')} loading="lazy" />
                                                </span>
                                                <div className='cs-listcon'>
                                                    <span className='cs-listtit'>Maris</span>
                                                    <span className='badge badge-primary'>Verified</span>
                                                </div>
                                                <div className='cs-badges'>
                                                    <DropZone id="drop3" onDrop={handleDrop}>
                                                    </DropZone>
                                                </div>
                                                <div className='cl-badges'>
                                                    <span data-tooltip="Certificates" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="award-outline"></i>
                                                        <span className='cl-icocount'>10+</span>
                                                    </span>
                                                    <span data-tooltip="Add certificate" className='btn btn-outline-primary text-primary btn-sm btn-action'><i data-eva-animation="flip" data-eva="plus-circle-outline"></i>
                                                    </span>

                                                </div>
                                            </li>


                                            <li>
                                                <span className='cl-listimg cl-listuser'>
                                                    <img src={require('../assets/images/photo4.png')} loading="lazy" />
                                                </span>
                                                <div className='cs-listcon'>
                                                    <span className='cs-listtit'>Thomas</span>
                                                    <span className='badge badge-primary'>Verified</span>
                                                </div>
                                                <div className='cs-badges'>
                                                    <DropZone id="drop4" onDrop={handleDrop}>
                                                    </DropZone>
                                                </div>
                                                <div className='cl-badges'>
                                                    <span data-tooltip="Certificates" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="award-outline"></i>
                                                        <span className='cl-icocount'>10+</span>
                                                    </span>
                                                    <span data-tooltip="Add certificate" className='btn btn-outline-primary text-primary btn-sm btn-action'><i data-eva-animation="flip" data-eva="plus-circle-outline"></i>
                                                    </span>

                                                </div>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row fadein'>
                    <div className='col-md-12 text-center mt-5'>
                        <span className='btngrouprht'>
                            <button onClick={toggle} className='btn btn-light btn-icon'><i data-eva-animation="flip" data-eva="code-download-outline"></i> Import Users <span className='mx-2'>{`<>`}</span> File Mapping CSV </button>
                        </span>
                    </div>
                    <div className='col-md-12 text-center mt-3 mb-3'>
                        <span className='btngrouprht'>
                            <a href="#" className='text-link'>Download sample CSV file </a>
                        </span>
                    </div>



                </div>

                {isOpened && (
                    <div className='fadein'>
                        <div className='tableblur my-3 cs-fileupload' ref={ref}>
                            <div className='row align-items-center'>
                                <div className='col-md-6'>
                                    <FileUploader
                                        multiple={true}
                                        handleChange={handleChange}
                                        name="file"
                                        types={fileTypes}
                                    />
                                    {/* <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p> */}
                                </div>
                                <div className='col-md-6'>
                                    <h5>When you upload a file ensure you record its "File name" in a CSV file against a user's email address or iUserID, So that you can successfully send those files to respective users in bulk.</h5>
                                </div>
                            </div>
                        </div>
                        <div className='tableblur mt-4 mb-5'>
                            <div className='table-responsive'>
                                <table className="table align-middle mb-0 custable table-hover">
                                    <thead className="">
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>File Name</th>
                                            <th>File No</th>
                                            <th className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    Williams
                                                </div>
                                            </td>
                                            <td>
                                                williams@gmail.com
                                            </td>
                                            <td>
                                                Google Analytics Certification
                                            </td>
                                            <td> 223 </td>

                                            <td className='text-center'>
                                                <div className='btngrouprht'>
                                                    <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="View User">< i data-eva-animation="flip" data-eva="external-link-outline"></i></a>
                                                    <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Delete">< i data-eva-animation="flip" data-eva="trash-2-outline"></i></a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    John
                                                </div>
                                            </td>
                                            <td>
                                                john@gmail.com
                                            </td>
                                            <td>
                                                Google Analytics Certification
                                            </td>
                                            <td> 222 </td>

                                            <td className='text-center'>
                                            <div className='btngrouprht'>
                                                    <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="View User">< i data-eva-animation="flip" data-eva="external-link-outline"></i></a>
                                                    <a href="#" className='btn btn-outline-primary text-primary btn-sm btn-action' data-tooltip="Delete">< i data-eva-animation="flip" data-eva="trash-2-outline"></i></a>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                )}




            </div>
            <div className='row align-items-center'>
                {/* <div className='col-6'>Totally <span className='fw-bold'>125</span> students seclected</div> */}
                <div className='col-12 text-end'>
                    <div className='btngrouprht'>
                        <button className='btn btn-primary btn-icon icon-rht'>Continue < i data-eva-animation="flip" data-eva="arrow-forward-outline"></i></button>
                    </div>
                </div>
            </div>

            <Modal centered show={sharefileShow} onHide={handleSharefileClose} >
                {/* <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <span className='mcls' onClick={handleSharefileClose}>&times;</span>
                    <h4 className='text-uppercase fw-bold'>Share File</h4>
                    <p className='mb-3'>Select from the following list to share your file!</p>
                    <div className='form-group'>
                        <input type='text' placeholder='Search and select users' className='form-control' />
                    </div>
                    <div className='filesli '>
                        <ul className='list-unstyled list-modal'>
                            <li>
                                <span className='cl-listimg cl-listuser'>
                                    <img src={require('../assets/images/photo5.png')} loading="lazy" />
                                </span>
                                <div className='cs-listcon'>
                                    <span className='cs-listtit'>Williams</span>

                                </div>
                                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                                    < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                                </span>
                            </li>
                            <li>
                                <span className='cl-listimg cl-listuser'>
                                    <img src={require('../assets/images/photo1.png')} loading="lazy" />
                                </span>
                                <div className='cs-listcon'>
                                    <span className='cs-listtit'>David</span>

                                </div>
                                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                                    < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                                </span>
                            </li>
                            <li>
                                <span className='cl-listimg cl-listuser'>
                                    <img src={require('../assets/images/photo2.png')} loading="lazy" />
                                </span>
                                <div className='cs-listcon'>
                                    <span className='cs-listtit'>John</span>

                                </div>
                                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                                    < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                                </span>
                            </li>
                            <li>
                                <span className='cl-listimg cl-listuser'>
                                    <img src={require('../assets/images/photo3.png')} loading="lazy" />
                                </span>
                                <div className='cs-listcon'>
                                    <span className='cs-listtit'>Maris</span>

                                </div>
                                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                                    < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                                </span>
                            </li>
                            <li>
                                <span className='cl-listimg cl-listuser'>
                                    <img src={require('../assets/images/photo4.png')} loading="lazy" />
                                </span>
                                <div className='cs-listcon'>
                                    <span className='cs-listtit'>Thomas</span>

                                </div>
                                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                                    < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                                </span>
                            </li>
                            <li>
                                <span className='cl-listimg cl-listuser'>
                                    <img src={require('../assets/images/photo5.png')} loading="lazy" />
                                </span>
                                <div className='cs-listcon'>
                                    <span className='cs-listtit'>Williams</span>

                                </div>
                                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                                    < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                                </span>
                            </li>
                            <li>
                                <span className='cl-listimg cl-listuser'>
                                    <img src={require('../assets/images/photo1.png')} loading="lazy" />
                                </span>
                                <div className='cs-listcon'>
                                    <span className='cs-listtit'>David</span>

                                </div>
                                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                                    < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                                </span>
                            </li>
                            <li>
                                <span className='cl-listimg cl-listuser'>
                                    <img src={require('../assets/images/photo2.png')} loading="lazy" />
                                </span>
                                <div className='cs-listcon'>
                                    <span className='cs-listtit'>John</span>

                                </div>
                                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                                    < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                                </span>
                            </li>
                            <li>
                                <span className='cl-listimg cl-listuser'>
                                    <img src={require('../assets/images/photo3.png')} loading="lazy" />
                                </span>
                                <div className='cs-listcon'>
                                    <span className='cs-listtit'>Maris</span>

                                </div>
                                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                                    < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                                </span>
                            </li>
                            <li>
                                <span className='cl-listimg cl-listuser'>
                                    <img src={require('../assets/images/photo4.png')} loading="lazy" />
                                </span>
                                <div className='cs-listcon'>
                                    <span className='cs-listtit'>Thomas</span>

                                </div>
                                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                                    <i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className='btngrouprht '>
                        <a href='' className="btn btn-primary btn-icon minw-auto"><i data-eva-animation="flip" data-eva="share-outline"></i> Share</a>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleSharefileClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSharefileClose}>
                    Share
                </Button>
                </Modal.Footer> */}
            </Modal>
        </Fragment>


    );
}

export default CreateFiles;
