import { React, useEffect, useMyCustomStuff, useState } from 'react';
import * as eva from 'eva-icons';
import StudentSidemenu from '../components/shared/StudentSidemenu';
import { Navigate, useNavigate } from 'react-router-dom';
import { mainRoles, userRoles } from '../components/shared/Roles';
import { connectBlocto, isConnectWallet } from '../helpers/ConnectWallet';
import { useDispatch, useSelector } from 'react-redux';
import { firebaseUserUpdate, getUserProfile, getWalletAddress, resetUpdateProfile, resetUpdateProfileFailed, resetWalletAddressSuccess } from '../actions/exampleAction';
import { getFlowAddress, getOrgName, getUsername, getUserRole, logoutUser } from '../helpers/authData';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './../firebase-config';
import Sidemenu from 'components/shared/Sidemenu';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AdminHeader from 'components/shared/AdminHeader';
const StudentLayout = ({ children }) => {

   
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [isStatusChanged,setIsStatusChanged] = useState(false)

    const walletaddress = useSelector(state => state.demoReducer.walletAddress);
    const profiledata = useSelector(state => state.demoReducer.updatedProfile);
    const profiledatafailed = useSelector(state => state.demoReducer.updatedProfileFailed);

  
    useEffect(() => { eva.replace() });

    

    useEffect(() => {
        dispatch(getWalletAddress())
    }, [])

    const [isActive5, setActive5] = useState(false);
    const toggleNav = () => { setActive5(!isActive5); };


    useEffect(() => {
        if(profiledata && profiledata.statusCode === 200){
          dispatch(resetUpdateProfile())
          const userDetails = JSON.parse(localStorage.getItem('walletProfile'));
          if(userDetails && userDetails !== undefined && userDetails !== null && userDetails.data){
            userDetails.name = profiledata.data.name
            userDetails.phone = profiledata.data.phone
            localStorage.setItem('walletProfile',JSON.stringify(userDetails))
          }
          
          setIsStatusChanged(true)
          dispatch(getUserProfile())
          updateFirebaseAuth()
        }
      },[profiledata])
      useEffect(() => {
        if(profiledatafailed && profiledatafailed.statusCode){
          dispatch(resetUpdateProfileFailed())
        }
      },[profiledatafailed])
      useEffect(() => {
        if(isStatusChanged){
          updateFirebaseAuth()
        }
      },[isStatusChanged])
      const updateFirebaseAuth = async () => {
        setIsStatusChanged(false)
        const user = await getUserDetail()
        console.log(user)
        if(user && user.claims && user.token){
          localStorage.setItem('userprofile',JSON.stringify(user.claims))
          localStorage.setItem('accessToken', user.token)
          localStorage.setItem('user_email', user.claims.email);
          dispatch(firebaseUserUpdate(user.claims))
        }
    
      }
      const getUserDetail = async () => {
        return new Promise((resolve, reject) => {
            const unsub = onAuthStateChanged(getAuth(), async (user) => {
                if (user) {
                    const userDet = await getAuth().currentUser.getIdTokenResult(true);
                    resolve(userDet)
                } else {
                    console.log("User not logged in")
                    resolve(null)
                }
                unsub();
            });
        })
    }

    return (
        
            <DndProvider backend={HTML5Backend}>

                <div className='container-fluid ptb15 h100vh'>
                {/* <div className={isActive5 ? 'sboverlay show' : 'sboverlay'}></div> */}
                    <div className='backgroundblur commonbox'>
                        <header>
                            
                            <StudentSidemenu toggleNav={toggleNav} isopen={isActive5} />

                            <nav id="main-navbar" className="navbar navbar-expand-lg navbar-light bg-white fixed-top navbar-toggler mobile-header">
                                <div className='mobnav'>
                                    <div className='logo'>
                                        <a className="navbar-brand" href="#">
                                            <img
                                                src={require('../assets/images/icons/Certifily-icon.png')}
                                                alt="certifily Logo"
                                                loading="lazy"
                                                className='sidebarlogo'
                                            />
                                        </a>
                                    </div>
                                    {/* <div className='cmpname'>
                                <h5 className='fw-bolder text-uppercase'>{userOrg}</h5>
                            </div> */}
                                    <div className='navtoggle text-end'>
                                        <button
                                            type="button"
                                            data-mdb-toggle="collapse"
                                            data-mdb-target="#sidebarMenu"
                                            aria-controls="sidebarMenu"
                                            aria-label="Toggle navigation"
                                            onClick={toggleNav} className={isActive5 ? 'navbar-toggler ' : 'navbar-toggler collapsed'}
                                            aria-expanded={isActive5 ? 'true' : 'false'}
                                        >
                                            <span className='bars'></span>
                                            <span className='bars'></span>
                                            <span className='bars'></span>
                                        </button>
                                    </div>
                                </div>


                            </nav>
                        </header>
                        <main className="height100per">
                            <div className="container-fluid height100per pt-3 ps-4">
                                <div className='scrolldiv mar-top'>
                                    <div className='row '>
                                        <div className='col-md-12 text-start'>
                                            <AdminHeader />
                                            {children}

                                        </div>
                                    </div>
                                </div>

                            </div></main>

                    </div>
                </div> </DndProvider>
    );
}

export default StudentLayout;