import { types } from "../constants/types";

const initialState = {
    superadminOrgList : [],
    superadminOrgListFailed : '',
    updateOrgStatus : [],
    updateOrgStatusFailed : '',
    superadminUserList: [],
    superadminUserListFailed : ''
}
//receives the current state and an action object
export function superadminReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_SUPERADMIN_ORG_SUCCESS:
            return {
                ...state,
                superadminOrgList : action.payload
            }
        case types.GET_SUPERADMIN_ORG_FAILURE:
            return {
                ...state,
                superadminOrgListFailed : action.payload
            }
        case types.PUT_UPDATE_ORG_STATUS_SUCCESS:
            return {
                ...state,
                updateOrgStatus : action.payload
            }
        case types.PUT_UPDATE_ORG_STATUS_FAILURE:
            return {
                ...state,
                updateOrgStatusFailed : action.payload
            }
        case types.GET_SUPERADMIN_USERLIST_SUCCESS:
            return {
                ...state,
                superadminUserList : action.payload
            }
        case types.GET_SUPERADMIN_USERLIST_FAILURE:
            return {
                ...state,
                superadminUserListFailed : action.payload
            }
        
        default:
            return state
    }
}