import { Navigate } from 'react-router-dom';
import StudentLayout from './studentslayout';
import { getSelectedUserOid, getSelectedUserRole } from 'helpers/authData';
import { publicRole, userRoles } from 'components/shared/Roles';
import { useAuthStatus } from 'helpers/authHook';
import Admin from './admin';
import { isOrgRoleEnabled, isUserRoleEnabled } from 'utils/utils';


export { StudentRoute };

function StudentRoute({ children, service, permissions, role, userRole, publicRole }) {

  let services = []
  let canAccess;
  let servicesDetail = sessionStorage.getItem('services')
  if(servicesDetail && servicesDetail !== null && servicesDetail !== undefined){
    services = JSON.parse(servicesDetail)
  }

  
 
  const accessService = services.find(s => s.name === service);
  if (accessService && accessService.serviceid) {
    canAccess = permissions && permissions.some(element => {
      return accessService[element] === true
    });

  }
  const oid = getSelectedUserOid()
  const isOrgUser = role && role.some(e => e.includes(getSelectedUserRole()))
  let authUser = localStorage.accessToken;
  const isLoggedin = useAuthStatus()
  if (!authUser || !isLoggedin) {
    return <Navigate to="/login" />
  }

  if (role) {
    if ((isOrgUser) && isOrgRoleEnabled(getSelectedUserRole())) {
      return <Admin>{children}</Admin>;
    }else{
      return <Navigate to={`/dashboard`} />;

    }
  }
  if (canAccess) {
    if (isOrgRoleEnabled(getSelectedUserRole())) {
      return <Navigate to={`/institution/${oid}/dashboard`} />;
    }
    return <StudentLayout>{children}</StudentLayout>;

  }
  
  if ((userRole && (userRole === userRoles))) {

    if (isOrgRoleEnabled(getSelectedUserRole())) {
      return <Navigate to="/not-found" />
    }
    if (isUserRoleEnabled(getSelectedUserRole())) {
      return <StudentLayout>{children}</StudentLayout>;
    }
  }
  if ((publicRole)) {

    if (isOrgRoleEnabled(getSelectedUserRole())) {
      return <Admin>{children}</Admin>
    }
    if (isUserRoleEnabled(getSelectedUserRole())) {
      return <StudentLayout>{children}</StudentLayout>;
    }
  }

  else {
    return <Navigate to="/not-found" />
  }


}