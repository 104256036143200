import { React, useState, useEffect, useRef } from 'react';
import ProfileArea from '../components/shared/ProfileArea';
import * as eva from 'eva-icons';
import { getAccessToken, getOrganisationsArray, setAccessToken } from '../helpers/authData';
import jwtDecode from 'jwt-decode';
import { getAuth } from 'firebase/auth';
import btnLoader from '../assets/images/certifi-loader.gif';
import { useDispatch, useSelector } from 'react-redux';
import { commonSuccess } from 'actions/exampleAction';

const CreateApiToken = () => {
    const [copySuccess, setCopySucess] = useState(false);
    let timeout;

    const accessToken = getAccessToken();
    const [email, setEmail] = useState('');
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [inputValue, setInputValue] = useState(accessToken);
    const apiKeyInputRef = useRef(null);
    const [decodedToken, setDecodedToken] = useState({
        exp: Date.now(),
        iat: Date.now(),
    });
    const [tokenStatus, setTokenStatus] = useState(true);
    const dispatch = useDispatch()
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value);
        }
    };

    const handleRefreshClick = (event) => {
        event.preventDefault();
        setIsRefreshing(true);
        getAuth()
            .currentUser.getIdToken(true)
            .then((token) => {
                setInputValue(token);
                setAccessToken(token);
                setIsRefreshing(false);
            })
            .catch((e) => {
                setIsRefreshing(false);
            });
    };

    const handleCopy = () => {
        const input = apiKeyInputRef.current;
        input.select();
        input.setSelectionRange(0, 99999);
        navigator.clipboard
            .writeText(input.value)
            .then(() => {
                // setCopySucess(true);
                dispatch(commonSuccess(`Secret key copied to clipboard!`))
                
                // clearTimeout(timeout);
                // timeout = setTimeout(() => {
                //     setCopySucess(false);
                // }, 4000);
            })
            .catch((e) => console.error(e));
    };

    useEffect(() => {
        if (inputValue && inputValue !== '') {
            console.log('token', inputValue);
            const token = jwtDecode(inputValue);
            token.exp *= 1000;
            token.iat *= 1000;
            console.log(token);
            setDecodedToken(token);
        }
    }, [inputValue]);

    useEffect(() => {
        console.log('new token', decodedToken);
        const intervalID = setInterval(() => {
            // console.log('pinging status of token',decodedToken);
            if (Date.now() > decodedToken.exp) {
                setTokenStatus(false);
            } else if (decodedToken.exp > Date.now()) {
                setTokenStatus(true);
            }
        });
        return () => clearInterval(intervalID);
    }, [decodedToken]);

    const getOrganisations = (organisations) => {
        const rows = [];
        let count = 0;
        for (const key in organisations) {
            count++;
            rows.push(
                <tr key={count}>
                    <td>{count}</td>
                    <td>{organisations[key].name}</td>
                    <td>{organisations[key].oid}</td>
                </tr>
            );
        }
        return rows;
    };

    useEffect(() => {
        eva.replace();
    });
    const userProfileWithWallet = useSelector(state => state.demoReducer.userProfile);
    let organisations = []
  if (userProfileWithWallet && userProfileWithWallet.data && userProfileWithWallet.data.name) {

    if (userProfileWithWallet.data && userProfileWithWallet.data.organistaions) {
        organisations = userProfileWithWallet.data.organistaions

    }
  }

    return (
        <div className="row">
            <div className="col-md-12 text-start">
                <div className="row mb-3 align-items-center addemailaccount">
                    <div className="col-md-12 mb-3">
                        {/* <div className='backgroundblur'>
                <h6 className="fw-bolder text-black text-uppercase mb-3">Create Token</h6>
                <form onSubmit={handleSubmit}>
                  <div className="row">                   
                    <div className='col-md-6'>
                    <div className="mb-3 btngrouprht resbtn">
                        <button type="submit" className='btn btn-primary btn-icon'> Generate Public Key</button>
                        <button type="submit" className='btn btn-primary btn-icon'> Generate Secret Key</button>
                      </div>
                    <div className="input-group mb-3 inputbtngroup">
                          <input type={'text'} name="email" value={inputValue} onChange={e => setInputValue(e.target.value)} className="form-control" placeholder='' />
                          <button onClick={handleCopy} type="submit" className='btn btn-primary btn-icon'><i data-eva="copy-outline"></i> Copy Token</button>
                        </div>
                      </div>                          
                  </div>
                </form>
              </div> */}
                    </div>
                    <div className="col-md-12 mb-3 mt">
                        <div className="backgroundblur">
                            <h6 className="fw-bolder text-black text-uppercase mt-3">
                                Organisations
                            </h6>
                            <div className="tableblur mt-4">
                                <div className="table-responsive">
                                    <table className="table align-middle mb-0 custable table-hover">
                                        <thead className="">
                                            <tr>
                                                <th></th>
                                                <th width="700">Name</th>
                                                <th>oid</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getOrganisations(
                                                organisations
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                            <div className='row align-items-center mt-5'>
                                <div className='col-6'> <h6 className="fw-bolder text-black text-uppercase m-0">API keys</h6></div>
                                <div className='col-6'><div className="btngrouprht text-end">
                                {isRefreshing === true && (
                                    <button
                                        type="button"
                                        className="btn btn-light btn-icon btn-disabled bg-white mb-0"
                                    >
                                        <img
                                            src={btnLoader}
                                            loading="lazy"
                                            alt="Loading..."
                                            width={28}
                                        />{' '}
                                        Refreshing...
                                    </button>
                                )}
                                {isRefreshing === false && (
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-icon mb-0"
                                        onClick={handleRefreshClick}
                                    >
                                        <i data-eva="refresh-outline"></i>
                                        Refresh secret
                                    </button>
                                )}
                            </div></div>
                            </div>
                           

                          

                            {/* {copySuccess && (
                                <div
                                    className="alert alert-success text-center py-3 fade show fadein alert-top"
                                    role="alert"
                                >
                                    <b>Secret key</b> copied to clipboard!
                                </div>
                            )} */}
                            <div className="tableblur mt-4 mb-4 w-100">
                                <div className="table-responsive">
                                    <table className="table align-middle mb-0 custable table-hover">
                                        <thead className="">
                                            <tr>
                                                <th></th>
                                                <th width="700">Secret key</th>
                                                <th>Created on</th>
                                                <th>Expires on</th>
                                                <th>Status</th>
                                                {/* <th className='text-center d-none'>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        1
                                                    </div>
                                                </td>
                                                <td className="accessToken">
                                                    <div className="input-group mb-0 inputbtngroup">
                                                        <input
                                                            type={'text'}
                                                            name="apiKey"
                                                            value={inputValue}
                                                            onChange={(e) =>
                                                                setInputValue(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            className="form-control"
                                                            placeholder=""
                                                            readOnly={true}
                                                            ref={apiKeyInputRef}
                                                        />
                                                        <button
                                                            onClick={handleCopy}
                                                            type="submit"
                                                            className="btn btn-primary btn-icon"
                                                        >
                                                            <i data-eva="copy-outline"></i>{' '}
                                                            Copy Secret
                                                        </button>
                                                    </div>
                                                    <form></form>
                                                </td>
                                                <td>
                                                    {new Intl.DateTimeFormat(
                                                        'en-In',
                                                        {
                                                            dateStyle: 'full',
                                                            timeStyle: 'short',
                                                        }
                                                    ).format(
                                                        new Date(
                                                            decodedToken.iat
                                                        )
                                                    )}
                                                </td>
                                                <td>
                                                    {new Intl.DateTimeFormat(
                                                        'en-In',
                                                        {
                                                            dateStyle: 'full',
                                                            timeStyle: 'short',
                                                        }
                                                    ).format(
                                                        new Date(
                                                            decodedToken.exp
                                                        )
                                                    )}
                                                </td>
                                                <td>
                                                    {tokenStatus ? (
                                                        <span className="badge badge-primary">
                                                            ACTIVE
                                                        </span>
                                                    ) : (
                                                        <span className="badge badge-danger">
                                                            EXPIRED
                                                        </span>
                                                    )}
                                                </td>

                                                {/* <td className='text-center'>
                            <div className='btngrouprht'>
                              <a href="" className='btn btn-outline-primary text-primary btn-sm btn-action'>< i data-eva-animation="flip" data-eva="trash-2-outline"></i></a>
                            </div>
                          </td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateApiToken;
