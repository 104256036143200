import { React, useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap"

const SuccessModal = (props) => {
    const [isOpen, setIsOpen] = useState(true)


    const closeModal = () => {
        setIsOpen(false)
        props.closemodal()
    }
    return (
        <Modal show={isOpen} onHide={(e) => closeModal(e, this)} size="md" aria-labelledby="contained-modal-title-vcenter"
            centered backdrop="static">

            <Modal.Body>
            <span className='mcls' onClick={closeModal}>&times;</span>
                <div className="modal-body d-flex flex-column align-items-center">
                    <div className="form-group text-center checkcross">
                        <div className=' mb-5'><img src={require('../../assets/images/logo.png')} loading="lazy" /></div>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#005fff" strokeWidth="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline className="path check" fill="none" stroke="#005fff" strokeWidth="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                        <h4 className='mt-3  mb-0 text-uppercase fw-bold'>Mint Initiated Successfully</h4>
                    </div>

                    <div className='btngrouprht mt-5'>
                        <button className="btn btn-light  minw-auto cursor-pointer" type="button" onClick={closeModal}>Return to Dashboard</button>
                    </div>

                </div>

            </Modal.Body>          
        </Modal>
    );
}

export default SuccessModal;