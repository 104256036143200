import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { commonError, commonSuccess, getWalletAddress, importUsers, resetImportUserFailed, resetImportUserSuccess, resetMintDocsType } from '../../actions/exampleAction';
import { getOrgName, getSelectedUserOid, getSelectedUserRole, getUsername } from '../../helpers/authData';
import { NavLink, useLocation } from 'react-router-dom';
import ProfileArea from './ProfileArea';
import { Modal, Button } from "react-bootstrap"
import * as eva from 'eva-icons';
import { isOrgRoleEnabled } from 'utils/utils';
import { CertifiButton } from './CertifiButton';

const AdminHeader = () => {

  const [address, setAddress] = useState('')
  const [isMintTypeClicked, setIsMintTypeClicked] = useState(false)
  const dispatch = useDispatch()
  const walletaddress = useSelector(state => state.demoReducer.walletAddress);
  const mintCertificateTypeClicked = useSelector(state => state.demoReducer.isDocTypeClicked);
  const navigate = useNavigate()
  const importSuccess = useSelector(state => state.demoReducer.importUsersSuccess);
  const importFailed = useSelector(state => state.demoReducer.impoerUsersFailed);
  const [isOpen, setIsOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const closeModal = () => {
    setIsOpen(false)
  }

  let location = useLocation();

  useEffect(() => {
    setIsMintTypeClicked(mintCertificateTypeClicked)
  }, [mintCertificateTypeClicked])
  const oid = getSelectedUserOid()
  const loadTitle = () => {
    if ((location && location.pathname && location.pathname === `/institution/${oid}/users`)) {
      return 'Users'
    } else if (location && location.pathname && location.pathname.includes('/create-token')) {
      return 'API Tokens'
    } else if (location && location.pathname && location.pathname.includes('/add-user')) {
      return 'Add Users'
    } else if (location && location.pathname && location.pathname.includes('/users-import')) {
      return 'Import Users'
    } else if (location && location.pathname && location.pathname.includes('/edit-user')) {
      return 'Edit Users'
    } else if (location && location.pathname && location.pathname.includes('/document/create')) {
      return 'Create Document'
    } else if (location && location.pathname && location.pathname.includes('/documents/all')) {
      return 'All Documents'
    } else if (location && location.pathname && location.pathname.includes('/agreement-signers')) {
      return 'Agreement Contract'
    } else if (location && location.pathname && location.pathname.includes('/attributes')) {
      return 'Agreement Contract'
    } else if (location && location.pathname && location.pathname.includes('/select-template')) {
      return 'cert templates'
    }
    else if (location && location.pathname && location.pathname.includes('/profile')) {
      return 'Account'
    }
    else if (location && location.pathname && location.pathname.includes('/members')) {
      return 'Members'
    }
    else if (location && location.pathname && location.pathname.includes('/invite-members')) {
      return 'Invite Members'
    }
    else if (location && location.pathname && location.pathname.includes('/create/file')) {
      return 'Create File'
    }
    else if (location && location.pathname && location.pathname.includes('/organisation/create-organisation')) {
      return 'Institution'
    }
    else if (location && location.pathname && location.pathname.includes('/create/all')) {
      return ''
    }
    else if (location && location.pathname && location.pathname.includes('/customize-template')) {
      return 'Customize template'
    }
    else if (location && location.pathname && location.pathname.includes('/create-institution')) {
      return 'Create Institution'
    }
    else if (location && location.pathname && location.pathname.includes('/certificates/all')) {
      return 'Certificates'
    }
    else if (location && location.pathname && location.pathname.includes('/billing')) {
      return 'Payment'
    }
    else if (location && location.pathname && location.pathname.includes('/templates')) {
      return 'Certificate Templates'
    }
    else if (location && location.pathname && location.pathname.includes(`/documentTypes`)) {
      return 'Choose document type'
    }
    else if (location && location.pathname && location.pathname.includes('/')) {
      return ''
    }
  }
  const isUser = () => {
    if (
      location && location.pathname && location.pathname.includes('/users')
    ) {
      return true
    } else {
      return false
    }
  }
  const isAddUser = () => {
    if (location && location.pathname && (location.pathname.includes('/add-user') || location.pathname.includes('/edit-user'))) {
      return true
    } else {
      return false
    }
  }
  const isIssueCertificate = () => {
    if (
      location && location.pathname && location.pathname.includes('/mint-certificate') || location.pathname.includes('/create/all') 
    ) {
      return true
    } else {
      return false
    }
  }
  const isAgreementPage = () => {
    if (location && location.pathname && location.pathname.includes('/document/create')) {
      return true
    } else {
      return false
    }
  }
  const isAllDocPage = () => {
    if (location && location.pathname && location.pathname.includes('/documents/all')) {
      return true
    } else {
      return false
    }
  }
  const isAgreementSigner = () => {
    if (location && location.pathname && (location.pathname.includes('/agreement-signers') || location.pathname.includes('/attributes'))) {
      return true
    } else {
      return false
    }
  }
  const importUsersClick = () => {
    if ((location && location.pathname && location.pathname.includes('/users-import'))) {
      setIsOpen(true)
    } else {
      navigate(`/institutions/${oid}/users-import`)
    }
  }
  const isMembersPage = () => {
    if (location && location.pathname && location.pathname.includes('/members')) {
      return true
    } else {
      return false
    }
  }
  const isCreateAllPage = () => {
    if (location && location.pathname && location.pathname.includes('/create/all')) {
      return true
    } else {
      return false
    }
  }
  const isDashboardPage = () => {
    if (location && location.pathname && location.pathname.includes('/dashboard')) {
      return true
    } else {
      return false
    }
  }

  const isDocumentType = () => {
    if (location && location.pathname && location.pathname.includes(`/institution/${oid}/documentTypes`)) {
      return true
    } else {
      return false
    }
  }

  const isCertTemplate = () => {
    if (location && location.pathname && location.pathname.includes('/templates')) {
      return true
    } else {
      return false
    }
  }

  const handleSelectCSV = (e) => {
    let uploadFile = e.target.files[0]
    closeModal()
    const types = ['text/csv']
    let err = '';

    const sizeLimit = 1 * (1e+6);
    e.target.value = null;
    if (uploadFile == undefined || uploadFile.type == undefined) {
      return false
    }
    else if ((types.every(type => uploadFile && uploadFile.type !== type))) {

      err += uploadFile.type + ' is not a supported format\n';
      dispatch(commonError(err))

    } else if (uploadFile && uploadFile.size >= sizeLimit) {
      err += `Maximum supported file size is ${1} MB`;
      dispatch(commonError(err))

    } else {
      if (uploadFile) {
        setIsUploading(true)
        dispatch(importUsers(uploadFile))

        // this.setState({ uploadedFile: uploadFile, localImageName: uploadFile && uploadFile.name })
      }
    }

  }
  useEffect(() => {
    if (importFailed && importFailed.statusCode) {
      setIsUploading(false)
      setIsOpen(false)

      dispatch(resetImportUserFailed())
      if (importFailed && ( importFailed.statusCode === 401)) {
        navigate('/login')
      }else if (importFailed && (importFailed.statusCode === 403)) {
        dispatch('Access denied')
      } else if (importFailed && importFailed.data && importFailed.data[0] && importFailed.data[0][0] && typeof importFailed.data[0][0] === 'string') {
        dispatch(commonError(importFailed.data[0][0]))
      } else if (importFailed.message) {
        dispatch(commonError(importFailed.message))
      }
    }
  }, [importFailed])
  useEffect(() => {
    if (importSuccess && importSuccess.statusCode === 202) {
      setIsUploading(false)
      dispatch(resetImportUserSuccess())
      dispatch(commonSuccess("Successfully imported"))

    }
  }, [importSuccess])

  const downloadCsv = () => {
    closeModal()
    var csvFileData = [
      ['example@gmail.com', 'firstname', '+1-202-555-0173', 'Batch A']
    ];
    //define the heading for each row of the data  
    var csv = 'email,name,phone,slotName\n';

    //merge the data with CSV  
    csvFileData.forEach(function (row) {
      csv += row.join(',');
      csv += "\n";
    });
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);

    //provide the name for the CSV file to be downloaded  
    hiddenElement.download = 'sample.csv';
    hiddenElement.click();


  }
  const [sharefileShow, setSharefileShow] = useState(false);
  const handleSharefileClose = () => setSharefileShow(false);
  const handleSharefileShow = () => setSharefileShow(true);
  useEffect(() => { eva.replace() });
  const gotoAllDocument = () => {
    if(isOrgRoleEnabled(getSelectedUserRole())){
      navigate(`/institution/${oid}/documents/all`)
    }else{
      navigate("/documents/all")
    }
  }
  const gotoCreateDocument = () => {
    if(isOrgRoleEnabled(getSelectedUserRole())){
      navigate(`/institution/${oid}/document/create`)
    }else{
      navigate("/document/create")
    }
  }
  const gotoAddUser = () => {
    navigate(`/institution/${oid}/add-user`)
  }
  const gotoCreateAllPage = () => {
    if(isOrgRoleEnabled(getSelectedUserRole())){
      navigate(`/institution/${oid}/create/all`)
    }else{
      navigate("/create/all")
    }
  }

  const gotoCustomizeTemplate = () => {
    if(isOrgRoleEnabled(getSelectedUserRole())){
      navigate(`/institution/${oid}/customize-template`)
    }else{
      navigate(`/institution/${oid}/templates/all`)
    }
  }
  const isBillingPage = () => {
    if (location && location.pathname && (location.pathname.includes('/billing'))) {
      return true
    } else {
      return false
    }
  }
  return (
    <div className='pageheader'>
      <Modal show={isOpen} onHide={(e) => closeModal(e, this)} size="md" aria-labelledby="contained-modal-title-vcenter"
        centered  >

        <Modal.Body>
          <span className='mcls' onClick={closeModal}>&times;</span>
          <div className="modal-body d-flex flex-column align-items-center">

            <div className="form-group1 text-center">
              <div className=' mb-5'><img src={require('../../assets/images/logo.png')} loading="lazy" /></div>
              <div className='btngrouprht'>
                <button onClick={downloadCsv} type="button" className="btn btn-light btn-icon icon-rht">Download sample CSV <i data-eva-animation="flip" data-eva="download-outline"></i></button>
                <label htmlFor="file-upload" className="btn btn-primary btn-icon icon-rht">
                  Upload CSV <i data-eva-animation="flip" data-eva="upload-outline"></i>
                </label>
                {/* <input type="file" id="file-upload" single="" onChange={handleSelectCSV} accept=".csv" /> */}

              </div>
            </div>
          </div>

        </Modal.Body>
        {/* <Modal.Footer className='bg-light justify-content-center'>
                <Button variant="outline-secondary" onClick={closeModal}>Close</Button>
            </Modal.Footer> */}
      </Modal>
      <Modal centered show={sharefileShow} onHide={handleSharefileClose} >
        {/* <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header> */}
        <Modal.Body>
          <span className='mcls' onClick={handleSharefileClose}>&times;</span>
          <h4 className='text-uppercase fw-bold'>Invite Members</h4>
          <p className='mb-3'>Select from the following list to invite!</p>
          <div className='form-group'>
            <input type='text' placeholder='Search and select users' className='form-control' />
          </div>
          <div className='filesli '>
            <ul className='list-unstyled list-modal'>
              <li>
                <span className='cl-listimg cl-listuser'>
                  <img src={require('../../assets/images/photo5.png')} loading="lazy" />
                </span>
                <div className='cs-listcon'>
                  <span className='cs-listtit'>Williams</span>

                </div>
                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                  < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                </span>
              </li>
              <li>
                <span className='cl-listimg cl-listuser'>
                  <img src={require('../../assets/images/photo1.png')} loading="lazy" />
                </span>
                <div className='cs-listcon'>
                  <span className='cs-listtit'>David</span>

                </div>
                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                  < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                </span>
              </li>
              <li>
                <span className='cl-listimg cl-listuser'>
                  <img src={require('../../assets/images/photo2.png')} loading="lazy" />
                </span>
                <div className='cs-listcon'>
                  <span className='cs-listtit'>John</span>

                </div>
                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                  < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                </span>
              </li>
              <li>
                <span className='cl-listimg cl-listuser'>
                  <img src={require('../../assets/images/photo3.png')} loading="lazy" />
                </span>
                <div className='cs-listcon'>
                  <span className='cs-listtit'>Maris</span>

                </div>
                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                  < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                </span>
              </li>
              <li>
                <span className='cl-listimg cl-listuser'>
                  <img src={require('../../assets/images/photo4.png')} loading="lazy" />
                </span>
                <div className='cs-listcon'>
                  <span className='cs-listtit'>Thomas</span>

                </div>
                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                  < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                </span>
              </li>
              <li>
                <span className='cl-listimg cl-listuser'>
                  <img src={require('../../assets/images/photo5.png')} loading="lazy" />
                </span>
                <div className='cs-listcon'>
                  <span className='cs-listtit'>Williams</span>

                </div>
                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                  < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                </span>
              </li>
              <li>
                <span className='cl-listimg cl-listuser'>
                  <img src={require('../../assets/images/photo1.png')} loading="lazy" />
                </span>
                <div className='cs-listcon'>
                  <span className='cs-listtit'>David</span>

                </div>
                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                  < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                </span>
              </li>
              <li>
                <span className='cl-listimg cl-listuser'>
                  <img src={require('../../assets/images/photo2.png')} loading="lazy" />
                </span>
                <div className='cs-listcon'>
                  <span className='cs-listtit'>John</span>

                </div>
                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                  < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                </span>
              </li>
              <li>
                <span className='cl-listimg cl-listuser'>
                  <img src={require('../../assets/images/photo3.png')} loading="lazy" />
                </span>
                <div className='cs-listcon'>
                  <span className='cs-listtit'>Maris</span>

                </div>
                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                  < i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                </span>
              </li>
              <li>
                <span className='cl-listimg cl-listuser'>
                  <img src={require('../../assets/images/photo4.png')} loading="lazy" />
                </span>
                <div className='cs-listcon'>
                  <span className='cs-listtit'>Thomas</span>

                </div>
                <span className='btn btn-outline-primary text-primary btn-sm btn-action'>
                  <i data-eva-animation="flip" data-eva="checkmark-circle-2-outline"></i>
                </span>
              </li>
            </ul>
          </div>
          <div className='btngrouprht '>
            <a className="btn btn-primary btn-icon minw-auto" onClick={handleSharefileClose}><i data-eva-animation="flip" data-eva="paper-plane-outline"></i> Invite</a>
          </div>
        </Modal.Body>
      </Modal>
      {isIssueCertificate() ?

        <div className='row mb-3 align-items-center'>
          {!isCreateAllPage() ? <div className='col-md-6'>
            <div className='userOrg'>
              <img src={require('../../assets/images/icons/Certifily-icon.png')} loading="lazy" />
              <h4 className="fw-bolder text-black text-uppercase mb-0">Create Certificate</h4>
            </div>
          </div> : <div className='col-md-6'>
            <h4 className="fw-bolder text-black text-uppercase mb-2">Create</h4>
            <h6 className='mb-0'>Select a document type to be minted as NFT</h6>
          </div>}
          <div className='col-md-6 text-end'>
            <div className='btnwithpro'>
              <ProfileArea />
            </div>
          </div>
        </div>
        :
        <div className='row mb-3 align-items-center'>
          <div className={isAgreementSigner() ? 'col-md-6' : 'col-md-6'}>
            <h4 className="fw-bolder text-black text-uppercase mb-0">
              {/* {isAddUser() ? <NavLink to={`/institutions/${oid}/users`} className='text-dark d-inline-block'>Users {'>'}</NavLink> : ''}  */}
              {loadTitle()}</h4>
            {isAgreementSigner() ? <h6 className='mb-0'>Insurance Agreement</h6> : ''}
           {isBillingPage() ? <h6 class="mb-0">Update your payment information or change your plans according to your needs.</h6> : ''}

          </div>

          <div className={isAgreementSigner() ? 'col-md-6 text-end' : 'col-md-6 text-end'}>
            <div className='btnwithpro'>
              {isUser() ? <div className='btngrouprht'>
              <CertifiButton type="button" className='btn btn-primary btn-icon' onClick={gotoAddUser} name="Add Users" icon={<i data-eva={'plus-outline'} data-eva-animation="flip"></i>} image="plus.png"/>

                {isUploading &&
                  <button
                    type="button"
                    className="btn btn-light btn-icon btn-disabled bg-white">

                    <img
                      src={require('../../assets/images/certifi-loader.gif')}
                      loading="lazy"
                      alt="Loading..." width={28}
                    />&nbsp;Uploading..
                  </button>}
                {/* {!isUploading && <button className="btn btn-primary btn-icon" type='button' onClick={importUsersClick}>< i data-eva-animation="flip" data-eva="code-download-outline"></i> Import users</button>} */}
                <input type="file" id="file-upload" single="" onChange={handleSelectCSV} accept=".csv" />

              </div> : isAgreementPage() ?
                <div className='btngrouprht'>
                  <CertifiButton type="button" className='btn btn-primary btn-icon' onClick={gotoAllDocument} name="All Documents" icon={<i data-eva={'file-text-outline'} data-eva-animation="flip"></i>} image="document.png"/>

                </div> : isAllDocPage() ?
                  <div className='btngrouprht'>
                      <CertifiButton type="button" className='btn btn-primary btn-icon' onClick={gotoCreateDocument} name="Create" icon={<i data-eva={'plus-outline'} data-eva-animation="flip"></i>} image="plus.png"/>

                  </div> : isMembersPage() ?
                      <CertifiButton type="button" className='btn btn-primary btn-icon' onClick={handleSharefileShow} name="Invite" icon={<i data-eva={'paper-plane-outline'} data-eva-animation="flip"></i>} image="paper-plane-outline.png"/>

                    : isDashboardPage() ?
                    <div className='btngrouprht mobhide'>
                        <CertifiButton type="button" className='btn btn-primary btn-icon' onClick={gotoCreateAllPage} name="CREATE" icon={<i data-eva={'award-outline'} data-eva-animation="flip"></i>} verificationNeed={false} image="award.png"/>
                          
                    {/* </div> : isDocumentType() ?
                  <div className='btngrouprht'>
                  <CertifiButton type="button" className='btn btn-primary btn-icon' onClick={gotoCreateAllPage} name="Create Document" icon={<i data-eva={'award-outline'} data-eva-animation="flip"></i>} verificationNeed={false} image="plus.png"/> */}

                  {/* </div> : isCertTemplate() ?
                  <div className='btngrouprht'>
                  <CertifiButton type="button" className='btn btn-primary btn-icon' onClick={gotoCustomizeTemplate} name="New Template" icon={<i data-eva={'award-outline'} data-eva-animation="flip"></i>} verificationNeed={false} image="plus.png"/> */}


                    </div> : ''}


                    
              <ProfileArea />
            </div>
          </div>
        </div>}
    </div>

  );
}

export default AdminHeader;