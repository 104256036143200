import { isEmailVerified } from 'helpers/authData';
import api from '../services/api';
import apiTokenOrgid, { apiTokenWithBlob } from '../services/apiTokenOrgid';
import apiWithToken, { apiWithMultipartFormData } from '../services/apiWithToken';
import { types } from '../store/constants/types';
import { getUserAddress } from '../utils/utils';

export const getSampleAPI = () => {
  return (dispatch) => {
    api.get(`/`)
      .then((response) => {
        dispatch({ type: 'GET_SAMPLEAPI_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'GET_SAMPLEAPI_FAILURE', payload: error });
      });
  };
};

// Sign in
export const postSignIn = (data) => {
  return (dispatch) => {
    api.post('/api/users/auth/sign-in', data)
      .then((response) => {
        localStorage.setItem('accessToken',response.data.data.accessToken);
        dispatch({ type: 'POST_LOGIN_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'POST_LOGIN_FAILURE', payload: error && error.message && error.response.data.message });
      });
  };
};

// Get user profile
export const getUserProfile = () => { 
  return (dispatch) => {
    apiWithToken.get(`/api/users/profile/self`)
      .then((response) => {
        dispatch({ type: 'GET_USERPROFILE_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'GET_USERPROFILE_FAILURE', payload: error });
      });
  };
};

// Create user
export const postCreateStudent = (data,orgID) => { 
  return (dispatch) => {
    apiTokenOrgid.post(`/api/organisations/${orgID}/receivers`, data)
      .then((response) => {
        dispatch({ type: 'POST_CREATESTUDENT_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'POST_CREATESTUDENT_FAILURE', payload: error });
      });
  };
};

// Get all user
export const getUserList = (oid) => { 
  return (dispatch) => {
    apiTokenOrgid.get(`/api/organisations/${oid}/receivers`)
      .then((response) => {
        dispatch({ type: 'GET_USERLIST_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'GET_USERLIST_FAILED', payload: error });
      });
  };
};

// Get user by id
export const getUserByID = (userID) => { 
  return (dispatch) => {
    apiTokenOrgid.get(`/api/users/${userID}`)
      .then((response) => {
        dispatch({ type: 'GET_USERBYID_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'GET_USERBYID_FAILURE', payload: error });
      });
  };
};

// Edit user by id
export const putUserDetails = (data,userID) => { 
  return (dispatch) => {
    apiTokenOrgid.put(`/api/users/${userID}`,data)
      .then((response) => {
        dispatch({ type: 'PUT_EDITUSERDETAILS_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'PUT_EDITUSERDETAILS_FAILURE', payload: error });
      });
  };
};

export const generateCertificate = (userID, data) => {
  return (dispatch) => {
    apiTokenWithBlob.post(`/api/certificates/preview`, data)
      .then((response) => {
        dispatch({ type: 'GENERATE_CERTIFICATE_SUCCESS', payload: response });
      })
      .catch((error) => {
        dispatch({ type: 'GENERATE_CERTIFICATE_FAILED', payload: error });
      });
  };
}

// Get all certificate issued to user
export const getUserCertList = (userID) => { 
  return (dispatch) => {
    apiTokenOrgid.get(`/api/users/${userID}/certificate`)
      .then((response) => {
        dispatch({ type: 'GET_USERCERTLIST_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'GET_USERCERTLIST_FAILURE', payload: error });
      });
  };
};

export const resetLoginInfo = () => {
   return dispatch => {
      dispatch({type : 'POST_LOGIN_SUCCESS',payload : []})
}}
export const resetUserbyid = () => {
  return dispatch => {
     dispatch({type : 'GET_USERBYID_SUCCESS',payload : []})
}}
export const resetEdituser = () => {
  return dispatch => {
     dispatch({type : 'PUT_EDITUSERDETAILS_SUCCESS',payload : []})
}}
export const resetEdituserFailed = () => {
  return dispatch => {
     dispatch({type : 'PUT_EDITUSERDETAILS_FAILURE',payload : {}})
}}
export const resetUserProfile = () => {
  return dispatch => {
     dispatch({type : 'GET_USERPROFILE_SUCCESS',payload : []})
}}
export const resetUserProfileFailed = () => {
  return dispatch => {
     dispatch({type : 'GET_USERPROFILE_FAILURE',payload : ''})
}}
export const resetLoginInfoFailed = () => {
  return dispatch => {
     dispatch({type : 'POST_LOGIN_FAILURE',payload : ''})
}}
export const resetAddStudent = () => {
  return dispatch => {
     dispatch({type : 'POST_CREATESTUDENT_SUCCESS',payload : []})
  }
}
export const resetAddStudentFailed = () => {
  return dispatch => {
     dispatch({type : 'POST_CREATESTUDENT_FAILURE',payload : ''})
  }
}

export const showInfoModal = () => {
  return dispatch => {
     dispatch({type : 'SHOW_MODAL',payload : true})
}}
export const closeInfoModal = () => {
  return dispatch => {
     dispatch({type : 'CLOSE_MODAL',payload : false})
}}
export const getWalletAddress = () => {

  return dispatch => {
      getUserAddress().then(res => {
        if(res && res !== null){
          dispatch({type : 'GET_WALLET_ADDRESS',payload : res})
      }else{
          dispatch({type : 'GET_WALLET_ADDRESS_FAILURE',payload : null})
      }
      },error => {
          dispatch({typs : 'GET_WALLET_ADDRESS_FAILURE',payload : error})
      })

     
  }
}
export const mintCertificate = (data) => {
  return (dispatch) => {
    apiTokenOrgid.post(`/api/mint/certificate`, data)
      .then((response) => {
        dispatch({ type: 'MINT_CERTIFICATE', payload: response.data  });
      })
      .catch((error) => {
        dispatch({ type: 'MINT_CERTIFICATE_FAILURE', payload: error });
      });
  };
}
export const resetMintCertificate = () => {
  return dispatch => {
     dispatch({type : 'MINT_CERTIFICATE',payload : []})
}}
export const resetMintCertificateFailed = () => {
  return dispatch => {
     dispatch({type : 'MINT_CERTIFICATE_FAILURE',payload : []})
}}
export const resetGenerateCertificate = () => {
  return dispatch => {
     dispatch({type : 'GENERATE_CERTIFICATE_SUCCESS',payload : []})
}}
export const reseRecentCertificate = () => {
  return dispatch => {
     dispatch({type : 'POST_RECENTCERTIFICATE_SUCCESS',payload : []})
}}
export const resetUserlist = () => {
  return dispatch => {
     dispatch({type : 'GET_USERLIST_SUCCESS',payload : []})
}}
export const resetUserlistfailed = () => {
  return dispatch => {
     dispatch({type : 'GET_USERLIST_FAILED',payload : ''})
}}
// Recent certificate
export const getRecentCertificate = (oid) => {
  return (dispatch) => {
    apiTokenOrgid.get(`/api/organisations/${oid}/recent-certificates`)
      .then((response) => {
        dispatch({ type: 'POST_RECENTCERTIFICATE_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'POST_RECENTCERTIFICATE_FAILURE', payload: error });
      });
  };
};
export const reseRecentCertificatefailed = () => {
  return dispatch => {
     dispatch({type : 'POST_RECENTCERTIFICATE_FAILURE',payload : []})
}}
export function getCertificateByCertificateNumber(certificateNumber) {
  return (dispatch) => {
    api.get(`/api/certificates/${certificateNumber}`)
      .then((response) => {
        console.log('GET %s', `/api/certificates/${certificateNumber}`, response.data)
        dispatch({
          type: 'GET_CERTIFICATE_CERT_NO_SUCCESS',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'GET_CERTIFICATE_CERT_NO_FAILURE',
          payload: error && error.message && error.response.data.message
        });
      });
  };
}
export function getCertificateFailed() {
  return dispatch => {
    dispatch({ type: 'GET_CERTIFICATE_CERT_NO_FAILURE', payload: '' })
  }
}
export function resetGetCertificate() {
  return dispatch => {
    dispatch({ type: 'GET_CERTIFICATE_CERT_NO_SUCCESS', payload: {} })
  }
}
export function resetUserCertList() {
  return dispatch => {
    dispatch({ type: 'GET_USERCERTLIST_SUCCESS', payload: {} })
  }
}
export const addOwnershipForAccount = (data) => { 
  return (dispatch) => {
    apiWithToken.post(`/api/users/self/public-key`, data)
      .then((response) => {
        dispatch({ type: 'ADD_OWNERSHIP_FLOW_ACCOUNT', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'ADD_OWNERSHIP_FLOW_ACCOUNT_FAILURE', payload: error });
      });
  };
};
export function resetAddOwnership() {
  return dispatch => {
    dispatch({ type: 'ADD_OWNERSHIP_FLOW_ACCOUNT', payload: {} })
  }
}
export function resetAddOwnershipFailed() {
  return dispatch => {
    dispatch({ type: 'ADD_OWNERSHIP_FLOW_ACCOUNT_FAILURE', payload: '' })
  }
}
export const removePublicKey = () => { 
  return (dispatch) => {
    apiWithToken.delete(`/api/users/self/public-key`)
      .then((response) => {
        dispatch({ type: 'REMOVE_PUBLIC_KEY', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'REMOVE_PUBLIC_KEY_FAILURE', payload: error });
      });
  };
};
export function resetRemovePublickey() {
  return dispatch => {
    dispatch({ type: 'REMOVE_PUBLIC_KEY', payload: {} })
  }
}
export function resetRemovePublickeyFailed() {
  return dispatch => {
    dispatch({ type: 'REMOVE_PUBLIC_KEY_FAILURE', payload: '' })
  }
}

export const isMintCertificateDocTypeClicked = () => {
  return (dispatch) => {
    dispatch({type : 'SELECT_DOCUMENT_TYPE', payload : true})
  }
}

export const resetMintDocsType = () => {
  return (dispatch) => {
    dispatch({type : 'RESET_DOCUMENT_TYPE', payload : false})
  }
}

export const commonError = (error) => {
  return (dispatch) => {
    dispatch({type : types.COMMON_ERROR_MESSAGE, payload : error})
  }
}

export const resetCommonError = () => {
  return (dispatch) => {
    dispatch({type : types.COMMON_ERROR_MESSAGE, payload : ''})
  }
}

export const commonSuccess = (error) => {
  return (dispatch) => {
    dispatch({type : types.COMMON_SUCCESS_MESSAGE, payload : error})
  }
}

export const resetCommonSuccess = () => {
  return (dispatch) => {
    dispatch({type : types.COMMON_SUCCESS_MESSAGE, payload : ''})
  }
}

export const importUsers = (data) => {
  return (dispatch) => {

    const formData = new FormData();
    formData.append('importFile', data);

    apiWithMultipartFormData.post('/api/users/import', formData)
      .then((response) => {
        dispatch({ type: types.IMPORT_USERS_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.IMPORT_USERS_FAILED, payload: error });
      });
  };
};
export const resetImportUserSuccess = () => {
  return (dispatch) => {
    dispatch({type : types.IMPORT_USERS_SUCCESS, payload : []})
  }
}
export const resetImportUserFailed = () => {
  return (dispatch) => {
    dispatch({type : types.IMPORT_USERS_FAILED, payload : ''})
  }
}

export const resetWalletAddressSuccess = () => {
  return (dispatch) => {
    dispatch({type : types.GET_WALLET_ADDRESS, payload : []})
  }
}
export const resetWalletAddressFailed = () => {
  return (dispatch) => {
    dispatch({type : types.GET_WALLET_ADDRESS_FAILURE, payload : ''})
  }
}
// Get all user
export const getAllStats = (oid) => { 
  return (dispatch) => {
    apiTokenOrgid.get(`/api/stats/organisations/${oid}`)
      .then((response) => {
        dispatch({ type: types.GET_INSTUITION_DASHBOARD_DETAILS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_INSTUITION_DASHBOARD_DETAILS_FAILED, payload: error });
      });
  };
};

export const resetGetAllStats = () => {
  return (dispatch) => {
    dispatch({type : types.GET_INSTUITION_DASHBOARD_DETAILS, payload : []})
  }
}
export const resetGetAllStatsFailed = () => {
  return (dispatch) => {
    dispatch({type : types.GET_INSTUITION_DASHBOARD_DETAILS_FAILED, payload : ''})
  }
}

// Get all user
export const getStudentStats = () => { 
  return (dispatch) => {
    apiTokenOrgid.get(`/api/stats/users/total-certificates`)
      .then((response) => {
        dispatch({ type: types.GET_STUDENT_DASHBOARD_DETAILS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_STUDENT_DASHBOARD_DETAILS_FAILED, payload: error });
      });
  };
};

export const resetGetAllStudentStats = () => {
  return (dispatch) => {
    dispatch({type : types.GET_STUDENT_DASHBOARD_DETAILS, payload : []})
  }
}
export const resetGetAllStudentStatsFailed = () => {
  return (dispatch) => {
    dispatch({type : types.GET_STUDENT_DASHBOARD_DETAILS_FAILED, payload : ''})
  }
}

// update user detail
export const updateProfile = (data) => { 
  return (dispatch) => {
    apiWithToken.put(`/api/users/profile/self`,data)
      .then((response) => {
        dispatch({ type: types.UPDATED_PROFILE_DATA_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.UPDATED_PROFILE_DATA_FAILED, payload: error });
      });
  };
};

export const resetUpdateProfile = () => {
  return (dispatch) => {
    dispatch({type : types.UPDATED_PROFILE_DATA_SUCCESS, payload : []})
  }
}
export const resetUpdateProfileFailed = () => {
  return (dispatch) => {
    dispatch({type : types.UPDATED_PROFILE_DATA_FAILED, payload : ''})
  }
}
//create organisation
export const createOrganisation = (data) => { 
  return (dispatch) => {
    apiWithToken.post(`api/organisations`,data)
      .then((response) => {
        dispatch({ type: types.CREATE_ORGANISATION, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.CREATE_ORGANISATION_FAILED, payload: error });
      });
  };
};

export const resetCreateOrganisation = () => {
  return (dispatch) => {
    dispatch({type : types.CREATE_ORGANISATION, payload : []})
  }
}
export const resetCreateOrganisationFailed = () => {
  return (dispatch) => {
    dispatch({type : types.CREATE_ORGANISATION_FAILED, payload : ''})
  }
}
// check if org name already exist
export const checkOrganisationNameExist = (name) => { 
  return (dispatch) => {
    apiWithToken.get(`api/organisations/check-organisation-name/${decodeURIComponent(name)}`)
      .then((response) => {
        dispatch({ type: types.CHECK_ORGANISATION_EXIST, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.CHECK_ORGANISATION_EXIST_FAILED, payload: error });
      });
  };
};

export const resetCheckOrganisationNameExist = () => {
  return (dispatch) => {
    dispatch({type : types.CHECK_ORGANISATION_EXIST, payload : []})
  }
}
export const resetCheckOrganisationNameExistFailed = () => {
  return (dispatch) => {
    dispatch({type : types.CHECK_ORGANISATION_EXIST_FAILED, payload : ''})
  }
}

// check if org name already exist
export const getMembersList = (oid) => { 
  return (dispatch) => {
    apiWithToken.get(`api/organisations/${oid}/members`)
      .then((response) => {
        dispatch({ type: types.GET_MEMBERS_LIST, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_MEMBERS_LIST_FAILED, payload: error });
      });
  };
};

export const resetGetMembers = () => {
  return (dispatch) => {
    dispatch({type : types.GET_MEMBERS_LIST, payload : []})
  }
}
export const resetGetMembersFailed = () => {
  return (dispatch) => {
    dispatch({type : types.GET_MEMBERS_LIST_FAILED, payload : ''})
  }
}

// check if org name already exist
export const getOrgDetail = (oid) => { 
  return (dispatch) => {
    apiWithToken.get(`api/organisations/${oid}`)
      .then((response) => {
        dispatch({ type: types.GET_ORGANISATION_DETAIL, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_ORGANISATION_DETAIL_FAILED, payload: error });
      });
  };
};

export const resetGetOrg = () => {
  return (dispatch) => {
    dispatch({type : types.GET_ORGANISATION_DETAIL, payload : []})
  }
}
export const resetGetOrgFailed = () => {
  return (dispatch) => {
    dispatch({type : types.GET_ORGANISATION_DETAIL_FAILED, payload : ''})
  }
}

// check if org name already exist
export const getOrgSelfPermissions = (oid) => { 
  return (dispatch) => {
    apiWithToken.get(`api/organisations/${oid}/user/self/permissions`)
      .then((response) => {
        dispatch({ type: types.ORGANISATION_SELF_PERMISSIONS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.ORGANISATION_SELF_PERMISSIONS_FAILED, payload: error });
      });
  };
};

export const resetGetOrgSelfPermission = () => {
  return (dispatch) => {
    dispatch({type : types.ORGANISATION_SELF_PERMISSIONS, payload : []})
  }
}
export const resetGetOrgSelfPermissionFailed = () => {
  return (dispatch) => {
    dispatch({type : types.ORGANISATION_SELF_PERMISSIONS_FAILED, payload : ''})
  }
}

export const commonNotification = (error) => {
  return (dispatch) => {
    dispatch({type : types.NOTIFICATION_POPUP, payload : error})
  }
}

export const resetCommonNotification = () => {
  return (dispatch) => {
    dispatch({type : types.NOTIFICATION_POPUP, payload : ''})
  }
}

export const isEmailVerifiedCheck = () => {
  return (dispatch) => {
    if(localStorage.getItem("accessToken") && localStorage.getItem('userprofile')){
      let obj = {
        status : 200,
        isEmailVerified : isEmailVerified()
      }
      dispatch({type : types.EMAIL_VERIFIED_CHECK, payload : obj})
    }else{
      let obj = {
        status : 400,
        isEmailVerified : null
      }
      dispatch({type : types.EMAIL_VERIFIED_CHECK, payload : obj})

    }
  }
}

export const getAllMintedCertificate = (uid) => {
  return (dispatch) => {
    apiWithToken.get(`/api/users/${uid}/certificate`)
      .then((response) => {
        dispatch({ type: types.GET_ALL_MINTED_CERTIFICATE, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_ALL_MINTED_CERTIFICATE_FAILED, payload: error });
      });
  };
};
export const resetGetAllMintedCertSuccess = () => {
  return dispatch => {
     dispatch({type : types.GET_ALL_MINTED_CERTIFICATE,payload : []})
}}

export const resetGetAllMintedCertFailed = () => {
  return dispatch => {
     dispatch({type : types.GET_ALL_MINTED_CERTIFICATE_FAILED,payload : []})
}}

export const getAllMintedOrgCertificate = (org) => {
  return (dispatch) => {
    apiWithToken.get(`/api/organisations/${org}/certificates`)
      .then((response) => {
        dispatch({ type: types.GET_ORGANISATION_CERTIFICATES, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_ORGANISATION_CERTIFICATES_FAILED, payload: error });
      });
  };
};
export const resetGetAllMintedOrgCertSuccess = () => {
  return dispatch => {
     dispatch({type : types.GET_ORGANISATION_CERTIFICATES,payload : []})
}}

export const resetGetAllMintedOrgCertFailed = () => {
  return dispatch => {
     dispatch({type : types.GET_ORGANISATION_CERTIFICATES_FAILED,payload : []})
}}

export const firebaseUserUpdate = (user) => {
  return dispatch => {
     dispatch({type : types.FIREBASE_USER_DETAIL,payload : user})
}}

export const getCertifiateTemplate = (org) => {
  return (dispatch) => {
    apiWithToken.get(`/api/organisations/${org}/certificate-templates`)
      .then((response) => {
        dispatch({ type: types.GET_CERTIFICATE_TEMPLATE, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_CERTIFICATE_TEMPLATE_FAILED, payload: error });
      });
  };
};
export const resetGetCertificateTemplate = () => {
  return dispatch => {
     dispatch({type : types.GET_CERTIFICATE_TEMPLATE,payload : []})
}}

export const resetGetCertificateTemplateFailed = () => {
  return dispatch => {
     dispatch({type : types.GET_CERTIFICATE_TEMPLATE_FAILED,payload : []})
}}

export const getOrgVerifyStatus = (org) => {
  return (dispatch) => {
    apiWithToken.get(`/api/organisations/${org}/certificate-templates`)
      .then((response) => {
        dispatch({ type: types.GET_ORGANISATION_VERIFY_STATUS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_ORGANISATION_VERIFY_STATUS_FAILED, payload: error });
      });
  };
};
export const resetGetOrgVerifyStatus = () => {
  return dispatch => {
     dispatch({type : types.GET_ORGANISATION_VERIFY_STATUS,payload : []})
}}

export const resetGetOrgVerifyStatusFailed = () => {
  return dispatch => {
     dispatch({type : types.GET_ORGANISATION_VERIFY_STATUS_FAILED,payload : []})
}}