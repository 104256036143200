import * as fcl from "@onflow/fcl";

export function getUsername(){
    let userprofile = localStorage.getItem('walletProfile') && JSON.parse(localStorage.getItem('walletProfile'));
    if(userprofile && userprofile.name){
        return userprofile.name
    }else{
        return ''
    }
}

export function getOrgName(){
    let userprofile = localStorage.getItem('userprofile') && JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.organisations){
        let keys = Object.keys(userprofile.organisations)
        if(keys && keys[0]){
            if(userprofile.organisations[keys[0]].name){
                return userprofile.organisations[keys[0]].name
            }
        }else{
            return ''
        }   
    }else{
        return ''
    }
}

export function getUserRole(){
    let userprofile = localStorage.getItem('userprofile') && JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.organisations){
        let keys = Object.keys(userprofile.organisations)
        if(keys && keys[0]){
        let roles = Object.keys(userprofile.organisations[keys[0]].roles)
        if(roles && roles[0]){
            console.log(userprofile.organisations[keys[0]].roles[roles[0]].name)
           return userprofile.organisations[keys[0]].roles[roles[0]].name
        }
        }else{
            return ''
        }   
    }else{
        return ''
    }
}

export function getOrgId(){
    let userprofile = localStorage.getItem('userprofile') && JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.organisations){
        let keys = Object.keys(userprofile.organisations)
        if(keys && keys[0]){
            return keys[0]
        }else{
            return ''
        }   
    }else{
        return ''
    }
}

export function getFlowAddress(){
    let userprofile = localStorage.getItem('walletProfile') && JSON.parse(localStorage.getItem('walletProfile'));
    if(userprofile && userprofile.flowAddress){
        return userprofile.flowAddress
    }else{
        return ''
    }
}

export function getUserId(){
    let userprofile = localStorage.getItem('userprofile') && JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.user_id){
        return userprofile.user_id
    }else{
        return ''
    }
}

export function getAccessToken(){
    let accessToken = localStorage.getItem('accessToken');
    if(accessToken){
        return accessToken
    }else{
        return ''
    }
}

export function setAccessToken(accessToken) {
    localStorage.setItem('accessToken', accessToken);
}

export function logoutUser(){
    
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userprofile');
    localStorage.removeItem('certInfo');
    localStorage.removeItem('user_email');
    localStorage.removeItem('selectedStudent');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('walletProfile');
    localStorage.removeItem('selectedRole')
    sessionStorage.removeItem('services')
    fcl.unauthenticate()

    sessionStorage.removeItem("CURRENT_USER")
}
export function isUserLoggedIn(){
    let user = ""
    if(localStorage.getItem("accessToken")){
        user = localStorage.getItem('accessToken')
    }
    if(user){
        return user;
   }else{
       return false
   }

  
}

export function getUserEmail(){
    let userprofile = localStorage.getItem('userprofile') && JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.email){
        return userprofile.email
    }else{
        return ''
    }
}
export function getUserPhone(){
    let userprofile = localStorage.getItem('userprofile') && JSON.parse(localStorage.getItem('userprofile'));
    if(userprofile && userprofile.phone_number){
        return userprofile.phone_number
    }else{
        return ''
    }
}

export function getSelectedUserRole(){
    let isSelectedRole = localStorage.getItem('selectedRole')
    if(isSelectedRole && isSelectedRole !== undefined && typeof isSelectedRole === 'string'){
        const selectedRole = JSON.parse(isSelectedRole)
        if(selectedRole && selectedRole.oid){
            if(selectedRole.roles && selectedRole.roles[0] && selectedRole.roles[0].name){
                return selectedRole.roles[0].name
            }
        }else{
            return false
        }
    }else{
        return false
    }
    
}

export function getSelectedUserOid(){
    const selectedOrganisation = localStorage.getItem('selectedRole')
    if(selectedOrganisation && selectedOrganisation !== undefined){
      const role = JSON.parse(selectedOrganisation)
      if(role && role.oid){
        const oid = role.oid
        return oid
      }else{
        return ''
      }
      
    }else{
        return ''
    }
}
export function getSelectedOrgName(){
    const selectedOrganisation = localStorage.getItem('selectedRole')
    if(selectedOrganisation && selectedOrganisation !== undefined){
      const role = JSON.parse(selectedOrganisation)
      if(role && role.name){
        const name = role.name
        return name
      }else{
        return ''
      }
      
    }else{
        return ''
    }
}

export function isEmailVerified(){
    const user = localStorage.getItem('userprofile')
    if(user && user !== null && user !== undefined){
        const userDetail = JSON.parse(user)
        if(userDetail && userDetail.email_verified){
            return true
        }else{
            return false
        }
    }else{
        return false
    }
}

export function getOrganisationsArray(){
    const user = localStorage.getItem('walletProfile')
    if(user && user !== null && user !== undefined){
        const userDetail = JSON.parse(user)
        if(userDetail && userDetail.organistaions){
            return userDetail.organistaions
        }else{
            return []
        }
    }else{
        return []
    }

}