import { types } from "../constants/types";

const initialState = {
    fetchuserDetail : [],
    fetchuserDetailFailed : '',
    signupInfo : [],
    signupFailed : '',
    signinInfo : [],
    signinFailed : '',
    scheduleSuccess : [],
    scheduleFailed : '',
    sendResetPwdEmailSuccess: {},
    sendResetPwdEmailFailed: '',
    resendEmailSuccess : {},
    resendEmailFailed: {}
}
//receives the current state and an action object
export function authUserReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_AUTHUSER_SUCCESS:
            return {
                ...state,
                fetchuserDetail : action.payload
            }
        case types.GET_AUTHUSER_FAILED:
            return {
                ...state,
                fetchuserDetailFailed : action.payload
            }
        case types.POST_USER_SIGNUP:
            return {
                ...state,
                signupInfo : action.payload
            }
        case types.POST_USER_SIGNUP_FAILED:
            return {
                ...state,
                signupFailed : action.payload
            }
        case types.POST_SIGNIN:
            return {
                ...state,
                signinInfo : action.payload
            }
        case types.POST_SIGNIN_FAILED:
            return {
                ...state,
                signinFailed : action.payload
            }
        case types.SCHEDULE_DEMO_SUCCESS:
            return {
                ...state,
                scheduleSuccess : action.payload
            }
        case types.SCHEDULE_DEMO_FAILED:
            return {
                ...state,
                scheduleFailed : action.payload
            }
        case types.PWD_RESET_EMAIL_SUCCESS:
            return {
                ...state,
                sendResetPwdEmailSuccess: action.payload
            }
        case types.PWD_RESET_EMAIL_FAILED:
            return {
                ...state,
                sendResetPwdEmailFailed: action.payload
            }
        case types.RESEND_VERIFICATION_EMAIL:
            return {
                ...state,
                resendEmailSuccess: action.payload
            }
        case types.RESEND_VERIFICATION_EMAIL_FAILED:
            return {
                ...state,
                resendEmailFailed: action.payload
            }
        default:
            return state
    }
}